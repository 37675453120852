import React, { useState, useEffect, useContext, useRef } from "react";
import { parseISO, getMonth, getYear } from "date-fns";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AuthContext } from "../../context/LogContext";
import { NumericFormat } from "react-number-format";
import axios from "axios";
import Select from "react-select";
import * as Yup from "yup";
import { FaPlusCircle } from "react-icons/fa";
import {
	route_person_searchPerson,
	route_confiPresupuesto_base,
	route_movimientosPresupuesto,
	route_configuration_base,
	route_instituciones_base,
} from "../../../api-routes/RoutesLogin";
import Swal from "sweetalert2";
import RegTerceros from "../../persons/register/RegisterPerson";

export default function NuevoMovimiento(props) {
	const closeModal = useRef(null);
	const selectRef = useRef(null);
	const closecont = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const {
		establishment,
		getToken,
		FechaYMD,
		FechaYMDHMS,
		customStyles,
		dateStyles,
		inputStyles,
	} = useContext(AuthContext);
	const { tipo, idM } = useParams();
	const [titulo, setTitulo] = useState("");
	const [optionsPersons, setOptionsPersons] = useState([]);
	const [cabecerasPlanP, setCabecerasPlanP] = useState([]);
	const [proyecciones, setProyecciones] = useState([]);
	const [cuenta, setCuenta] = useState("");
	const [debito, setDebito] = useState(0);
	const [credito, setCredito] = useState(0);
	const [cuentas, setCuentas] = useState([]);
	const [verCredito, setVerCredito] = useState(false);
	const [verDebito, setVerDebito] = useState(false);
	const [isEnableDebito, setIsEnableDebito] = useState(true);
	const [isEnableCredito, setIsEnableCredito] = useState(true);
	const [contadorId, setContadorId] = useState(1); // Inicializa el contador en 1
	const [isDisabled, setIsDisabled] = useState(true);
	const [cabSelect, setCabSelect] = useState(null);
	const [personSelected, setPersonSelected] = useState(null);
	const [movimientos, setMovimientos] = useState([]);
	const [movSelected, setMovSelected] = useState(null);
	const [arrayMovimientos, setArrayMovimientos] = useState([]);
	const [detIngreso, setDetIngreso] = useState([]);
	const [valDetalle, setValDetalle] = useState("");
	const [jsonIngresosAfectados, setJsonIngresosAfectados] = useState({});
	const [arrayControl, setArrayControl] = useState([]);
	const [totalDB, setTotalDB] = useState(0);
	const [totalCR, setTotalCR] = useState(0);
	const [bancos, setBancos] = useState([]);
	const [bancosS, setBancosS] = useState({ label: "Bancos", value: null });
	const [ctabancoS, setCtabancoS] = useState({
		label: "Cuenta Bancaria",
		value: null,
	});
	const [frecursos, setFrecursos] = useState([]);
	const [frecursosS, setFrecursosS] = useState({
		label: "Fuente de recursos",
		value: null,
	});
	const [ffinanciacion, setFfinanciacion] = useState([]);
	const [ffinanciacionS, setFfinanciacionS] = useState({
		label: "Fuente de financiacion",
		value: null,
	});
	const [ccontrato, setCcontrato] = useState([]);
	const [ccontratoS, setCcontratoS] = useState({
		label: "Clase de contrato",
		value: null,
	});
	const [fcontratacion, setFcontratacion] = useState([]);
	const [fcontratacionS, setFcontratacionS] = useState({
		label: "forma de contratación",
		value: null,
	});
	const [tpago, setTpago] = useState([]);
	const [tpagoS, setTpagoS] = useState({
		label: "Tipo de pago",
		value: null,
	});
	const [institucionS, setInstitucionS] = useState({
		label: "Institucion",
		value: null,
	});

	const [mActivos, setMActivos] = useState([]);
	const [ctasxbanco, setCtasxbanco] = useState([]);
	const formikRef = useRef(null);
	const formikCto = useRef(null);
	const navigate = useNavigate();
	const [contratistaSelected, setContratistaSelected] = useState([]);
	const [interventorSelected, setInterventorSelected] = useState([]);
	const [vinculacionSelected, setVinculacionSelected] = useState({});
	const [anticipoSelected, setanticipoSelected] = useState({});
	const [informacionContrato, setInformacionContrato] = useState({});
	const [pagosSA, setPagosSA] = useState([]);
	const [detalleSA, setDetalleSA] = useState([]);
	const [vraplicado, setVraplicado] = useState(0);
	const [conceptos, setConceptos] = useState([]);
	const [conceptosDcto, setConceptosDcto] = useState([]);
	const [isDisabledD, setIsDisabledD] = useState(true);
	const [dctoSelected, setDctoSelected] = useState({
		value: null,
		label: "Búsqueda de concepto",
	});
	const [porcDcto, setPorcDcto] = useState(0);
	const [vr2Dcto, setVr2Dcto] = useState(0);
	const [listDctos, setListDctos] = useState([]);
	const [detalleSelected, setDetalleSelected] = useState(null);
	const [btnCarga, setBtnCarga] = useState(0);
	const [instituciones, setInstituciones] = useState([]);
	const [clase, setClase]=useState(null);

	useEffect(() => {
		getInstituciones();
		getMesesActivos();
		if (tipo == 1) {
			setTitulo("Reconocimiento Presupuestal");
			setIsEnableDebito(false);
		} else if (tipo == 2) {
			setTitulo("Ingreso Presupuestal");
			getAllConceptos([1, 2]);
		} else if (tipo == 3) {
			setTitulo("Disponibilidad Presupuestal");
			setIsEnableCredito(false);
		} else if (tipo == 4) {
			setTitulo("Registro Presupuestal");
			getAllConceptos([3, 2, 4, 5]);
		} else if (tipo == 5) {
			setTitulo("Obligación Presupuestal");
		} else if (tipo == 6) {
			setTitulo("Pago");
			getAllConceptos([1, 6]);
		} else if (tipo == 15) {
			setTitulo("Adición Presupuestal");
		} else if (tipo == 16) {
			setTitulo("Reducción Presupuestal");
		} else if (tipo == 17) {
			setTitulo("Traslado Presupuestal");
		} else if (tipo == 18) {
			setTitulo("Pagos sin afcetación Presupuestal");
			setTitulo("Pago");
			getAllConceptos([1, 6]);
		}

		if (idM) {
			const cab = getMovimiento(idM);
		} else {
			getCabecerasP(0);
		}
	}, [tipo]);

	const getInstituciones = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_instituciones_base}/listar/${establishment.id}`,
			config
		);
		const formattedOptions = result.data.map((item) => ({
			value: item.id,
			label: item.codigo + " - " + item.nombre,
		}));
		setInstituciones(formattedOptions);
	};

	const agregarTransaccion = () => {
		/* ----- si no existe la cuenta en array cotrol, la crea de lo contrario la edita */
		let ctaSel = proyecciones.find((objeto) => objeto.value === cuenta);
		let saldoDisponible = 0;

		if (ctaSel) {
			saldoDisponible = ctaSel.saldo;
		} else {
			Swal.fire({
				icon: "warning",
				title: "Selecciona una cuenta",
				showConfirmButton: false,
				timer: 2000,
			});
		}
		
		if (
			(tipo == 1 || tipo == 3) &&
			(!institucionS.value || institucionS.value == null) && establishment.institucion==true
		) {
			Swal.fire({
				icon: "warning",
				title: "Selecciona una institución",
				showConfirmButton: false,
				timer: 2000,
			});
			return false;
		}
		const existeId = arrayControl.some((item) => item.id === cuenta);
		if (existeId) {
			const Ctrl = arrayControl.find((item) => item.id === cuenta);
			saldoDisponible = Ctrl.saldoDispo;
		}

		/*-------------------------------------------------------------------- */
		if (
			(cuenta &&
				!isNaN(parseFloat(debito)) &&
				!isNaN(parseFloat(credito)) &&
				parseFloat(debito) >= 0 &&
				parseFloat(credito) >= 0 &&
				parseFloat(debito) <= parseFloat(saldoDisponible) &&
				parseFloat(credito) <= parseFloat(saldoDisponible)) ||
			tipo == 15 || (tipo == 17 && clase==2 )
		) {
			//si existe institucion agregamos el valor
			let inst = null;
			if (
				(tipo == 1 || tipo == 3) &&
				institucionS.value &&
				institucionS.value != null
			) {
				inst = institucionS.value;
				setInstitucionS({ label: "Seleccione institucion", value: null });
			}
			//---------------------------------------
			const transaccion = {
				id: contadorId,
				cuenta,
				debito: debito,
				credito: credito,
				institucion: inst,
			};
			/* Se crea un array  con la acummulacion de valores para controlar el saldo */
			if (existeId) {
				// Si el id existe, actualizar el array sumando el valor al acumulado
				const newData = arrayControl.map((item) =>
					item.id === cuenta
						? {
								...item,
								vrAcum:
									parseFloat(item.vrAcum) +
									parseFloat(debito) +
									parseFloat(credito),
								saldoDispo:
									parseFloat(item.saldoDispo) -
									parseFloat(credito) -
									parseFloat(debito),
						  }
						: item
				);
				setArrayControl(newData);
			} else if (ctaSel) {
				// Si el id no existe, agregar un nuevo objeto al array
				let formattControl = {
					id: ctaSel.value,
					vrAcum: parseFloat(debito) + parseFloat(credito),
					saldoBD: parseFloat(ctaSel.saldo),
					saldoDispo:
						parseFloat(ctaSel.saldo) - parseFloat(debito) - parseFloat(credito),
				};
				setArrayControl([...arrayControl, formattControl]);
			}
			/* --------------------------------------------------------------------- */
			setCuentas([...cuentas, transaccion]);
			setContadorId(contadorId + 1); // Incrementa el contador para la próxima transacción
			// Limpia los campos después de agregar la transacción
			setCuenta("");

			setDebito(0);
			setCredito(0);
			if (selectRef.current) {
				selectRef.current.clearValue();
			}

			if (tipo == 15 || tipo == 16 || tipo == 17) {
				setTotalDB(totalDB + debito);
				setTotalCR(totalCR + credito);
				if (totalDB + debito == totalCR + credito) {
					setIsDisabled(false);
				} else {
					setIsDisabled(true);
				}
			} else {
				setIsDisabled(false);
			}
		} else {
			Swal.fire({
				icon: "warning",
				title:
					"El saldo disponible es $" +
					saldoDisponible +
					" verifica que todos los campos sean validos",
				showConfirmButton: false,
				timer: 2000,
			});
		}
	};

	const agregarIngreso = (formik) => {
		if (movSelected) {
			const mov = arrayMovimientos.find(
				(opcion) => opcion.id === movSelected.value
			);
			setValDetalle(mov.detalle);

			setCuentas([...cuentas, mov]);
			const nuevosMovs = movimientos.filter(
				(cuenta) => cuenta.value !== mov.id
			);
			setMovimientos(nuevosMovs);
			setMovSelected(null);
			formik.setFieldValue("planp", mov.planp);
		} else {
			Swal.fire({
				icon: "warning",
				title: "Verifica que todos los campos sean validos",
				showConfirmButton: false,
				timer: 1500,
			});
		}
	};

	const agregaPagoSA = () => {
		setPagosSA([
			...pagosSA,
			{ contador: contadorId, detalle: detalleSA, credito: credito, debito: 0 },
		]);
		setContadorId(contadorId + 1);
		setIsDisabled(false);
	};

	const eliminarPagoSA = (id) => {
		const nuevoArray = pagosSA.filter((item) => item.contador !== id);
		if (nuevoArray.length > 0) {
			setIsDisabled(false);
		} else {
			setIsDisabled(true);
		}
		setPagosSA(nuevoArray);
	};

	const eliminarMov = (id) => {
		const mov = arrayMovimientos.find((opcion) => opcion.id === id);
		let formattedOption = {};
		if (mov) {
			formattedOption = {
				value: mov.id,
				label: mov.numero + " - Detalle: " + mov.detalle.substring(0, 20),
			};
		}

		setMovimientos([...movimientos, formattedOption]);
		/* actualizamos el array de control el control del valor acumulado */
		const valEliminado = cuentas.find((cuenta) => cuenta.id == id);
		// Si el id existe, actualizar el array sumando el valor al acumulado
		const newData = arrayControl.find(
			(cuenta) => cuenta.id == valEliminado.cuenta
		);

		const indexToUpdate = arrayControl.findIndex(
			(cuenta) => cuenta.id === valEliminado.cuenta
		);

		if (indexToUpdate !== -1) {
			// Copia el array original para evitar la mutación directa del estado
			const newArrayControl = [...arrayControl];

			// actualiza los valores dl array de control
			newArrayControl[indexToUpdate].vrAcum -=
				valEliminado.debito - valEliminado.credito;
			newArrayControl[indexToUpdate].saldoDispo +=
				valEliminado.debito + valEliminado.credito;

			// Actualiza el estado con el nuevo array
			setArrayControl(newArrayControl);
			// actualiza el total debitos y creditos cuando es adicion
			setTotalCR(totalCR - valEliminado.credito);
			setTotalDB(totalDB - valEliminado.debito);
		}

		/* --------------------------------------------------------------------------------------------------- */
		const nuevasCuentas = cuentas.filter((cuenta) => cuenta.id !== id);
		setCuentas(nuevasCuentas);

		if (tipo == 15 || tipo == 16 || tipo == 17) {
			if (
				totalCR - valEliminado.credito == totalDB - valEliminado.debito &&
				totalCR - valEliminado.credito > 0
			) {
				setIsDisabled(false);
			} else {
				setIsDisabled(true);
			}
		} else {
			if (nuevasCuentas.length > 0) {
				setIsDisabled(false);
			} else {
				setIsDisabled(true);
			}
		}
	};

	function customSort(a, b) {
		// Ordenar por 'codigo'
		if (a.codigo < b.codigo) return -1;
		if (a.codigo > b.codigo) return 1;

		// Si 'codigo' es igual, ordenar por 'tipo'
		if (a.tipo < b.tipo) return -1;
		if (a.tipo > b.tipo) return 1;

		// Si 'codigo' y 'tipo' son iguales, ordenar por 'naturaleza'
		if (a.naturaleza < b.naturaleza) return -1;
		if (a.naturaleza > b.naturaleza) return 1;

		return 0;
	}

	const onInputChange = (inputValue) => {
		if (
			typeof inputValue === "string" &&
			inputValue != "" &&
			inputValue.length >= 3
		) {
			loadPersons(inputValue);
		}
	};

	const loadPersons = async (inputValue) => {
		setIsLoading(true);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			const response = await axios.get(
				route_person_searchPerson +
					establishment.id +
					"," +
					inputValue +
					",true",
				config
			);
			const data = response.data;
			const formattedOptions = data.map((item) => ({
				value: item.id,
				label:
					item.identification +
					" - " +
					item.name1 +
					" " +
					item.name2 +
					" " +
					item.lastName1 +
					" " +
					item.lastName2 +
					" - " +
					item.businessName,
			}));

			setOptionsPersons(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const getCabecerasP = async (idP) => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};

			const result = await axios.get(
				`${route_confiPresupuesto_base}/getplanesp/${establishment.id}`,
				config
			);
			const formattedOptions = result.data.map((item) => ({
				value: item.id,
				label: item.nombre + " - " + item.yearObject.year,
				tipo: item.tipo,
			}));
			setCabSelect(formattedOptions.length > 0 ? formattedOptions[0] : null);
			loadCuentas(
				formattedOptions.length > 0 ? formattedOptions[0].value : null
			);
			setCabecerasPlanP(formattedOptions);
			if (idP != 0) {
				const plan = formattedOptions.find((opcion) => opcion.value === idP);
				setCabSelect(plan);
				loadCuentas(plan.value);
			}
		} catch (error) {
			console.error("Error while fetching options:", error);
		}
	};

	function asignarPlanP(cab) {
		console.log(cab);
		console.log(arrayMovimientos);
		const mov = arrayMovimientos.find((opcion) => opcion.id === cab.value);
		console.log(mov);
		if (mov) {
			const plan = cabecerasPlanP.find((opcion) => opcion.value === mov.planp);
			console.log(plan);
			setCabSelect(plan);
			loadCuentas(plan.value);
		}
	}

	const getMovimiento = async (id) => {
		setBtnCarga(1);
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};
			const result = await axios.get(
				`${route_movimientosPresupuesto}/findMovimiento/${id}`,
				config
			);
			const movEdit = result.data;
			if (formikRef.current) {
				formikRef.current.setValues(movEdit);
			}
			/* ----- si es registro cargar los valores de formulario de contrato */
			if (tipo == 4 && movEdit.id != null) {
				getContrato(movEdit.id);
			}

			/* ----------------------------------------------------------------- */
			const tercero = {
				value: result.data.terceroObject.id,
				label:
					result.data.terceroObject.identification +
					" - " +
					result.data.terceroObject.name1 +
					" " +
					result.data.terceroObject.name2 +
					" " +
					result.data.terceroObject.lastName1 +
					" " +
					result.data.terceroObject.lastName2 +
					" - " +
					result.data.terceroObject.businessName,
			};
			/* asignar variables de informacion para mostrar en select */
			setBancosS({
				label: movEdit.bancoO ? movEdit.bancoO.nombre : null,
				value: movEdit.bancoO ? movEdit.bancoO.id : null,
			});

			setFrecursosS({
				label: movEdit.frecursosO ? movEdit.frecursosO.nombre : null,
				value: movEdit.frecursosO ? movEdit.frecursosO.id : null,
			});
			setFfinanciacionS({
				label: movEdit.ffinanciacionO ? movEdit.ffinanciacionO.nombre : null,
				value: movEdit.ffinanciacionO ? movEdit.ffinanciacionO.id : null,
			});
			setCcontratoS({
				label: movEdit.ccontratoO ? movEdit.ccontratoO.nombre : null,
				value: movEdit.ccontratoO ? movEdit.ccontratoO.id : null,
			});
			setFcontratacionS({
				label: movEdit.fcontratacionO ? movEdit.fcontratacionO.nombre : null,
				value: movEdit.fcontratacionO ? movEdit.fcontratacionO.id : null,
			});
			setTpagoS({
				label: movEdit.tpagoO ? movEdit.tpagoO.nombre : null,
				value: movEdit.tpagoO ? movEdit.tpagoO.id : null,
			});
			if (movEdit.bancoO) {
				getCuentasxBanco(movEdit.bancoO.id);
			}
			setCtabancoS({
				value: movEdit.ctabancoO ? movEdit.ctabancoO.id : null,
				label: movEdit.ctabancoO
					? movEdit.ctabancoO.denominacion + " No:  " + movEdit.ctabancoO.numero
					: null,
			});
			setInstitucionS({
				value: movEdit.institucionO ? movEdit.institucionO.id : null,
				label: movEdit.institucionO
					? movEdit.institucionO.codigo + " - " + movEdit.institucionO.nombre
					: null,
			});

			/* ------------------------------------------------------- */
			setPersonSelected(tercero);
			getCabecerasP(result.data.planp);

			if (tipo == 1 || tipo == 3 || tipo == 15 || tipo == 16 || tipo == 17) {
				setCuentas(result.data.listDetalles);
				//Actualizamos el total debitos y total creditos

				/* llenamos el array de control de saldo y valore acumuñados */
				let accumulator = [];
				let totDB = 0;
				let totCR = 0;
				result.data.listDetalles.forEach((item) => {
					//en caso de modificacion acumulamos el total db y total cr
					if (tipo == 15 || tipo == 16 || tipo == 17) {
						totDB += item.debito;
						totCR += item.credito;
					}
					//--------------------------------------------------------
					let ctaSel = proyecciones.find(
						(objeto) => objeto.value == item.cuenta
					);

					if (accumulator[item.cuenta]) {
						// Si existe, acumular los valores de débito y crédito
						accumulator[item.cuenta].vrAcum +=
							parseFloat(item.debito) + parseFloat(item.credito);
						// accumulator[item.cuenta].saldoDispo -= parseFloat(item.credito)-parseFloat(item.debito);
					} else {
						// Si no existe, crear una nueva entrada en el acumulador
						accumulator[item.cuenta] = {
							id: item.cuenta,
							vrAcum: parseFloat(item.debito) + parseFloat(item.credito),
							saldoBD: item.cuentaObject.saldo,
							saldoDispo: item.cuentaObject.saldo,
						};
					}
				});
				setTotalCR(totCR);
				setTotalDB(totDB);

				// Convertir el objeto acumulado a un array
				const groupedArrayResult = Object.values(accumulator);
				const resultadoFinal = Object.values(groupedArrayResult);
				//const resultadoJSON = JSON.stringify(resultadoFinal);

				setArrayControl(resultadoFinal);

				/* -------------------------------------------------------------- */
			} else if (tipo == 2 || tipo == 4 || tipo == 5 || tipo == 6) {
				const docsAfectados = [];
				const ingresosAfectados = {};
				try {
					let listMovs = [];
					if (tipo == 2) {
						listMovs = await obtenerMovimientos(result.data.terceroObject.id);
					} else if (tipo == 4 || tipo == 5 || tipo == 6) {
						listMovs = await obtenerMovimientos(null);
					}

					result.data.listDetalles.forEach((element) => {
						docsAfectados.push(element.docAfectado);
						if (element.docAfectado) {
							if (tipo == 2) {
								ingresosAfectados[element.docAfectado] = element.debito;
							} else if (tipo == 4 || tipo == 5 || tipo == 6) {
								ingresosAfectados[element.docAfectado] = element.credito;
							}
						}
					});
					setJsonIngresosAfectados(ingresosAfectados);
					const objetosFiltrados = listMovs.filter((elementMov) => {
						// Usamos .some() para verificar si al menos un objeto de listDetalles cumple la condición.
						return elementMov.listDetalles.some((detalle) =>
							docsAfectados.includes(detalle.id)
						);
					});
					const detallesEdit = [];

					listMovs.forEach((elementMov) => {
						// Filtra los objetos de listDetalles que coincidan con la condición
						const detallesFiltrados = elementMov.listDetalles.filter(
							(detalle) => docsAfectados.includes(detalle.id)
						);

						// Actualiza el campo 'ingreso' en cada detalle filtrado con el valor correspondiente de 'vals'
						detallesFiltrados.forEach((detalle) => {
							if (ingresosAfectados.hasOwnProperty(detalle.id)) {
								detalle.ingreso = ingresosAfectados[detalle.id];
							}
						});

						detallesEdit.push(...detallesFiltrados);
					});
					setDetIngreso(detallesEdit);
					setCuentas(objetosFiltrados);
				} catch (error) {
					console.error("Error:", error);
				}
			} else if (tipo == 18) {
				var contador = 1;
				var elementos = [];
				result.data.listDetalles.forEach((element) => {
					var detallemov = {
						contador: contador,
						detalle: element.detalle,
						credito: element.credito,
						debito: 0,
					};
					elementos.push(detallemov);
					contador++;
				});
				setPagosSA(elementos);
				setIsDisabled(false);
			}
			setIsDisabled(false);
			/* ----------------------------------------------- */
		} catch (error) {
			console.error("Error while fetching options:", error);
		}
		setBtnCarga(0);
	};

	const getContrato = async (registro) => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};
			const result = await axios.get(
				`${route_movimientosPresupuesto}/getContrato/${registro}`,
				config
			);
			console.log(result);
			const movEdit = result.data;
			if (movEdit.contratistaO) {
				var cont = {
					value: movEdit.contratistaO.id,
					label:
						movEdit.contratistaO.identification +
						" - " +
						movEdit.contratistaO.name1 +
						" " +
						movEdit.contratistaO.name2 +
						" " +
						movEdit.contratistaO.lastName1 +
						" " +
						movEdit.contratistaO.lastName2 +
						" - " +
						movEdit.contratistaO.businessName,
				};
				setContratistaSelected(cont);
			}

			if (movEdit.interventorO) {
				var inte = {
					value: movEdit.interventorO.id,
					label:
						movEdit.interventorO.identification +
						" - " +
						movEdit.interventorO.name1 +
						" " +
						movEdit.interventorO.name2 +
						" " +
						movEdit.interventorO.lastName1 +
						" " +
						movEdit.interventorO.lastName2 +
						" - " +
						movEdit.interventorO.businessName,
				};
				setInterventorSelected(inte);
			}
			if (movEdit.vinculacioni) {
				setVinculacionSelected({
					label: movEdit.vinculacioni,
					value: movEdit.vinculacioni,
				});
			}
			if (movEdit.pactoa) {
				setanticipoSelected({
					label: movEdit.pactoa,
					value: movEdit.pactoa,
				});
			}
			if (formikCto.current) {
				formikCto.current.setValues(movEdit);
			}
		} catch (error) {
			console.error("Error while fetching options:", error);
		}
	};

	const loadCuentas = async (idC) => {
		setIsLoading(true);
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};
			const result = await axios.get(
				`${route_confiPresupuesto_base}/getproyecciones/${idC}`,
				config
			);
			let order = result.data.sort(customSort);
			let ctasxtipo = order;

			if (tipo == 1) {
				ctasxtipo = order.filter((cuenta) => cuenta.naturaleza == 1);
			} else if (tipo == 3 || tipo == 17) {
				ctasxtipo = order.filter((cuenta) => cuenta.naturaleza == 2);
			}

			const formattedOptions = ctasxtipo.map((item) => ({
				value: item.id,
				label: item.codigo + " - " + item.nombre,
				tipo: item.tipo,
				naturaleza: item.naturaleza,
				isDisabled: item.tipo == 1 ? true : false,
				saldo: item.saldo,
			}));

			setProyecciones(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const validationSchema = () => {
		let baseSchema = {
			tercero: Yup.number().required("Este campo es requerido"),
			fecha: Yup.date().required("Este campo es requerido"),
			detalle: Yup.string().required("Este campo es requerido"),
		};

		// Añadir validaciones adicionales según el valor de tipo
		if (tipo == 2) {
			baseSchema = {
				...baseSchema,
				banco: Yup.number().required().typeError("Campo obligatorio"),
				frecursos: Yup.number().required().typeError("Campo obligatorio"),
				ctabanco: Yup.number().required().typeError("Campo obligatorio"),
			};
		} else if (tipo == 4) {
			baseSchema = {
				...baseSchema,
				ffinanciacion: Yup.number().required().typeError("Campo obligatorio"),
				frecursos: Yup.number().required().typeError("Campo obligatorio"),
				ccontrato: Yup.number().required().typeError("Campo obligatorio"),
				fcontratacion: Yup.number().required().typeError("Campo obligatorio"),
			};
		} else if (tipo == 6) {
			baseSchema = {
				...baseSchema,
				banco: Yup.number().required().typeError("Campo obligatorio"),
				tpago: Yup.number().required().typeError("Campo obligatorio"),
				ctabanco: Yup.number().required().typeError("Campo obligatorio"),
			};
		} else if (tipo == 18) {
			baseSchema = {
				...baseSchema,
				banco: Yup.number().required().typeError("Campo obligatorio"),
				ctabanco: Yup.number().required().typeError("Campo obligatorio"),
			};
		}

		return Yup.object().shape(baseSchema);
	};

	const initialValues = {
		id: null,
		tercero: "",
		fecha: FechaYMD,
		detalle: "",
		establishment: establishment.id,
		estado: 1,
		tipo: tipo,
		planp: null,
		estado: 1,
		banco: null,
		frecursos: null,
		ffinanciacion: null,
		ccontrato: null,
		fcontratacion: null,
		tpago: null,
		ctadestino: "",
		noacto: "",
		ctabanco: null,
		institucion: null,
	};

	const initialValuesCto = {
		numcontrato: "",
		codproyecto: "",
		linead: "",
		contratista: "",
		fechaf: "",
		interventor: "",
		vinculacioni: "",
		fechaagu: "",
		fechaic: "",
		plazod: "",
		unidade: "Dias",
		pactoa: "",
		valora: "",
		fechapa: "",
		fechaad: "",
		plazoad: "",
		valorad: "",
		fechatc: "",
		fechaal: "",
	};

	const stylePlan = {
		control: (base) => ({
			...base,
			height: 60,
			minHeight: 35,
			fontSize: "14px",
			height: "45px",
		}),
		option: (base, { data, isDisabled }) => ({
			...base,
			textAlign: `left`,
			backgroundColor: data.tipo === 1 ? "#5fb7eb" : "#3879db",
			color: "white",
		}),
	};

	const styleClase = {
		control: (base) => ({
			...base,
			height: 60,
			minHeight: 35,
			fontSize: "14px",
			height: "45px",
		}),
		option: (base, { data, isDisabled }) => ({
			...base,
			textAlign: `left`,
			color: "Black",
		}),
	};

	const handleSubmit = (values) => {
		setBtnCarga(1);
		let sumaCreditos = 0;
		let sumaDebitos = 0;
		if (tipo == 1 || tipo == 3 || tipo == 15 || tipo == 16 || tipo == 17) {
			values.detalles = cuentas;
			sumaCreditos = values.detalles.reduce((total, detalle) => {
				return total + detalle.credito;
			}, 0);
			sumaDebitos = values.detalles.reduce((total, detalle) => {
				return total + detalle.debito;
			}, 0);
		} else if (tipo == 2 || tipo == 4 || tipo == 5 || tipo == 6) {
			values.detalles = detIngreso;
			if (tipo == 2) {
				sumaCreditos = 0;
				sumaDebitos = values.detalles.reduce((total, detalle) => {
					return total + detalle.ingreso;
				}, 0);
			} else if (tipo == 4 || tipo == 5 || tipo == 6) {
				sumaDebitos = 0;
				sumaCreditos = values.detalles.reduce((total, detalle) => {
					return total + detalle.ingreso;
				}, 0);
				// si es un pago asignamos la lista de descuento
				//values.listDctos = listDctos;
			}
		} else if (tipo == 18) {
			values.detalles = pagosSA;
			sumaCreditos = values.detalles.reduce((total, detalle) => {
				return total + detalle.credito;
			}, 0);
			sumaDebitos = 0;
			// si es un pago asignamos la lista de descuento
			values.listDctos = listDctos;
		}

		values.creditos =
			typeof sumaCreditos === "number" && !isNaN(sumaCreditos)
				? sumaCreditos
				: 0;
		values.debitos =
			typeof sumaDebitos === "number" && !isNaN(sumaDebitos) ? sumaDebitos : 0;

		if (values.id == null) {
			values.crea = localStorage.getItem("idUser");
			values.fechacrea = FechaYMDHMS;
		} else {
			values.mod = localStorage.getItem("idUser");
			values.fechamod = FechaYMDHMS;
		}
		if (cabSelect) {
			values.planp = cabSelect.value;
		}
		var activo = verificarFecha(values);
		if (activo == true) {
			//console.log(values);
			registrarMovimiento(values);
		} else {
			Swal.fire({
				icon: "warning",
				title: "El periodo de tiempo no se encuentra activo",
				showConfirmButton: false,
				timer: 1500,
			});
			setBtnCarga(0);
		}
	};
	const handleSubmitCto = (values) => {
		setInformacionContrato(values);
		if (closecont.current) {
			closecont.current.click();
		}
	};

	const registrarMovimiento = async (json) => {
		console.log("mov " + json);

		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		return axios
			.post(route_movimientosPresupuesto + "/saveMovimiento", json, config)
			.then((response) => {
				if (response.data.errorMessage != null) {
					alert(response.data.errorMessage, "error");
					return false;
				} else {
					/* almacenar informacin de contrato si es tipo 4 o registro */
					if (
						!(Object.keys(json).length === 0 && json.constructor === Object) &&
						response.status == 200 &&
						response.data &&
						tipo == 4
					) {
						var values = informacionContrato;
						values.registro = response.data.id;
						guardarContrato(values);
					}

					Swal.fire({
						icon: "success",
						title: "Movimiento registrado ",
						showConfirmButton: false,
						timer: 1500,
					}).then(() => {
						setBtnCarga(0);
						navigate(
							"/menu/hefestoPresupuesto/reconocimiento-Presupuesto/" + tipo
						);
					});
				}
			})
			.catch((error) => {
				setBtnCarga(0);
				console.error(error);
				throw error;
			});
	};

	const guardarContrato = async (json) => {
		console.log("funcon guarda contrato");
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		return axios
			.post(route_movimientosPresupuesto + "/saveContrato", json, config)
			.then((response) => {
				console.log(response.data);
			})
			.catch((error) => {
				console.error(error);
				throw error;
			});
	};

	const obtenerMovimientos = async (idT) => {
		let data = [];
		if (idT != 0) {
			setMovimientos([]);
			setArrayMovimientos([]);
			setCuentas([]);

			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};
			try {
				let url = `${route_movimientosPresupuesto}/listaMovimientos/`;

				if (establishment.id) {
					url += `${establishment.id},`;
				} else {
					url += `null,`;
				}
				/* elegimos el tipo de moviimento que debe buscar  */
				if (tipo == 2) {
					url += `1,`;
				} else if (tipo == 4) {
					url += `3,`;
				} else if (tipo == 5) {
					url += `4,`;
				} else if (tipo == 6) {
					url += `5,`;
				}
				/* --------------------------------- */
				if (idT) {
					url += `${idT},`;
				} else {
					url += `0,`;
				}
				url += `-,`;
				url += `1900-01-01,`;
				url += `3000-01-01,`;
				url += `1`;

				const response = await axios.get(url, config);
				data = response.data;
				setArrayMovimientos(data);
				const formattedOptions = data
					.filter((item) =>
						item.listDetalles.some((detalle) => detalle.saldo > 0)
					)
					.map((item) => ({
						value: item.id,
						label: item.numero + " - Detalle: " + item.detalle.substring(0, 20),
					}));
				setMovimientos(formattedOptions);
			} catch (error) {
				console.error("Error while fetching options:", error);
			} finally {
				setIsLoading(false);
			}
		} else {
			setMovimientos([]);
			setArrayMovimientos([]);
			setCuentas([]);
		}
		return data;
	};

	const handleDetIngreso = (values, val) => {
		// Buscar si values.id ya está en el array detIngreso
		const valorString = String(val); // Asegura que sea una cadena
		const valorFloat = parseFloat(
			valorString.replace(/\./g, "").replace(",", ".")
		);

		let saldo = values.saldo;
		if (jsonIngresosAfectados.hasOwnProperty(values.id)) {
			saldo = jsonIngresosAfectados[values.id] + values.saldo;
		}

		if (valorFloat >= 0 && valorFloat <= saldo) {
			values.ingreso = valorFloat;
			const existingIndex = detIngreso.findIndex(
				(item) => item.id === values.id
			);
			setVraplicado(valorFloat);
			console.log(values);

			if (existingIndex !== -1) {
				// Si values.id ya exis te, reemplazar el valor en ese índice
				const updatedDetIngreso = [...detIngreso];
				updatedDetIngreso[existingIndex] = values;
				setDetIngreso(updatedDetIngreso);
			} else {
				// Si values.id no existe, agregarlo al array
				setDetIngreso([...detIngreso, values]);
			}
			setIsDisabled(false);
		} else {
			Swal.fire({
				icon: "warning",
				title: "El ingreso no es valido, verifica el saldo",
				showConfirmButton: false,
				timer: 1500,
			});
		}
	};

	const validarCampos = (e) => {
		if (tipo == 15) {
			setDebito(0);
			setCredito(0);
			if (e.naturaleza === 1) {
				setIsEnableCredito(true);
				setIsEnableDebito(false); // Opcional: establecer "hide" en función de tus necesidades
			} else {
				setIsEnableCredito(false);
				setIsEnableDebito(true);
			}
		} else if (tipo == 16) {
			setDebito(0);
			setCredito(0);
			if (e.naturaleza === 1) {
				setIsEnableCredito(false);
				setIsEnableDebito(true); // Opcional: establecer "hide" en función de tus necesidades
			} else {
				setIsEnableCredito(true);
				setIsEnableDebito(false);
			}
		}
	};

	const validarClase = (e) => {
		setDebito(0);
		setCredito(0);
		setClase(e.value)
		if (e.value == 1) {
			setIsEnableCredito(true);
			setIsEnableDebito(false);
		} else {
			setIsEnableCredito(false);
			setIsEnableDebito(true);
		}
	};
	// traer conceptos info ------
	const getAllConceptos = async (arrayT) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_confiPresupuesto_base}/getAllConceptosI`,
			config
		);
		const formattedOptions = result.data.map((item) => ({
			value: item.id,
			label: item.codigo + " - " + item.nombre,
			tipo: item.tipo,
			active: item.active,
		}));

		for (let i = 0; i < arrayT.length; i++) {
			if (arrayT[i] == 1) {
				setBancos(
					formattedOptions.filter(
						(objeto) => objeto.tipo == 1 && objeto.active == true
					)
				);
			} else if (arrayT[i] == 2) {
				setFrecursos(formattedOptions.filter((objeto) => objeto.tipo === 2));
			} else if (arrayT[i] == 3) {
				setFfinanciacion(
					formattedOptions.filter((objeto) => objeto.tipo === 3)
				);
			} else if (arrayT[i] == 4) {
				setCcontrato(formattedOptions.filter((objeto) => objeto.tipo === 4));
			} else if (arrayT[i] == 5) {
				setFcontratacion(
					formattedOptions.filter((objeto) => objeto.tipo === 5)
				);
			} else if (arrayT[i] == 6) {
				setTpago(formattedOptions.filter((objeto) => objeto.tipo === 6));
			}
		}
	};
	//---------- meses activos ------
	const getMesesActivos = async () => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};

			const result = await axios.get(
				`${route_configuration_base}/getMonthsActive/${establishment.id}`,
				config
			);
			setMActivos(result.data);
		} catch (error) {
			console.error("Error while fetching options:", error);
		}
	};
	// ---------- funcion que valida si mes y año estan abiertos -----------
	const verificarFecha = (json) => {
		const fechaString = json.fecha;
		const fecha = parseISO(fechaString);
		// Obtener el mes (devuelve un número entre 0 y 11, donde 0 es enero y 11 es diciembre)
		const month = getMonth(fecha) + 1; // Sumamos 1 porque los meses en JavaScript van de 0 a 11
		// Obtener el año
		const year = getYear(fecha);
		// Verificar si el año y el mes están en el array de fechas

		const fechaEnArray = mActivos.find(
			(fecha) => fecha.year == year && fecha.numero == month
		);
		return fechaEnArray ? true : false;
	};
	// --- obtener cuentas bancarias x banco
	const getCuentasxBanco = async (idB) => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};

			const result = await axios.get(
				`${route_confiPresupuesto_base}/getCuentaxBanco/${idB}`,
				config
			);
			const formattedOptions = result.data.map((item) => ({
				value: item.id,
				label: item.denominacion + " No:  " + item.numero,
				tipo: item.tipo,
				active: item.active,
			}));
			setCtasxbanco(formattedOptions.filter((objeto) => objeto.active == true));
		} catch (error) {
			console.error("Error while fetching options:", error);
		}
	};

	return (
		<div className="containerFormUser">
			<h1>{titulo}</h1>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
				innerRef={formikRef}
			>
				{(formik, props) => (
					<Form>
						{/* ------ BLOQUE BUSCADOR DE DISPONIBILIDADES --------------- */}
						{tipo == 4 ? (
							<>
								<div className="row">
									<div className="col-md-12">
										<div className="form-floating mb-2">
											<Select
												styles={customStyles}
												isSearchable={true}
												placeholder="Búsqueda de disponibilidades"
												isClearable={true}
												isLoading={isLoading}
												onInputChange={onInputChange}
												options={optionsPersons}
												onChange={(e) => {
													obtenerMovimientos(e ? e.value : 0);
												}}
											/>
											<ErrorMessage
												name="tercero"
												component="div"
												className="error"
											/>
										</div>
									</div>
								</div>
							</>
						) : null}
						{/* ----------------------------------------------------------- */}
						<div className="row">
							{/*---------- Bloque 1 ---------------------------------- */}
							{tipo == 1 ||
							tipo == 3 ||
							tipo == 15 ||
							tipo == 16 ||
							tipo == 17 ? (
								<>
									<div className="col-md-4  ">
										<div className="form-floating mb-2  ">
											<Select
												styles={customStyles}
												isSearchable="true"
												placeholder="Plan presupuestal"
												isClearable="true"
												value={cabSelect}
												options={cabecerasPlanP}
												onChange={(e) => {
													setCabSelect(e);
													loadCuentas(e ? e.value : 0);
													formik.setFieldValue("planp", e ? e.value : "");
												}}
											></Select>
										</div>
									</div>
									<div className="col-md-8  ">
										<div className="d-flex align-items-center mb-2">
											<div className="flex-grow-1">
												<Select
													styles={customStyles}
													isSearchable={true}
													placeholder="Búsqueda de terceros"
													isClearable={true}
													isLoading={isLoading}
													value={personSelected}
													onInputChange={onInputChange}
													options={optionsPersons}
													onChange={(e) => {
														setPersonSelected(e);
														formik.setFieldValue("tercero", e ? e.value : "");
														setOptionsPersons([]);
														if (tipo == 2 || tipo == 5 || tipo == 6) {
															obtenerMovimientos(e ? e.value : 0);
														}
													}}
												/>
												<ErrorMessage
													name="tercero"
													component="div"
													className="error"
												/>
											</div>
											<button
												style={{ margin: "2px" }}
												type="button"
												className="btn btn-primary"
												data-bs-toggle="modal"
												data-bs-target="#modalTercero"
											>
												<i className="fas fa-user-plus ml-2"></i>
											</button>
										</div>
									</div>
								</>
							) : tipo == 2 ||
							  tipo == 4 ||
							  tipo == 5 ||
							  tipo == 6 ||
							  tipo == 18 ? (
								<>
									<div className="col-md-7">
										<div className="d-flex align-items-center mb-2">
											<div className="flex-grow-1">
												<Select
													styles={customStyles}
													isSearchable={true}
													placeholder="Búsqueda de terceros"
													isClearable={true}
													isLoading={isLoading}
													value={personSelected}
													onInputChange={onInputChange}
													options={optionsPersons}
													onChange={(e) => {
														setPersonSelected(e);
														formik.setFieldValue("tercero", e ? e.value : "");
														setOptionsPersons([]);
														if (tipo == 2 || tipo == 5 || tipo == 6) {
															obtenerMovimientos(e ? e.value : 0);
														}
													}}
												/>
												<ErrorMessage
													name="tercero"
													component="div"
													className="error"
												/>
											</div>
											<button
												style={{ margin: "2px" }}
												type="button"
												className="btn btn-primary"
												data-bs-toggle="modal"
												data-bs-target="#modalTercero"
											>
												<i className="fas fa-user-plus ml-2"></i>
											</button>
										</div>
									</div>

									<div className="col-md-5  ">
										<div className="form-floating mb-2  ">
											<Field
												style={inputStyles}
												value={valDetalle}
												type="textarea"
												name="detalle"
												id="detalle"
												className="form-control"
												placeholder="Detalle"
												onChange={(e) => {
													const newValue = e.target.value;
													setValDetalle(newValue);
													formik.setFieldValue("detalle", newValue);
												}}
											/>

											<label
												style={{ zIndex: "0" }}
												htmlFor="observaciones"
												className="form-label"
											>
												Detalle
											</label>
											<ErrorMessage
												name="detalle"
												component="div"
												className="error"
											/>
										</div>
									</div>
								</>
							) : (
								""
							)}
							{/* --------------------------- Bloque extra para agregar conceptos para informes ---------- */}
							{tipo == 2 ? (
								<>
									<div className="col-md-3">
										<div className="form-floating mb-2">
											<Select
												value={bancosS}
												styles={customStyles}
												isSearchable={true}
												placeholder="Bancos"
												isClearable={true}
												isLoading={isLoading}
												onInputChange={onInputChange}
												options={bancos}
												onChange={(e) => {
													setCtabancoS(null);
													if (e) {
														setBancosS(e ? e : null);
														formik.setFieldValue("banco", e ? e.value : null);
														getCuentasxBanco(e ? e.value : null);
													} else {
														setBancosS(null);
														setCtasxbanco([]);
														formik.setFieldValue(null);
													}
												}}
											/>
											<ErrorMessage
												name="banco"
												component="div"
												className="error"
											/>
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-floating mb-2">
											<Select
												value={ctabancoS}
												styles={customStyles}
												isSearchable={true}
												placeholder="Cuenta Banco"
												isClearable={true}
												isLoading={isLoading}
												onInputChange={onInputChange}
												options={ctasxbanco}
												onChange={(e) => {
													setCtabancoS(e ? e : null);
													formik.setFieldValue("ctabanco", e ? e.value : null);
												}}
											/>
											<ErrorMessage
												name="ctabanco"
												component="div"
												className="error"
											/>
										</div>
									</div>

									<div className="col-md-5">
										<div className="form-floating mb-2">
											<Select
												value={frecursosS}
												styles={customStyles}
												isSearchable={true}
												placeholder="Fuente de recursos"
												isClearable={true}
												isLoading={isLoading}
												onInputChange={onInputChange}
												options={frecursos}
												onChange={(e) => {
													setFrecursosS(e ? e : null);
													formik.setFieldValue("frecursos", e ? e.value : null);
												}}
											/>
											<ErrorMessage
												name="frecursos"
												component="div"
												className="error"
											/>
										</div>
									</div>
								</>
							) : tipo == 4 ? (
								<>
									<div className="col-md-7">
										<div className="form-floating mb-2">
											<Select
												value={ffinanciacionS}
												styles={customStyles}
												isSearchable={true}
												placeholder="Fuente de financiación"
												isClearable={true}
												isLoading={isLoading}
												onInputChange={onInputChange}
												options={ffinanciacion}
												onChange={(e) => {
													setFfinanciacionS(e ? e : null);
													formik.setFieldValue(
														"ffinanciacion",
														e ? e.value : null
													);
												}}
											/>
											<ErrorMessage
												name="ffinanciacion"
												component="div"
												className="error"
											/>
										</div>
									</div>

									<div className="col-md-5">
										<div className="form-floating mb-2">
											<Select
												value={frecursosS}
												styles={customStyles}
												isSearchable={true}
												placeholder="Fuente de recursos"
												isClearable={true}
												isLoading={isLoading}
												onInputChange={onInputChange}
												options={frecursos}
												onChange={(e) => {
													setFrecursosS(e ? e : null);
													formik.setFieldValue("frecursos", e ? e.value : null);
												}}
											/>
											<ErrorMessage
												name="frecursos"
												component="div"
												className="error"
											/>
										</div>
									</div>
									<div className="col-md-7">
										<div className="form-floating mb-2">
											<Select
												value={ccontratoS}
												styles={customStyles}
												isSearchable={true}
												placeholder="clase contrato"
												isClearable={true}
												isLoading={isLoading}
												onInputChange={onInputChange}
												options={ccontrato}
												onChange={(e) => {
													setCcontratoS(e ? e : null);
													formik.setFieldValue("ccontrato", e ? e.value : null);
												}}
											/>
											<ErrorMessage
												name="ccontrato"
												component="div"
												className="error"
											/>
										</div>
									</div>

									<div className="col-md-5">
										<div className="form-floating mb-2">
											<Select
												value={fcontratacionS}
												styles={customStyles}
												isSearchable={true}
												placeholder="Forma de contratación"
												isClearable={true}
												isLoading={isLoading}
												onInputChange={onInputChange}
												options={fcontratacion}
												onChange={(e) => {
													setFcontratacionS(e ? e : null);
													formik.setFieldValue(
														"fcontratacion",
														e ? e.value : null
													);
												}}
											/>
											<ErrorMessage
												name="fcontratacion"
												component="div"
												className="error"
											/>
										</div>
									</div>
								</>
							) : tipo == 6 ? (
								<>
									<div className="col-md-3">
										<div className="form-floating mb-2">
											<Select
												value={bancosS}
												styles={customStyles}
												isSearchable={true}
												placeholder="Bancos"
												isClearable={true}
												isLoading={isLoading}
												onInputChange={onInputChange}
												options={bancos}
												onChange={(e) => {
													setCtabancoS(null);
													if (e) {
														setBancosS(e ? e : null);
														formik.setFieldValue("banco", e ? e.value : null);
														getCuentasxBanco(e ? e.value : null);
													} else {
														setBancosS(null);
														setCtasxbanco([]);
														formik.setFieldValue(null);
													}
												}}
											/>
											<ErrorMessage
												name="banco"
												component="div"
												className="error"
											/>
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-floating mb-2">
											<Select
												value={ctabancoS}
												styles={customStyles}
												isSearchable={true}
												placeholder="Cuenta Banco"
												isClearable={true}
												isLoading={isLoading}
												onInputChange={onInputChange}
												options={ctasxbanco}
												onChange={(e) => {
													setCtabancoS(e ? e : null);
													formik.setFieldValue("ctabanco", e ? e.value : null);
												}}
											/>
											<ErrorMessage
												name="ctabanco"
												component="div"
												className="error"
											/>
										</div>
									</div>

									<div className="col-md-5">
										<div className="form-floating mb-2">
											<Select
												value={tpagoS}
												styles={customStyles}
												isSearchable={true}
												placeholder="Tipo de pago"
												isClearable={true}
												isLoading={isLoading}
												onInputChange={onInputChange}
												options={tpago}
												onChange={(e) => {
													setTpagoS(e ? e : null);
													formik.setFieldValue("tpago", e ? e.value : null);
												}}
											/>
											<ErrorMessage
												name="tpago"
												component="div"
												className="error"
											/>
										</div>
									</div>
								</>
							) : tipo == 18 ? (
								<>
									<div className="col-md-3">
										<div className="form-floating mb-2">
											<Select
												value={bancosS}
												styles={customStyles}
												isSearchable={true}
												placeholder="Bancos"
												isClearable={true}
												isLoading={isLoading}
												onInputChange={onInputChange}
												options={bancos}
												onChange={(e) => {
													setCtabancoS(null);
													if (e) {
														setBancosS(e ? e : null);
														formik.setFieldValue("banco", e ? e.value : null);
														getCuentasxBanco(e ? e.value : null);
													} else {
														setBancosS(null);
														setCtasxbanco([]);
														formik.setFieldValue(null);
													}
												}}
											/>
											<ErrorMessage
												name="banco"
												component="div"
												className="error"
											/>
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-floating mb-2">
											<Select
												value={ctabancoS}
												styles={customStyles}
												isSearchable={true}
												placeholder="Cuenta Banco"
												isClearable={true}
												isLoading={isLoading}
												onInputChange={onInputChange}
												options={ctasxbanco}
												onChange={(e) => {
													setCtabancoS(e ? e : null);
													formik.setFieldValue("ctabanco", e ? e.value : null);
												}}
											/>
											<ErrorMessage
												name="ctabanco"
												component="div"
												className="error"
											/>
										</div>
									</div>

									<div className="col-md-5">
										<div className="form-floating mb-2  ">
											<Field
												style={dateStyles}
												type="date"
												name="fecha"
												id="fecha"
												className="form-control"
												placeholder="Fecha "
												onChange={(e) => {
													formik.setFieldValue("fecha", e.target.value);
												}}
											/>
											<label
												style={{ zIndex: "0" }}
												htmlFor="fecha"
												className="form-label"
											>
												Fecha
											</label>
											<ErrorMessage
												name="fecha"
												component="div"
												className="error"
											/>
										</div>
									</div>
								</>
							) : null}

							{/*------------------------------- Bloque 2---------------------------- */}
							{tipo == 1 ||
							tipo == 3 ||
							tipo == 15 ||
							tipo == 16 ||
							tipo == 17 ? (
								<>
									<div className="col-md-4  ">
										<div className="form-floating mb-2  ">
											<Field
												style={dateStyles}
												type="date"
												name="fecha"
												id="fecha"
												className="form-control"
												placeholder="Fecha "
												onChange={(e) => {
													formik.setFieldValue("fecha", e.target.value);
												}}
											/>
											<label
												style={{ zIndex: "0" }}
												htmlFor="fecha"
												className="form-label"
											>
												Fecha
											</label>
											<ErrorMessage
												name="fecha"
												component="div"
												className="error"
											/>
										</div>
									</div>
									<div className="col-md  ">
										<div className="form-floating mb-2  ">
											<Field
												style={inputStyles}
												type="textarea"
												name="detalle"
												id="detalle"
												className="form-control"
												placeholder="Detalle"
											/>
											<label
												style={{ zIndex: "0" }}
												htmlFor="observaciones"
												className="form-label"
											>
												Detalle
											</label>
											<ErrorMessage
												name="detalle"
												component="div"
												className="error"
											/>
										</div>
									</div>

									{tipo == 15 || tipo == 16 || tipo == 17 ? (
										<>
											<div className="col-md  ">
												<div className="form-floating mb-2  ">
													<Field
														style={inputStyles}
														type="textarea"
														name="noacto"
														id="noacto"
														className="form-control"
														placeholder="No acto administrativo"
														onChange={(e) => {
															formik.setFieldValue("noacto", e.target.value);
														}}
													/>
													<label
														style={{ zIndex: "0" }}
														htmlFor="noacta"
														className="form-label"
													>
														No Acto
													</label>
													<ErrorMessage
														name="noacto"
														component="div"
														className="error"
													/>
												</div>
											</div>
										</>
									) : null}
								</>
							) : (
								<></>
							)}
							{/* --------------------- Bloque 3 ------------------------------------- */}
							{tipo == 2 || tipo == 4 || tipo == 5 || tipo == 6 ? (
								<>
									<div className="col-md-7  ">
										<div className="form-floating mb-2  ">
											<Select
												styles={customStyles}
												isSearchable="true"
												placeholder="Documentos"
												isClearable="true"
												value={movSelected}
												isLoading={isLoading}
												onInputChange={onInputChange}
												options={movimientos}
												onChange={(e) => {
													setMovSelected(e ? e : null);
													asignarPlanP(e ? e : 0);
												}}
											></Select>
											<ErrorMessage
												name="documentos"
												component="div"
												className="error"
											/>
										</div>
									</div>
									<div className="col-md-3  ">
										<div className="form-floating mb-2  ">
											<Field
												style={inputStyles}
												type="date"
												name="fecha"
												id="fecha"
												className="form-control"
												placeholder="Fecha "
												onChange={(e) => {
													formik.setFieldValue("fecha", e.target.value);
												}}
											/>
											<label
												style={{ zIndex: "0" }}
												htmlFor="fecha"
												className="form-label"
											>
												Fecha
											</label>
											<ErrorMessage
												name="fecha"
												component="div"
												className="error"
											/>
										</div>
									</div>
									<div className="col-md-2" style={{ paddingTop: "15px" }}>
										<button
											onClick={() => {
												agregarIngreso(formik);
											}}
											type="button"
											style={{ width: "90%" }}
											className="btn btn-primary"
										>
											<FaPlusCircle />
										</button>
									</div>
								</>
							) : (
								<></>
							)}

							{tipo == 1 || tipo == 3 || tipo == 15 || tipo == 16 ? (
								<>
									<div
										style={{
											padding: "5px",
											marginBottom: "5px",
										}}
									>
										<div className="row">
											{(tipo == 1 || tipo == 3) &&
											establishment &&
											establishment.institucion === true ? (
												<div className="col-md">
													<div className="form-floating mb-2">
														<Select
															styles={customStyles}
															isSearchable="true"
															placeholder="Instituciones"
															isClearable="true"
															value={institucionS}
															isLoading={isLoading}
															//onInputChange={onInputChange}
															options={instituciones}
															onChange={(e) => {
																if (e != null && e.value) {
																	setInstitucionS(e);
																	//formik.setFieldValue("institucion", e.value);
																}
															}}
														></Select>
														<ErrorMessage
															name="institucion"
															component="div"
															className="error"
														/>
													</div>
												</div>
											) : null}
											<div className="col-md-3  ">
												<div className="form-floating mb-2  ">
													<Select
														ref={selectRef}
														styles={stylePlan}
														isSearchable="true"
														placeholder="Cuenta"
														isClearable="true"
														id="selectCta"
														options={proyecciones}
														onChange={(e) => {
															if (e) {
																validarCampos(e);
																setCuenta(e.value);
																if (e.naturaleza === 1) {
																	setVerDebito(true);
																	setVerCredito(false); // Opcional: establecer "hide" en función de tus necesidades
																} else {
																	setVerDebito(false);
																	setVerCredito(true);
																}
															} else {
																setVerDebito(false);
																setVerCredito(false);
															}
														}}
													></Select>
												</div>
											</div>

											<div className="col-md-2  ">
												<div className="form-floating mb-2  ">
													<NumericFormat
														style={{ ...inputStyles, textAlign: "right" }}
														value={debito}
														className="form-control"
														thousandSeparator="."
														decimalSeparator=","
														disabled={isEnableDebito}
														onChange={(e) => {
															setDebito(
																parseFloat(
																	e.target.value
																		.replace(/\./g, "")
																		.replace(",", ".")
																)
															);
															if (e.target.value > 0) {
																setCredito(0);
															}
														}}
													/>
													<label
														style={{ zIndex: "0" }}
														htmlFor="valorPlan"
														className="form-label"
													>
														Débitos
													</label>
												</div>
												<label
													style={{
														zIndex: "0",
														visibility: verDebito ? "visible" : "hidden",
													}}
													className="text-info font-weight-bold"
												>
													La cuenta es de naturaleza débito
												</label>
											</div>

											<div className="col-md-2  ">
												<div className="form-floating mb-2  ">
													<NumericFormat
														style={{
															...inputStyles,
															textAlign: "right",
															zIndex: "0",
														}}
														value={credito}
														className="form-control"
														thousandSeparator="."
														decimalSeparator=","
														disabled={isEnableCredito}
														onChange={(e) => {
															setCredito(
																parseFloat(
																	e.target.value
																		.replace(/\./g, "")
																		.replace(",", ".")
																)
															);
															if (e.target.value > 0) {
																setDebito(0);
															}
														}}
													/>
													<label
														style={{ zIndex: "0" }}
														htmlFor="valorPlan"
														className="form-label"
													>
														Créditos
													</label>
												</div>
												<label
													style={{
														zIndex: "0",
														visibility: verCredito ? "visible" : "hidden",
													}}
													className="text-info font-weight-bold"
												>
													La cuenta es de naturaleza crédito
												</label>
											</div>

											<div className="col-md-2" style={{ paddingTop: "15px" }}>
												<button
													onClick={agregarTransaccion}
													type="button"
													style={{ width: "90%" }}
													className="btn btn-primary"
												>
													<FaPlusCircle />
												</button>
											</div>
										</div>
									</div>
								</>
							) : (
								<></>
							)}

							{/*--------------- BLOQUE PARATRASLADOS PRESPUPUESTALES ------------------ */}
							{tipo == 17 ? (
								<>
									<div
										style={{
											padding: "5px",
											marginBottom: "10px",
											border: "1px solid #ccc",
										}}
									>
										<div className="row">
											<div className="col-md-2  ">
												<div className="form-floating mb-2  ">
													<Select
														styles={styleClase}
														placeholder="Clase"
														isClearable={true}
														options={[
															{ value: 1, label: "Origen" },
															{ value: 2, label: "Destino" },
														]}
														onChange={(e) => {
															if (e) {
																validarClase(e);
															} else {
																setIsEnableCredito(true);
																setIsEnableDebito(true);
																setVerDebito(false);
																setVerCredito(false);
															}
														}}
													></Select>
												</div>
											</div>

											<div className="col-md-3  ">
												<div className="form-floating mb-2  ">
													<Select
														ref={selectRef}
														styles={stylePlan}
														isSearchable="true"
														placeholder="Cuenta"
														isClearable="true"
														id="selectCta"
														options={proyecciones}
														onChange={(e) => {
															if (e) {
																validarCampos(e);
																setCuenta(e.value);
																if (e.naturaleza === 1) {
																	setVerDebito(true);
																	setVerCredito(false); // Opcional: establecer "hide" en función de tus necesidades
																} else {
																	setVerDebito(false);
																	setVerCredito(true);
																}
															} else {
																setVerDebito(false);
																setVerCredito(false);
															}
														}}
													></Select>
												</div>
											</div>

											<div className="col-md-3  ">
												<div className="form-floating mb-2  ">
													<NumericFormat
														value={debito}
														style={{ textAlign: "right" }}
														className="form-control"
														thousandSeparator="."
														decimalSeparator=","
														disabled={isEnableDebito}
														onChange={(e) => {
															setDebito(
																parseFloat(
																	e.target.value
																		.replace(/\./g, "")
																		.replace(",", ".")
																)
															);
															if (e.target.value > 0) {
																setCredito(0);
															}
														}}
													/>
													<label
														style={{ zIndex: "0" }}
														htmlFor="valorPlan"
														className="form-label"
													>
														Débitos
													</label>
												</div>
												<label
													style={{
														zIndex: "0",
														visibility: verDebito ? "visible" : "hidden",
													}}
													className="text-info font-weight-bold"
												>
													La cuenta es de naturaleza débito
												</label>
											</div>

											<div className="col-md-3  ">
												<div className="form-floating mb-2  ">
													<NumericFormat
														value={credito}
														style={{ textAlign: "right" }}
														className="form-control"
														thousandSeparator="."
														decimalSeparator=","
														disabled={isEnableCredito}
														onChange={(e) => {
															setCredito(
																parseFloat(
																	e.target.value
																		.replace(/\./g, "")
																		.replace(",", ".")
																)
															);
															if (e.target.value > 0) {
																setDebito(0);
															}
														}}
													/>
													<label
														style={{ zIndex: "0" }}
														htmlFor="valorPlan"
														className="form-label"
													>
														Créditos
													</label>
												</div>
												<label
													style={{
														zIndex: "0",
														visibility: verCredito ? "visible" : "hidden",
													}}
													className="text-info font-weight-bold"
												>
													La cuenta es de naturaleza crédito
												</label>
											</div>

											<div className="col-md-1" style={{ paddingTop: "15px" }}>
												<button
													onClick={agregarTransaccion}
													type="button"
													style={{ width: "90%" }}
													className="btn btn-primary"
												>
													<FaPlusCircle />
												</button>
											</div>
										</div>
									</div>
								</>
							) : (
								<></>
							)}

							{/* -------------- BLOQUE PARA PAGOS SN AFECATRCION PRESUPUESTAL ---------- */}
							{tipo == 18 ? (
								<>
									<div
										style={{
											padding: "5px",
											marginBottom: "5px",
										}}
									>
										<div className="row">
											<div className="col-md-3  ">
												<div className="form-floating mb-2  ">
													<NumericFormat
														style={{ ...inputStyles, textAlign: "right" }}
														value={credito}
														className="form-control"
														thousandSeparator="."
														decimalSeparator=","
														onChange={(e) => {
															setCredito(
																parseFloat(
																	e.target.value
																		.replace(/\./g, "")
																		.replace(",", ".")
																)
															);
															if (e.target.value > 0) {
																setDebito(0);
															}
														}}
													/>
													<label
														style={{ zIndex: "0" }}
														htmlFor="valorPlan"
														className="form-label"
													>
														Valor
													</label>
												</div>
											</div>
											<div className="col-md-4  ">
												<div className="form-floating mb-2  ">
													<Field
														style={inputStyles}
														value={detalleSA}
														type="text"
														className="form-control"
														placeholder="Detalle"
														onChange={(e) => {
															setDetalleSA(e.target.value);
														}}
													/>

													<label
														style={{ zIndex: "0" }}
														htmlFor="observaciones"
														className="form-label"
													>
														Detalle
													</label>
													<ErrorMessage
														name="detalle"
														component="div"
														className="error"
													/>
												</div>
											</div>

											<div className="col-md-2" style={{ paddingTop: "15px" }}>
												<button
													onClick={agregaPagoSA}
													type="button"
													style={{ width: "90%" }}
													className="btn btn-primary"
												>
													<FaPlusCircle />
												</button>
											</div>
										</div>
									</div>
								</>
							) : null}
							{/* ------------------------------------- */}
						</div>
						<h2>Movimientos</h2>
						{tipo == 1 ||
						tipo == 3 ||
						tipo == 15 ||
						tipo == 16 ||
						tipo == 17 ? (
							<>
								{/* --------------- TABLA RECONOCIMIENTOS ---------------------- */}
								<table
									className="table table-striped table-hover"
									style={{ border: 1 }}
								>
									<thead>
										<tr>
											{(tipo == 1 || tipo == 3) &&
											establishment.institucion == true ? (
												<th style={{ width: "15%", textAlign: "center" }}>
													{" "}
													Institución{" "}
												</th>
											) : null}

											<th style={{ textAlign: "center" }}>Cuenta</th>

											<th style={{ width: "20%", textAlign: "right" }}>
												Débitos
											</th>
											<th style={{ width: "20%", textAlign: "right" }}>
												Créditos
											</th>
											<th style={{ width: "20%", textAlign: "right" }}>
												Opciones
											</th>
										</tr>
									</thead>
								</table>
								<div style={{ maxHeight: "200px", overflowY: "auto" }}>
									<table className="table table-striped table-hover">
										<tbody>
											{cuentas.map((transaccion, index) => {
												const cuenta = proyecciones.find(
													(opcion) => opcion.value === transaccion.cuenta
												);
												let insts = instituciones.find(
													(i) => i.value === transaccion.institucion
												);
												return (
													<tr key={index}>
														{transaccion.institucion != null ? (
															<td style={{ width: "15%", textAlign: "center" }}>
																{" "}
																{transaccion.institucionO
																	? transaccion.institucionO.nombre
																	: insts.label
																	? insts.label
																	: ""}{" "}
															</td>
														) : null}
														<td style={{ textAlign: "left" }}>
															{cuenta ? cuenta.label : "No encontrado"}
														</td>
														<td style={{ width: "20%", textAlign: "right" }}>
															<NumericFormat
																value={transaccion.debito} // El número que deseas formatear
																displayType={"text"} // Puedes usar 'input' si deseas que sea un campo de entrada
																thousandSeparator="."
																decimalSeparator="," // Agregar separadores de miles
																decimalScale={2} // Especificar el número de decimales
																prefix={"$"} // Agregar un prefijo, como el símbolo de la moneda
															/>
														</td>
														<td style={{ width: "20%", textAlign: "right" }}>
															<NumericFormat
																value={transaccion.credito} // El número que deseas formatear
																displayType={"text"} // Puedes usar 'input' si deseas que sea un campo de entrada
																thousandSeparator="."
																decimalSeparator="," // Agregar separadores de miles
																decimalScale={2} // Especificar el número de decimales
																prefix={"$"} // Agregar un prefijo, como el símbolo de la moneda
															/>
														</td>
														<td style={{ width: "20%", textAlign: "right" }}>
															<button
																className="btnDelete"
																type="button"
																onClick={() => eliminarMov(transaccion.id)}
															>
																<i className="fas fa-trash-alt"></i>
															</button>
														</td>
													</tr>
												);
											})}
											{tipo == 15 || tipo == 16 || tipo == 17 ? (
												<>
													<tr>
														<th>TOTAL</th>
														<td style={{ width: "20%", textAlign: "right" }}>
															<NumericFormat
																value={totalDB} // El número que deseas formatear
																displayType={"text"} // Puedes usar 'input' si deseas que sea un campo de entrada
																thousandSeparator="."
																decimalSeparator="," // Agregar separadores de miles
																decimalScale={2} // Especificar el número de decimales
																prefix={"$"} // Agregar un prefijo, como el símbolo de la moneda
															/>
														</td>
														<td style={{ width: "20%", textAlign: "right" }}>
															<NumericFormat
																value={totalCR} // El número que deseas formatear
																displayType={"text"} // Puedes usar 'input' si deseas que sea un campo de entrada
																thousandSeparator="."
																decimalSeparator="," // Agregar separadores de miles
																decimalScale={2} // Especificar el número de decimales
																prefix={"$"} // Agregar un prefijo, como el símbolo de la moneda
															/>
														</td>
													</tr>
												</>
											) : null}
										</tbody>
									</table>
								</div>
								{/* ------------------------------------------------------------ */}
							</>
						) : tipo == 2 || tipo == 4 || tipo == 5 || tipo == 6 ? (
							<>
								{/* ------------- TABLA INGRESO -------------------------------- */}
								<div
									className="card"
									style={{
										maxHeight: "300px",
										overflowY: "auto",
										marginBottom: "10px",
									}}
								>
									{cuentas.map((movDet, index) => (
										<div key={index}>
											<div className="card-header">
												{"Numero: " +
													movDet.numero +
													" / Fecha: " +
													movDet.fecha}
												<i
													onClick={() => eliminarMov(movDet.id)}
													style={{ float: "right" }}
													className="btn btn-danger fas fa-times-circle"
												></i>
											</div>
											<div className="card-body" style={{ marginTop: "-20px" }}>
												<table
													//className="table table-success table-striped"
													id="tableUsers"
													style={{ width: "100%" }}
												>
													<tr>
														{establishment.institucion == true && (tipo==1 || tipo==2 || tipo==3 || tipo==4 ) ? (
															<th
																style={{ textAlign: "center", width: "15%" }}
															>Institución</th>
														) : null}
														<th style={{ textAlign: "center" }}>Cuenta</th>
														<th style={{ textAlign: "center", width: "10%" }}>
															Débito
														</th>
														<th style={{ textAlign: "center", width: "10%" }}>
															Crédito
														</th>

														<th style={{ textAlign: "center", width: "15%" }}>
															Saldo
														</th>

														<th style={{ textAlign: "center", width: "15%" }}>
															{tipo == 2
																? "Ingreso"
																: tipo == 4
																? "Registro"
																: tipo == 5
																? "Obligación"
																: "Pago"}
														</th>
													</tr>
													{movDet.listDetalles.map((det, index1) => {
														let saldo2 = 0;
														if (jsonIngresosAfectados.hasOwnProperty(det.id)) {
															saldo2 = jsonIngresosAfectados[det.id];
														} else {
															saldo2 = 0;
														}

														return det.saldo + saldo2 > 0 ? (
															<tr key={index1}>
																{establishment.institucion == true &&
																det.institucion != null  ? (
																	<td
																		style={{
																			textAlign: "center",
																			width: "15%",
																		}}
																	>
																		{det.institucionO.codigo +
																			" - " +
																			det.institucionO.nombre}
																	</td>
																) : null}

																<td style={{ textAlign: "left" }}>
																	{det.cuentaObject.codigo +
																		" - " +
																		det.cuentaObject.nombre}
																</td>
																<td style={{ textAlign: "right" }}>
																	<NumericFormat
																		value={det.debito}
																		displayType={"text"}
																		thousandSeparator="."
																		decimalSeparator=","
																		decimalScale={2}
																		prefix={"$"}
																	/>
																</td>
																<td style={{ textAlign: "right" }}>
																	{det.credito}
																</td>
																<td style={{ textAlign: "right" }}>
																	<NumericFormat
																		value={
																			jsonIngresosAfectados.hasOwnProperty(
																				det.id
																			)
																				? jsonIngresosAfectados[det.id] +
																				  det.saldo
																				: det.saldo
																		}
																		displayType={"text"}
																		thousandSeparator="."
																		decimalSeparator=","
																		decimalScale={2}
																		prefix={"$"}
																	/>
																</td>
																<td>
																	<NumericFormat
																		style={{ textAlign: "right" }}
																		className="form-control"
																		thousandSeparator="."
																		value={
																			jsonIngresosAfectados.hasOwnProperty(
																				det.id
																			)
																				? jsonIngresosAfectados[det.id]
																				: null
																		}
																		decimalSeparator=","
																		onBlur={(e) => {
																			handleDetIngreso(det, e.target.value);
																		}}
																	/>
																</td>
															</tr>
														) : null;
													})}
												</table>
											</div>
										</div>
									))}
								</div>
								{/* ------------------------------------------------------------ */}
							</>
						) : tipo == 18 ? (
							<>
								{/* ------------- TABLA PAGOS SA -------------------------------- */}
								<div
									className="card"
									style={{
										maxHeight: "300px",
										overflowY: "auto",
										marginBottom: "10px",
									}}
								>
									<div className="card-body">
										<table
											style={{ width: "100%" }}
											id="tableUsers"
											className="table table-striped table-hover"
										>
											<thead>
												<tr>
													<th style={{ textAlign: "center", width: "50%" }}>
														Detalle
													</th>
													<th style={{ textAlign: "center", width: "10%" }}>
														Valor
													</th>
													<th style={{ textAlign: "center", width: "15%" }}>
														Opciones
													</th>
												</tr>
											</thead>
											<tbody>
												{pagosSA.map((det, index1) => (
													<tr key={det.contador}>
														<td style={{ textAlign: "left" }}>{det.detalle}</td>
														<td style={{ textAlign: "right" }}>
															<NumericFormat
																value={det.credito}
																displayType={"text"}
																thousandSeparator="."
																decimalSeparator=","
																decimalScale={2}
																prefix={"$"}
															/>
														</td>
														<td style={{ textAlign: "right" }}>
															<button
																className="btnDelete"
																type="button"
																onClick={() => eliminarPagoSA(det.contador)}
															>
																<i className="fas fa-trash-alt"></i>
															</button>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
								{/* ------------------------------------------------------------ */}
							</>
						) : null}
						{/* modal de informacin de contrato */}
						{tipo == 4 ? (
							<button
								type="button"
								className="btn btn-primary"
								data-bs-toggle="modal"
								data-bs-target="#modalContrato"
							>
								Información contrato
							</button>
						) : null}
						{/* --------------------------- */}
						&nbsp;
						{btnCarga === 0 ? (
							<button
								disabled={isDisabled} // Usamos una expresión para habilitar o deshabilitar el botón
								type="submit"
								className="btn btn-primary"
							>
								Guardar
							</button>
						) : (
							/* spinner carga */
							<button className="btn btn-primary" type="button" disabled>
								<span
									className="spinner-border spinner-border-sm"
									aria-hidden="true"
								></span>
								<span role="status"> Guardando...</span>
							</button>
							/*--------------- */
						)}
						&nbsp;
						<NavLink
							className="navInfo"
							to={{
								pathname:
									"/menu/hefestoPresupuesto/reconocimiento-Presupuesto/" + tipo,
							}}
						>
							<button className="btn btn-danger">Cancelar</button>
						</NavLink>
						&nbsp;
					</Form>
				)}
			</Formik>
			{/* modal de informacin de contrato */}
			<div
				className="modal fade"
				id="modalContrato"
				tabindex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="exampleModalLabel">
								Información de contrato
							</h1>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div className="modal-body">
							{/* --- formulario */}
							<Formik
								initialValues={initialValuesCto}
								onSubmit={handleSubmitCto}
								innerRef={formikCto}
							>
								{(formik, props) => (
									<Form>
										<div className="row">
											{/* --------bloque 1------- */}
											<div className="col-md-3  ">
												<div className="form-floating mb-3  ">
													<Field
														style={inputStyles}
														type="text"
														name="numcontrato"
														id="numcontrato"
														className="form-control"
														//onChange={(e) => {}}
													/>

													<label htmlFor="observaciones" className="form-label">
														Número Del Contrato
													</label>
													<ErrorMessage
														name="numContrato"
														component="div"
														className="error"
													/>
												</div>
											</div>
											<div className="col-md-3  ">
												<div className="form-floating mb-3  ">
													<Field
														style={inputStyles}
														type="text"
														name="codproyecto"
														id="codproyecto"
														className="form-control"
													/>

													<label htmlFor="codproyecto" className="form-label">
														Codigo Banco Proyecto
													</label>
													<ErrorMessage
														name="codproyecto"
														component="div"
														className="error"
													/>
												</div>
											</div>
											<div className="col-md-3  ">
												<div className="form-floating mb-3  ">
													<Field
														style={inputStyles}
														type="text"
														name="linead"
														id="linead"
														className="form-control"
													/>

													<label htmlFor="linead" className="form-label">
														Linea O Estrategia Desarrollada
													</label>
													<ErrorMessage
														name="linead"
														component="div"
														className="error"
													/>
												</div>
											</div>
											<div className="col-md-3  ">
												<div className="form-floating mb-2  ">
													<Select
														styles={customStyles}
														isSearchable="true"
														placeholder="Búsqueda de contratista "
														isClearable="true"
														isLoading={isLoading}
														value={contratistaSelected}
														onInputChange={onInputChange}
														options={optionsPersons}
														onChange={(e) => {
															setContratistaSelected(e);
															formik.setFieldValue(
																"contratista",
																e ? e.value : ""
															);
															setOptionsPersons([]);
														}}
													></Select>
													<ErrorMessage
														name="tercero"
														component="div"
														className="error"
													/>
												</div>
											</div>
											{/* ----------------------- */}
											<div className="col-md-3  ">
												<div className="form-floating mb-2  ">
													<Field
														style={dateStyles}
														type="date"
														name="fechaf"
														id="fechaf"
														className="form-control"
														placeholder="Fecha "
														onChange={(e) => {
															formik.setFieldValue("fechaf", e.target.value);
														}}
													/>
													<label htmlFor="fechaf" className="form-label">
														Fecha Firma Contrato
													</label>
													<ErrorMessage
														name="fecha"
														component="div"
														className="error"
													/>
												</div>
											</div>
											<div className="col-md-3  ">
												<div className="form-floating mb-2  ">
													<Select
														styles={customStyles}
														isSearchable="true"
														placeholder="Búsqueda de interventor "
														isClearable="true"
														isLoading={isLoading}
														value={interventorSelected}
														onInputChange={onInputChange}
														options={optionsPersons}
														onChange={(e) => {
															setInterventorSelected(e);
															formik.setFieldValue(
																"interventor",
																e ? e.value : ""
															);
															setOptionsPersons([]);
														}}
													></Select>
													<ErrorMessage
														name="interventor"
														component="div"
														className="error"
													/>
												</div>
											</div>
											<div className="col-md-3  ">
												<div className="form-floating mb-2  ">
													<Select
														styles={customStyles}
														isSearchable="true"
														placeholder="Tipo De Vinculación Interventor O Supervisor"
														isClearable="true"
														isLoading={isLoading}
														value={vinculacionSelected}
														onInputChange={onInputChange}
														options={[
															{ label: "Interno", value: "Interno" },
															{ label: "Externo", value: "Externo" },
															{ label: "Nd", value: "Nd" },
														]}
														onChange={(e) => {
															setVinculacionSelected(e);
															formik.setFieldValue(
																"vinculacioni",
																e ? e.value : ""
															);
														}}
													></Select>
													<ErrorMessage
														name="interventor"
														component="div"
														className="error"
													/>
												</div>
											</div>
											<div className="col-md-3  ">
												<div className="form-floating mb-2  ">
													<Field
														style={dateStyles}
														type="date"
														name="fechaagu"
														id="fechaagu"
														className="form-control"
														placeholder="Fecha "
														onChange={(e) => {
															formik.setFieldValue("fechaagu", e.target.value);
														}}
													/>
													<label htmlFor="fechaagu" className="form-label">
														Fecha Aprobación Garantía Unica
													</label>
													<ErrorMessage
														name="fechaagu"
														component="div"
														className="error"
													/>
												</div>
											</div>
											{/* ------------------------- */}
											<div className="col-md-3  ">
												<div className="form-floating mb-2  ">
													<Field
														style={dateStyles}
														type="date"
														name="fechaic"
														id="fechaic"
														className="form-control"
														placeholder="Fecha "
														onChange={(e) => {
															formik.setFieldValue("fechaic", e.target.value);
														}}
													/>
													<label htmlFor="fechaagu" className="form-label">
														Fecha Iniciación Contrato
													</label>
													<ErrorMessage
														name="fechaic"
														component="div"
														className="error"
													/>
												</div>
											</div>
											<div className="col-md-3  ">
												<div className="form-floating mb-3  ">
													<Field
														style={inputStyles}
														type="number"
														name="plazod"
														id="plazod"
														className="form-control"
														//onChange={(e) => {}}
													/>

													<label htmlFor="plazod" className="form-label">
														Plazo Contrato En Dias Calendario
													</label>
													<ErrorMessage
														name="plazod"
														component="div"
														className="error"
													/>
												</div>
											</div>
											<div className="col-md-3  ">
												<div className="form-floating mb-2  ">
													<Select
														styles={customStyles}
														isSearchable="true"
														placeholder="Se Pactó Anticipo Al Contrato"
														isClearable="true"
														isLoading={isLoading}
														value={anticipoSelected}
														onInputChange={onInputChange}
														options={[
															{ label: "Si", value: "Si" },
															{ label: "No", value: "No" },
														]}
														onChange={(e) => {
															setanticipoSelected(e);
															formik.setFieldValue("pactoa", e ? e.value : "");
														}}
													></Select>
													<ErrorMessage
														name="pactoa"
														component="div"
														className="error"
													/>
												</div>
											</div>
											<div className="col-md-3  ">
												<div className="form-floating mb-3  ">
													<Field
														style={inputStyles}
														type="number"
														name="valora"
														id="valora"
														className="form-control"
														//onChange={(e) => {}}
													/>

													<label htmlFor="valora" className="form-label">
														Valor Pagado Por Anticipo
													</label>
													<ErrorMessage
														name="valora"
														component="div"
														className="error"
													/>
												</div>
											</div>
											{/* -------------------------- */}
											<div className="col-md-3  ">
												<div className="form-floating mb-2  ">
													<Field
														style={dateStyles}
														type="date"
														name="fechapa"
														id="fechapa"
														className="form-control"
														placeholder="Fecha "
														onChange={(e) => {
															formik.setFieldValue("fechapa", e.target.value);
														}}
													/>
													<label htmlFor="fechapa" className="form-label">
														Fecha De Pago Anticipo
													</label>
													<ErrorMessage
														name="fechapa"
														component="div"
														className="error"
													/>
												</div>
											</div>
											<div className="col-md-3  ">
												<div className="form-floating mb-2  ">
													<Field
														style={dateStyles}
														type="date"
														name="fechaad"
														id="fechaad"
														className="form-control"
														placeholder="Fecha "
														onChange={(e) => {
															formik.setFieldValue("fechaad", e.target.value);
														}}
													/>
													<label htmlFor="fechaad" className="form-label">
														Fecha Adición O Prórroga{" "}
													</label>
													<ErrorMessage
														name="fechaad"
														component="div"
														className="error"
													/>
												</div>
											</div>
											<div className="col-md-3  ">
												<div className="form-floating mb-3  ">
													<Field
														style={inputStyles}
														type="number"
														name="plazoad"
														id="plazoad"
														className="form-control"
														//onChange={(e) => {}}
													/>

													<label htmlFor="plazoad" className="form-label">
														Plazo Adición O Prórroga
													</label>
													<ErrorMessage
														name="valora"
														component="div"
														className="error"
													/>
												</div>
											</div>
											<div className="col-md-3  ">
												<div className="form-floating mb-3  ">
													<Field
														style={inputStyles}
														type="number"
														name="valorad"
														id="valorad"
														className="form-control"
														//onChange={(e) => {}}
													/>

													<label htmlFor="valorad" className="form-label">
														Valor Adición O Prórroga
													</label>
													<ErrorMessage
														name="valorad"
														component="div"
														className="error"
													/>
												</div>
											</div>
											{/* ---------------------------*/}
											<div className="col-md-3  ">
												<div className="form-floating mb-2  ">
													<Field
														style={dateStyles}
														type="date"
														name="fechatc"
														id="fechatc"
														className="form-control"
														placeholder="Fecha "
														onChange={(e) => {
															formik.setFieldValue("fechatc", e.target.value);
														}}
													/>
													<label htmlFor="fechatc" className="form-label">
														Fecha De Terminación Contrato
													</label>
													<ErrorMessage
														name="fechatc"
														component="div"
														className="error"
													/>
												</div>
											</div>
											<div className="col-md-3  ">
												<div className="form-floating mb-2  ">
													<Field
														style={dateStyles}
														type="date"
														name="fechaal"
														id="fechaal"
														className="form-control"
														placeholder="Fecha "
														onChange={(e) => {
															formik.setFieldValue("fechaal", e.target.value);
														}}
													/>
													<label htmlFor="fechaal" className="form-label">
														Fecha De Acta De Liquidación
													</label>
													<ErrorMessage
														name="fechaal"
														component="div"
														className="error"
													/>
												</div>
											</div>
											{/* --------------------------- */}
										</div>
										<div className="modal-footer">
											<button
												type="button"
												className="btn btn-danger"
												data-bs-dismiss="modal"
												ref={closecont}
											>
												Cancelar
											</button>
											<button type="submit" className="btn btn-primary">
												Guardar
											</button>
										</div>
									</Form>
								)}
							</Formik>
							{/* -------------- */}
						</div>
					</div>
				</div>
			</div>
			{/* ------------------------------- */}

			{/* modal terceros */}
			<div
				className="modal fade"
				id="modalTercero"
				tabindex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="exampleModalLabel">
								Terceros
							</h1>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								ref={closeModal}
							></button>
						</div>
						<div className="modal-body">
							<RegTerceros ban={1} closeModal={closeModal} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
