import React, { useState, useEffect, useContext, useRef } from "react";
import { AuthContext } from "../../context/LogContext";
import axios from "axios";
import { route_confiPresupuesto_base } from "../../../api-routes/RoutesLogin";
import Select from "react-select";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Swal from "sweetalert2";
import { BsFiletypeTxt } from "react-icons/bs";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { route_contabconf_base } from "../../../api-routes/RoutesLogin";
import * as Yup from "yup";

export default function PlanPxPlano() {
	const { getToken, customStyles, inputStyles, establishment } =
		useContext(AuthContext);
	const [estructura, setEstructura] = useState([]);
	const [file, setFile] = useState(null);
	const [banCarga, setBanCarga] = useState(0);
	const [maxNivel, setMaxNivel] = useState(0);
	const [maxCodigo, setMaxCodigo] = useState(0);
	const [pucSugerido, setPucSugerido] = useState([]);
	const [nivelesAcum, setNivelesAcum] = useState([]);
	const [LineaErrores, setLineaErrores] = useState("");
	const [nomCab, setNomCab] = useState("");
	const [yearCab, setYearCab] = useState(null);
	const [years, setYears] = useState([]);
	const [cabeceraPuc, setCabeceraPuc] = useState(0);
	



	const inputRef = useRef(null);

	const handleFileChange = (e) => {
		setFile(e.target.files[0]);
	};

	const handleFileRead = () => {
		setLineaErrores("");
		const fileReader = new FileReader();
		fileReader.onload = (event) => {
			const data = event.target.result;
			const workbook = XLSX.read(data, { type: "binary" });
			const firstSheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[firstSheetName];
			const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
			if (inputRef.current) {
				inputRef.current.value = "";
			}
			var jsonDataClean = cleanJsonData(jsonData);
			var response = validarXlsx(jsonDataClean);
		};

		fileReader.readAsBinaryString(file);
	};
	function customSort(a, b) {
		// Ordenar por 'codigo'
		if (a.codigo < b.codigo) return -1;
		if (a.codigo > b.codigo) return 1;

		// Si 'codigo' es igual, ordenar por 'tipo'
		if (a.tipo < b.tipo) return -1;
		if (a.tipo > b.tipo) return 1;

		// Si 'codigo' y 'tipo' son iguales, ordenar por 'naturaleza'
		if (a.naturaleza < b.naturaleza) return -1;
		if (a.naturaleza > b.naturaleza) return 1;

		return 0;
	}
	const getPucSugerido = async () => {
		setBanCarga(1)
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_contabconf_base}/getpuc/${establishment.id}`,
			config
		);
		//let newPuc = reemplazarMadreConCodigo(result.data.pucDTOList);
		let order = result.data.pucDTOList.sort(customSort);
		setPucSugerido(order);
		setBanCarga(0)

		/* obtenemos el codigo mas largo para validar las estructuras usadas */
		const diccionario = {};
		let maxCodigo = 0;
		order.forEach((obj) => {
			diccionario[obj.id] = obj.codigo;
			if (obj.codigo.length > maxCodigo) {
				maxCodigo = obj.codigo.length;
			}
		});
		setMaxCodigo(maxCodigo);
		/* ----------obtenemos cabecera si existen datos ---------------------*/
		var cab = result.data.cabecera;
		setCabeceraPuc(cab);
		console.log(cab.origen)
	};

	const handleImport = () => {
		//setBanCarga(1);
		if (file) {
			handleFileRead();
		} else {
			Swal.fire({
				icon: "warning",
				title: "Diligencie los campos requeridos",
				showConfirmButton: false,
				timer: 1500,
			});
			setBanCarga(0);
		}
	};

	function agregarCampoMadre(arr) {
		// Crear un nuevo array para el resultado
		let result = [];

		// Agregar el encabezado con los nuevos campos 'madre' y 'nivel'
		result.push([...arr[0], "madre", "nivel"]);

		// Recorrer el array original, comenzando desde el segundo elemento
		for (let i = 1; i < arr.length; i++) {
			let current = arr[i];
			let madre = null;
			let nivel = null;
			const currentCaracteres = current[0].length;
			const nivelEncontrado = nivelesAcum.find(
				(n) => n.caracteres >= currentCaracteres
			);
			if (nivelEncontrado) {
				nivel = nivelEncontrado.nivel;
				current.push(nivel);
				//result.push([...current, nivel]);
			}

			// Buscar el padre más cercano
			for (let j = i - 1; j >= 0; j--) {
				if (current[0].startsWith(arr[j][0]) && current[5] === arr[j][5] + 1) {
					madre = arr[j][0];
					break;
				}
			}

			// Añadir el nuevo elemento al resultado con 'madre' y 'nivel'
			result.push([...current, madre]);
		}

		return result;
	}

	const validarXlsx = (jsonData) => {
		var errores = "";
		//valida que todos los arrays tengan 7 campos
		let lineasIncompletas = jsonData
			.filter((subArray) => subArray.length !== 5)
			.map((subArray) => subArray[0]);
		errores =
			errores +
			arrayErrores(lineasIncompletas, " Lineas con campos faltantes ");
		//agregamos campo madre y nivel de cada cta
		let jsonMadreNivel = agregarCampoMadre(jsonData);
		//validamos campos sin madre
		let camposSinMadre = jsonMadreNivel
			.filter(
				(row, index) => index !== 0 && row[0].length > 1 && row[6] === null
			)
			.map((row) => row[0]);
		errores =
			errores + arrayErrores(camposSinMadre, " Códigos sin cuenta madre ");

		//valida que todos los campos esten con nivel asignado
		let camposSinEstructura = jsonMadreNivel
			.filter((row, index) => row[5] === null)
			.map((row) => row[0]);
		errores =
			errores +
			arrayErrores(camposSinEstructura, " Códigos sin estructura correcta ");
		//valida que la categoria sea valida con campos de 1 a 19
		let camposCategoriaInvalida = jsonMadreNivel
			.filter((row, index) => index !== 0 && (row[4] < 0 || row[4] > 19))
			.map((row) => row[0]);
		errores =
			errores +
			arrayErrores(camposCategoriaInvalida, " Códigos con categoria invalida ");

		setLineaErrores(errores);

		if (nomCab !== "" && yearCab != null) {
			if (errores === "") {
				var newPucList = [];
				jsonData.forEach((linea, indice) => {
					console.log(linea);
					console.log(indice);
					if (indice > 0) {
						let newObject = {
							id: null,
							codigo: linea[0],
							nombre: linea[1],
							naturaleza: linea[2],
							tipo: linea[3],
							activa: true,
							categoria: linea[4] != 0 ? linea[4] : null,
						};
						newPucList.push(newObject);
					}
				});
				let cabeceraPuc = {};
				let cabecera = {
					nombre: nomCab,
					year: yearCab,
					activa: true,
					establishment: establishment.id,
					user:localStorage.getItem("idUser"),
				};
				cabeceraPuc.cabecera = cabecera;
				cabeceraPuc.pucList = newPucList;
				handleGuardarPuc(cabeceraPuc)
			}
		} else {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Configure el año y nombre del PUC",
				showConfirmButton: true,
			}).then(() => {});
		}
		return false;
	};
	function cleanJsonData(jsonData) {
		// Mapea sobre cada elemento del arreglo y limpia los valores
		const cleanedData = jsonData.map((item) => {
			return item.map((value) => {
				// Convierte el valor a string y elimina los espacios al inicio y final
				return String(value).trim();
			});
		});

		return cleanedData;
	}
	function arrayErrores(array, title) {
		if (array.length > 0) {
			const formattedArray = array.map((item) => `*${item}`).join(" ");
			return `${title}: ${formattedArray}`;
		} else {
			return "";
		}
	}
	const handleDownload = () => {
		// Seleccionar las claves que deseas mostrar
		const selectedKeys = [
			"codigo",
			"nombre",
			"naturaleza",
			"tipo",
			"categoria",
		];

		// Crear encabezados a partir de las claves seleccionadas
		const headers = selectedKeys;

		// Crear un array de arrays con encabezados y datos
		const data = [
			headers,
			...pucSugerido.map((obj) =>
				selectedKeys.map((key) => (key === "categoria" ? 0 : obj[key]))
			),
		];

		// Crear una nueva hoja de cálculo
		const ws = XLSX.utils.aoa_to_sheet(data);

		// Crear un libro de trabajo
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Hoja1");

		// Convertir el libro de trabajo a un archivo binario
		const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

		// Guardar el archivo
		saveAs(
			new Blob([wbout], { type: "application/octet-stream" }),
			"Plan Unico de cuentas.xlsx"
		);
	};


	const handleGuardarPuc = async (pucFinal) => {
		setBanCarga(1)
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};
			

			const jsonString = JSON.stringify(pucFinal);
			console.log(jsonString)
			try {
				await axios.post(
					`${route_contabconf_base}/savePuc`,
					jsonString,
					config
				);
				Swal.fire({
					icon: "success",
					title: "PUC cargado correctamente",
					showConfirmButton: true,
				}).then(() => {
					getPucSugerido();
					setBanCarga(0)
				});
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error de red, contactese con su proveedor",
					showConfirmButton: true,
				}).then(() => {setBanCarga(0)});
			}
		

		/* --------------------------- */
	};

	const downloadTxtFile = () => {
		const element = document.createElement("a");
		const file = new Blob([LineaErrores], { type: "text/plain" });
		element.href = URL.createObjectURL(file);
		element.download = "errores.txt";
		document.body.appendChild(element);
		element.click();
	};
	const initialValues = {
		id: "",
		nivel: "",
		nombre: "",
		caracteres: "",
		establishment: establishment.id,
	};
	const validationSchema = Yup.object().shape({
		nombre: Yup.string().required("Requerido"),
		caracteres: Yup.number()
			.required("Requerido")
			.positive("Debe ser positivo")
			.integer("Debe ser  entero")
			.moreThan(0, "Debe ser mayor que cero"),
	});

	const handleSubmit = async (values) => {
		values.nivel = maxNivel + 1;
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			console.log(values);
			const response = await axios.post(
				`${route_contabconf_base}/saveEstructura`,
				values,
				config
			);
			Swal.fire({
				icon: "success",
				title: "Nivel Configurado correctamente",
				timer: 1500,
			}).then(() => {
				//btnCancel.current.click();
				getEstructura();
			});
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Error de red, contactese con su proveedor",
				showConfirmButton: true,
			}).then(() => {});
		}
	};
	function calcularAcumulacionCaracteres(data) {
		// Crear un array para almacenar la acumulación de caracteres
		const acumulaciones = [];

		// Recorrer cada elemento del array
		data.forEach((element, index) => {
			// Inicializar la suma de caracteres con el valor del nivel actual
			let sumaCaracteres = 0;
			// Sumar los caracteres de los niveles inferiores e igual al nivel actual
			for (let i = 0; i <= index; i++) {
				sumaCaracteres += data[i].caracteres;
			}
			// Crear un nuevo objeto solo con los campos nivel y caracteres
			const nuevoElemento = {
				nivel: element.nivel,
				caracteres: sumaCaracteres,
			};
			// Añadir el nuevo elemento al array de acumulaciones
			acumulaciones.push(nuevoElemento);
		});

		return acumulaciones;
	}

	const getEstructura = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_contabconf_base}/getestructura/${establishment.id}`,
			config
		);
		if (result.data.length === 0) {
			estructuraBasica();
		} else {
			let order = result.data.sort((a, b) => a.nivel - b.nivel);
			setEstructura(order);
			setNivelesAcum(calcularAcumulacionCaracteres(order));
			var max = result.data.reduce((max, estructura) => {
				return estructura.nivel > max ? estructura.nivel : max;
			}, result.data[0].nivel);
			setMaxNivel(max);
		}
	};
	const estructuraBasica = async () => {
		let estructura = JSON.stringify([
			{
				nivel: 1,
				nombre: "Clase",
				caracteres: 1,
				establishment: establishment.id,
			},
			{
				nivel: 2,
				nombre: "Grupo",
				caracteres: 1,
				establishment: establishment.id,
			},
			{
				nivel: 3,
				nombre: "Cuenta",
				caracteres: 2,
				establishment: establishment.id,
			},
			{
				nivel: 4,
				nombre: "Subcuenta",
				caracteres: 2,
				establishment: establishment.id,
			},
		]);
		/* almacenar estructura básica */
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(
				`${route_contabconf_base}/save`,
				estructura,
				config
			);
			getEstructura();
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Error de red, contactese con su proveedor",
				showConfirmButton: true,
			}).then(() => {});
		}
		/* --------------------------- */
	};
	const deleteE = async (id) => {
		Swal.fire({
			title: "Eliminar estructura?",
			text: "El nivel no debe tener configuración activa!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				if (validarE(id)) {
					deleteServicio(id);
				} else {
					Swal.fire({
						icon: "error",
						title:
							"Verifique que el nivel no este en uso y no existan niveles superiores",
						showConfirmButton: true,
					});
				}
			}
		});
	};
	function validarE(id) {
		var estructuraS = estructura.find((item) => item.id == id);
		let numero = estructura.reduce((sum, estructura) => {
			if (estructura.nivel <= estructuraS.nivel) {
				return sum + estructura.caracteres;
			}
			return sum;
		}, 0);
		console.log(numero);
		console.log(maxCodigo);
		var estructuraS = estructura.find((item) => item.id == id);
		if (estructuraS.nivel === maxNivel && numero > maxCodigo) {
			return true;
		} else {
			return false;
		}
	}
	const deleteServicio = async (servicioId) => {
		try {
			const response = await axios.delete(
				route_contabconf_base + "/deleteEstructura/" + servicioId,

				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			Swal.fire({
				icon: "success",
				title: "Estructura Eliminada",
				showConfirmButton: true,
			}).then(() => {
				getEstructura();
			});
		} catch (error) {
			throw error;
		}
	};
	const getYears = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_contabconf_base}/getyears/${establishment.id}`,
			config
		);
		const formattedOptions = result.data.map((item) => ({
			value: item.id,
			label: item.year,
		}));
		setYears(formattedOptions);
	};

	useEffect(() => {
		getEstructura();
		getPucSugerido();
		getYears();
	}, []);

	return (
		<div className="containerFormUser">
			<h1>Configuracion PUC</h1>
			<div className="row">
				{/* BLOQUE1 */}
				<div className="col-md-6  ">
					<div className="form-floating mb-2  ">
						<input
							style={{ ...inputStyles }}
							//innerRef={inputServicio}
							type="text"
							name="nombre"
							id="nombre"
							className="form-control"
							placeholder="Nombre"
							onChange={(e) => {
								setNomCab(e ? e.target.value : "");
							}}
						/>
						<label htmlFor="forma" className="form-label">
							Nombre
						</label>
					</div>
				</div>
				<div className="col-md-6  ">
					<div className="form-floating mb-2  ">
						<Select
							isClearable={true}
							styles={customStyles}
							onChange={(e) => {
								setYearCab(e ? e.value : null);
							}}
							//value={tipoS}
							placeholder="Año"
							options={years}
						/>
					</div>
				</div>
			</div>
			{/* BLOQUE ESTRUCTURA */}

			<div
				className="accordion accordion-flush mb-3"
				id="accordionFlushExample"
			>
				<div class="accordion-item">
					<h2 class="accordion-header">
						<button
							class="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#flush-collapseOne"
							aria-expanded="false"
							aria-controls="flush-collapseOne"
						>
							Estructura
						</button>
					</h2>
					<div
						id="flush-collapseOne"
						class="accordion-collapse collapse"
						data-bs-parent="#accordionFlushExample"
					>
						<div class="accordion-body mt-1 " style={{ paddingTop: "5px" }}>
							<button
								className="btn btn-primary"
								data-bs-toggle="modal"
								data-bs-target="#crearEstructura"
							>
								<i className="fas fa-plus"></i>
							</button>
							<table className="reactTables table table-striped">
								<thead>
									<tr>
										<th>Nivel</th>
										<th>Nombre</th>
										<th>Caracteres</th>
										<th>Opciones</th>
									</tr>
								</thead>
								<tbody>
									{estructura.map((e, index) => (
										<tr key={index}>
											<td>{e.nivel}</td>
											<td>{e.nombre}</td>
											<td>{e.caracteres}</td>
											<td>
												<button
													className="btnDelete"
													type="button"
													onClick={() => deleteE(e.id)}
												>
													<i className="fas fa-trash-alt"></i>
												</button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

			{/* ----------------- */}
			<h1>PUC Archivo Plano </h1>
			<div className="row">
				<div className="col-md-12">
					<input
						ref={inputRef}
						onChange={handleFileChange}
						type="file"
						className="form-control"
						id="inputGroupFile04"
						aria-describedby="inputGroupFileAddon04"
						aria-label="Upload"
						accept=".xlsx,.xls"
					></input>
				</div>
				<div className="col-md-12 mt-3">
					<button
						type="button"
						class="btn btn-info"
						data-bs-toggle="modal"
						data-bs-target="#exampleModal"
					>
						Información
					</button>
					&nbsp;
					<button
						type="button"
						className="btn btn-success"
						onClick={handleDownload}
					>
						Plantilla
					</button>
					&nbsp;
					{banCarga == 0 ? (
						cabeceraPuc.origen==0?
							<button
							type="button"
							className="btn btn-primary"
							onClick={handleImport}
						>
							Subir
						</button>:<label className="text text-danger" >Puc previamente Cargado</label>
						
						
					) : (
						<div className="spinner-overlay">
							<button className="btn btn-primary" type="button" disabled>
								<span
									className="spinner-border spinner-border-sm"
									aria-hidden="true"
								></span>
								<span role="status"> Cargando...</span>
							</button>
						</div>
						/*--------------- */
					)}
				</div>
				<br></br>
				<div style={{ textAlign: "left" }} className="text text-danger">
					{LineaErrores && LineaErrores.length > 0 && (
						<div>
							<label style={{ display: "flex", alignItems: "center" }}>
								<h3 className="text text-danger">Errores </h3> &nbsp;
								<button className="btnInfo" onClick={downloadTxtFile}>
									<BsFiletypeTxt />
								</button>
							</label>

							{LineaErrores}
						</div>
					)}
				</div>
			</div>
			{/* modañ de informacion */}
			<div
				class="modal fade"
				id="exampleModal"
				tabindex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-md">
					<div class="modal-content">
						<div class="modal-header">
							<h1 class="modal-title fs-5" id="exampleModalLabel">
								Información
							</h1>
							<button
								type="button"
								class="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div class="modal-body">
							<p
								style={{
									textAlign: "left",
									textAlignLast: "",
									whiteSpace: "preWrap",
								}}
							>
								<b>Codigo:</b> Este Campo almacena el código de la cuenta
								contable.<br></br>
								<b>Nombre:</b> Detalla el nómbre de la cuenta.<br></br>
								<b>Tipo:</b> Especifica si es una cuenta titulo o detalle asi:
								1=Titulo, 2=Detalle. <br></br>
								<b>Naturaleza:</b> Especifica la naturaleza de la cuenta asi:
								1=Dèbito , 2=Crédito.<br></br>
								<b>Categoria:</b> Selecciona el numero de la categoria de la
								cuenta detalle de la siguiente lista, o "0" si no se desea
								asignar categoría: <br></br>1 "Caja – Bancos"
								<br></br>2 "Cuentas por cobrar"<br></br>3 "Otros activos
								corrientes"<br></br>4 "Inventarios"<br></br>5 "Activos fijos"
								<br></br>6 "Otros activos"<br></br>7 "Cuentas por pagar"
								<br></br>8 "Otros pasivos corrientes"<br></br>9 "Pasivos corto
								plazo"<br></br>
								10 "Pasivos largos plazos"<br></br>
								11 "Otros pasivos"<br></br>
								12 "Patrimonio"<br></br>
								13 "Ingresos"<br></br>
								14 "Otros ingresos"<br></br>
								15 "Costo de ventas"<br></br>
								16 "Gastos"<br></br>
								17 "Otros gastos"<br></br>
								18 "Orden"<br></br>
							</p>
						</div>
						<div class="modal-footer">
							<button
								type="button"
								class="btn btn-secondary"
								data-bs-dismiss="modal"
							>
								Cerrar
							</button>
						</div>
					</div>
				</div>
			</div>
			{/*---------------------  */}

			{/* modal creacion de cuentas */}
			<div
				class="modal fade"
				id="crearEstructura"
				tabindex="-1"
				aria-labelledby="crearEstructuraLabel"
				aria-hidden="true"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<h1 class="modal-title fs-5" id="crearEstructuraLabel">
								Crear Cuenta
							</h1>
							<button
								type="button"
								class="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div class="modal-body">
							{/* ----------------- */}
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={handleSubmit}
								//innerRef={formikRef}
							>
								{(formik, props) => (
									<Form>
										<div className="row">
											{/* BLOQUE1 */}

											<div className="col-md-3  ">
												<div className="form-floating mb-2  ">
													<Field
														style={{ ...inputStyles }}
														value={maxNivel + 1}
														type="text"
														name="nivel"
														id="nivel"
														disabled
														className="form-control"
														placeholder="Nivel"
													/>
													<label htmlFor="forma" className="form-label">
														Nivel
													</label>
													<ErrorMessage
														name="nivel"
														component="div"
														className="error"
													/>
												</div>
											</div>
											<div className="col-md-6  ">
												<div className="form-floating mb-2  ">
													<Field
														style={{ ...inputStyles }}
														//innerRef={inputServicio}
														type="text"
														name="nombre"
														id="nombre"
														className="form-control"
														placeholder="Nombre"
													/>
													<label htmlFor="forma" className="form-label">
														Nombre
													</label>
													<ErrorMessage
														name="nombre"
														component="div"
														className="error"
													/>
												</div>
											</div>
											<div className="col-md-3  ">
												<div className="form-floating mb-2  ">
													<Field
														style={{
															...inputStyles,
															textAlign: "right",
														}}
														type="text"
														name="caracteres"
														id="caracteres"
														//value={madreS.codigo}
														className="form-control"
														placeholder="Caracteres"
													/>
													<label
														style={{ zIndex: "0" }}
														htmlFor="forma"
														className="form-label"
													>
														Caracteres
													</label>
													<ErrorMessage
														name="caracteres"
														component="div"
														className="error"
													/>
												</div>
											</div>
										</div>
										<div class="modal-footer">
											<button type="submit" className="btn btn-primary">
												Guardar
											</button>
											&nbsp;
											<button
												type="reset"
												//ref={btnCancel}
												className="btn btn-danger"
												data-bs-dismiss="modal"
												aria-label="Close"
											>
												Cancelar
											</button>
										</div>
									</Form>
								)}
							</Formik>
							{/* ----------------- */}
						</div>
					</div>
				</div>
			</div>
			{/* ---------------------------------- */}
		</div>
	);
}
