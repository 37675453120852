import React, { useState } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios";
import {
	route_users_getByEmail,
	route_login_existUserxEstab,
} from "../../api-routes/RoutesLogin";

// Crear contexto para el estado de la aplicación
export const AuthContext = React.createContext();
// Función para obtener el token de autenticación
export const getToken = () => {
	return localStorage.getItem("token");
};
// Función para almacenar el token de autenticación
const setToken = (token) => {
	localStorage.setItem("token", token);
};
// Función para eliminar el token de autenticación
const removeToken = () => {
	localStorage.removeItem("token");
};
const removeEstablishment = () => {
	localStorage.removeItem("establishment");
};
// Función para decodificar el token de autenticación
const decodeToken = (token) => {
	try {
		return jwtDecode(token);
	} catch (err) {
		return null;
	}
};
// Componente para manejar la autenticación
export const AuthProvider = ({ children }) => {
	const [banVisualizar, setBanVisualizar] = useState(1);
	const [userAct, setUserAct] = useState(null);
	const [user, setUser] = useState(decodeToken(getToken()));
	const existUserxEstab = async (ids) => {
		const token = "Bearer " + localStorage.getItem("token");
		var resp = null;
		try {
			const respuesta = await axios.get(route_login_existUserxEstab + ids, {
				headers: {
					Authorization: token,
				},
			});
			resp = respuesta.data;
			return resp;
		} catch (error) {
			resp = null;
			return resp;
		}
	};
	const getUserByMail = async (email) => {
		const token = "Bearer " + getToken();
		var newresp = null;
		try {
			const respuesta = await axios.get(route_users_getByEmail + email, {
				headers: {
					Authorization: token,
				},
			});
			var resp = respuesta.data;
			setUserAct(resp);
			//await getPermissions(resp);
			//var idE = getEstablishment();
			newresp = null;
			//const existeUsuario = await existUserxEstab(resp.id + "," + idE.id);
			/* if (existeUsuario === true) {
				newresp = { ...resp, status: true };
			} else {
				newresp = { ...resp, status: false };
			} */
			return resp;
		} catch (error) {
			newresp = null;
			return newresp;
		}
	};
	// Función para realizar el inicio de sesión
	const Functionlogin = (token) => {
		setToken(token);
		setUser(decodeToken(token));
		return decodeToken(token);
	};
	// funcin para obtener los permisos de user
	const getPermissions = async (user) => {
		const result = {
			modules: [],
			options: [],
			admin: false,
		};
		if (user.admin == true) {
			result.admin = true;
		}
		user.roles.forEach((role) => {
			console.log('user roles '+ role.id)
			var establishmentAct = getEstablishment();
			const parsedObject = JSON.parse(establishmentAct);
			if (parsedObject.id == role.rolesxrol.idEstablishment) {
				role.rolesxrol.permissions.forEach((permission) => {
					if (permission.modules && permission.modules.length > 0) {
						result.modules = [...result.modules, ...permission.modules];
					}
					if (permission.options && permission.options.length > 0) {
						result.options = [...result.options, ...permission.options];
					}
				});
			}
		});
		const jsonResult = JSON.stringify(result);
		localStorage.setItem("permissions", jsonResult);
		return jsonResult;
	};
	// Función para realizar el cierre de sesión
	const logout = () => {
		removeToken();
		setUser(null);
		removeEstablishment();
		localStorage.removeItem("permissions");
		localStorage.removeItem("modules");
		setUserAct(null);
	};
	const getEstablishment = () => {
		var estab = localStorage.getItem("establishment");

		/* var estab = localStorage.getItem("establishment");
		var estabJson=""
		if (typeof miVariable === "string") {
			estabJson = JSON.parse(estab);
			setEstablishment(estabJson)
			console.log(estabJson);
		} */

		return estab;
		//return true;
	};
	const setEstablishmentLS = (estab) => {
		localStorage.setItem("establishment", estab);
	};
	const [establishment, setEstablishment] = useState(getEstablishment());
	// constante meses
	const meses = [
		{ value: 1, label: "Enero" },
		{ value: 2, label: "Febrero" },
		{ value: 3, label: "Marzo" },
		{ value: 4, label: "Abril" },
		{ value: 5, label: "Mayo" },
		{ value: 6, label: "Junio" },
		{ value: 7, label: "Julio" },
		{ value: 8, label: "Agosto" },
		{ value: 9, label: "Septiembre" },
		{ value: 10, label: "Octubre" },
		{ value: 11, label: "Noviembre" },
		{ value: 12, label: "Diciembre" },
	];
	// constante anio
	const years = [
		{ value: 2015, label: "2015" },
		{ value: 2016, label: "2016" },
		{ value: 2017, label: "2017" },
		{ value: 2018, label: "2018" },
		{ value: 2019, label: "2019" },
		{ value: 2020, label: "2020" },
		{ value: 2021, label: "2021" },
		{ value: 2022, label: "2022" },
		{ value: 2023, label: "2023" },
		{ value: 2024, label: "2024" },
		{ value: 2025, label: "2025" },
		{ value: 2026, label: "2026" },
		{ value: 2027, label: "2027" },
		{ value: 2028, label: "2028" },
		{ value: 2029, label: "2029" },
		{ value: 2030, label: "2030" },
	];
	function obtenerFechaActualEnFormatoYMD() {
		const fechaActual = new Date();
		const year = fechaActual.getFullYear();
		const month = String(fechaActual.getMonth() + 1).padStart(2, "0"); // El mes se incrementa en 1, y luego se rellena con ceros a la izquierda si es necesario.
		const day = String(fechaActual.getDate()).padStart(2, "0"); // El día se rellena con ceros a la izquierda si es necesario.

		return `${year}-${month}-${day}`;
	}

	function obtenerFechaActualEnFormatoYMDHMS() {
		const fechaActual = new Date();
		const year = fechaActual.getFullYear();
		const month = String(fechaActual.getMonth() + 1).padStart(2, "0");
		const day = String(fechaActual.getDate()).padStart(2, "0");
		const hour = String(fechaActual.getHours()).padStart(2, "0");
		const minute = String(fechaActual.getMinutes()).padStart(2, "0");
		const second = String(fechaActual.getSeconds()).padStart(2, "0");

		return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
	}
	//fechas formateadas
	const FechaYMD = obtenerFechaActualEnFormatoYMD();
	const FechaYMDHMS = obtenerFechaActualEnFormatoYMDHMS();
	//estilos para select y campos date
	const customStyles = {
		control: (base) => ({
			...base,
			minHeight: 35,
			fontSize: "14px",
			height: "50px",
		}),
		option: (base) => ({
			...base,
			//textAlign: `left`,
			border: `1px dotted blue`,
			fontSize: "14px",
			//zIndex: "999",
			textAlign: "center",
			//position: "fixed",
     		 zIndex: 1000
		}),
	};
	const dateStyles = {
		fontSize: "12px",
		height: "48px",
	};
	const inputStyles = {
		fontSize: "12px",
		height: "48px",
		zIndex:0
	};
	const key = "ZXN0b3RhbWJpZW5wYXNhcmE=";

	// Contexto de autenticación
	const authContext = {
		user,
		establishment,
		getEstablishment,
		setEstablishmentLS,
		setEstablishment,
		getUserByMail,
		Functionlogin,
		logout,
		getToken,
		meses,
		years,
		isAuthenticated: !!user,
		FechaYMD,
		FechaYMDHMS,
		customStyles,
		dateStyles,
		inputStyles,
		userAct,
		setUserAct,
		getPermissions,
		banVisualizar,
		setBanVisualizar,
		key,
	};

	return (
		<AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
	);
};
