import React, { useState, useEffect, useContext, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import { AuthContext } from "../../context/LogContext";
import {
	route_confiPresupuesto_base,
	route_procesosPresupuesto,
} from "../../../api-routes/RoutesLogin";
import Swal from "sweetalert2";
import Select from "react-select";
import * as Yup from "yup";
import { NumericFormat } from "react-number-format";
import { FaCheckDouble } from "react-icons/fa";
import ProyeccionPDF from "../pdfs/ProyeccionPDF";
import { MdCurrencyExchange } from "react-icons/md";
import TableIngresos from "../../componentsBase/tables/TableProyeccion";

const Proyeccion = () => {
	const { getToken, customStyles, inputStyles } = useContext(AuthContext);
	const { establishment, user } = useContext(AuthContext);
	const [proyecciones, setProyecciones] = useState([]);
	const [inputValues, setInputValues] = useState({});
	const [estructura, setEstructura] = useState([]);
	const [cabeceras, setCabeceras] = useState([]);
	const [cabeceraSelected, setCabeceraSelected] = useState(null);
	const [selectedOption, setSelectedOption] = useState(null);
	const [selectedOptionN, setSelectedOptionN] = useState(null);
	const [selectedOptionT, setSelectedOptionT] = useState(null);
	const [inputValor, setInputValor] = useState(null);
	const [readO, setReadO] = useState(false);
	const [vrCreditos, setVrCreditos] = useState(0);
	const [vrDebitos, setVrDebitos] = useState(0);
	const [btnSolicitud, setBtnSolicitud] = useState(true);
	const [cabeceraObject, setCabeceraObject] = useState({});
	const [disabled, setDisabled] = useState(false);
	const [listSia, setListSia] = useState([]);
	const [listSifse, setListSifse] = useState([]);
	const [listSifseDet, setListSifseDet] = useState([]);
	const [siaS, setSiaS] = useState({ label: "SIA", value: null });
	const [sifseS, setSifseS] = useState({ label: "SIFSE", value: null });
	const [sifseDetS, setSifseDetS] = useState({ label: "SIFSE", value: null });
	const [siaUp, setSiaUp] = useState(null);
	const [sifseUp, setSifseUp] = useState(null);
	const [sifseDetup, setSifseDetUp] = useState(null);
	const [ctaUpdate, setCtaUpdate] = useState(null);
	const btnCancel = useRef(null);
	const formikRef = useRef(null);
	const btnClose = useRef(null);
	const closemod = useRef(null)
	const [label, setLabel] = useState("");
	const [edicion, setEdicion] = useState(0);
	const [vrOriginal, setVrOriginal] = useState(0);
	const [btnCarga, setBtnCarga] = useState(0);
	const [banCarga, setBanCarga] = useState(0);
	const [banSol, setBanSol] = useState(0);
	const [columnas, setColumnas] = useState([]);
	const [numPages, setNumpages] = useState(300);
	const [numPagesO, setNumpagesO] = useState({ value: 300, label: "300" });
	const [nomEdit, setNomEdit] = useState("");


	function customSort(a, b) {
		// Ordenar por 'codigo'
		if (a.codigo < b.codigo) return -1;
		if (a.codigo > b.codigo) return 1;

		// Si 'codigo' es igual, ordenar por 'tipo'
		if (a.tipo < b.tipo) return -1;
		if (a.tipo > b.tipo) return 1;

		// Si 'codigo' y 'tipo' son iguales, ordenar por 'naturaleza'
		if (a.naturaleza < b.naturaleza) return -1;
		if (a.naturaleza > b.naturaleza) return 1;

		return 0;
	}

	const handleSelectChange = (selected) => {
		setSelectedOption(selected);
		setBanCarga(1);
	};

	const handleSelectChangeT = (selected) => {
		setSelectedOptionT(selected);
	};
	const handleClear = () => {
		setSelectedOption(null);
		setSelectedOptionN(null);
		setSelectedOptionT(null);
		setInputValor("");
		setInputValues([]);
	};
	const handleValorChange = (valor) => {
		setInputValor(valor);
	};
	const validaApropiacion = (tipo, formik) => {
		if (tipo == 1) {
			setSiaS({ label: "SIA", value: null });
			setSifseS({ label: "Sifse Ingreso", value: null });
			setSifseDetS({ label: "Sifse detalle", value: null });
			setReadO(true);
			setInputValor(0);
			formik.setFieldValue("apropiacion", 0);
		} else {
			setReadO(false);
			formik.setFieldValue("apropiacion", "");
			setInputValor("");
		}
	};
	const handleSubmit = (values, { setValues }) => {
		setBtnCarga(1);
		let concatenatedValues = "";
		let lastKey = 0;
		let error = "";
		let madre = null;
		let naturaleza = null;
		for (const key in inputValues) {
			if (key == parseInt(lastKey) + 1 && inputValues[key] != "") {
				let codMadre = concatenatedValues.slice(0, -1);
				concatenatedValues += inputValues[key] + ".";
				lastKey = key;
				// verificamos que la cuenta madre este registrada
				const ctaMadre = proyecciones.find((obj) => obj.codigo == codMadre);

				if (!ctaMadre && key > 1) {
					error =
						"la cuenta " + concatenatedValues + " no se encuentra registrada ";
				} else if (key > 1) {
					//  valida si la cuenta madre es de tipo titulo
					naturaleza = ctaMadre.naturaleza;
					if (ctaMadre.tipo == 1) {
						madre = ctaMadre.id;
					} else {
						error = "la cuenta " + ctaMadre.codigo + " es de detalle ";
					}
					// valida si la nueva cuenta tiene la misma naturaleza de la madre
					if (naturaleza != values.naturaleza) {
						error =
							"La cuenta debe ser de naturaleza " +
							(naturaleza == 1 ? "Débito" : "Crédito");
					}
				} else if (key == 1 && lastKey == 0) {
					if (values.tipo == 2) {
						error =
							"la cuenta " +
							values.codigo +
							" - " +
							values.nombre +
							" debe ser una cuenta de titulo ";
					}
				}
			}
		}

		if (error == "") {
			var codigo = concatenatedValues.slice(0, -1);
			values.codigo = codigo;
			values.madre = madre;
			saveCabecera(values, { setValues });
		} else {
			Swal.fire({
				icon: "error",
				title: "Error en código",
				text: error,
				showConfirmButton: true,
			});
			setBtnCarga(0);
		}
		//---------------------------
	};
	const saveCabecera = async (json, { setValues }) => {
		json.apropiacionfinal = json.apropiacion;
		json.saldo = json.apropiacion;
		json.user = parseInt(localStorage.getItem("idUser"));
		json.edicion = edicion;
		json.vrOriginal = vrOriginal;
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(
				route_confiPresupuesto_base + "/saveproyeccion",
				json,
				config
			);
			const successMessage = "Cuenta Creada";

			Swal.fire({
				icon: "success",
				title: successMessage,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				setBtnCarga(0);
				btnCancel.current.click();
				getProyecciones(cabeceraSelected);
				//RECARGO OPCIONES PREDEFINIDAS
				const selected = cabeceras.find((item) => item.id === cabeceraSelected);
				setSelectedOption({ label: selected.nombre, value: selected.id });
				setEdicion(0);
				const nuevosValores = {
					id: "",
					idEstablishment: establishment.id,
					user: localStorage.getItem("idUser"),
					plan: cabeceraSelected,
					nombre: "",
					naturaleza: "",
					codigo: "",
					tipo: "",
					madre: "",
					apropiacion: "",
					sia: null,
					sifse: null,
				};
				// Actualiza los valores del formulario utilizando setValues
				setValues(nuevosValores);
				setInputValues([]);
				setSiaS({ label: "SIA", value: null });
				setSifseS({ label: "Sifse Ingreso", value: null });
				setSifseDetS({ label: "Sifse Detalle", value: null });
			});
		} catch (error) {
			if (error.response && error.response.status === 500) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Verifique que el codigo no se encuentre creado previamente",
					showConfirmButton: true,
				}).then(() => {});
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error de red, contactese con su proveedor",
					showConfirmButton: true,
				}).then(() => {});
			}
		}
	};
	const getProyecciones = async (idC) => {
		console.log("ok");
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_confiPresupuesto_base}/getproyecciones/${idC}`,
			config
		);
		setCabeceraSelected(idC);
		var jsonProcesado = procesarJson(result.data);
		let order = jsonProcesado.sort(customSort);
		console.log(order);
		setProyecciones(order);
		console.log(order)
		const Debitos = result.data
			.filter((obj) => obj.naturaleza === 1 && obj.tipo == 2)
			.reduce((total, obj) => total + obj.apropiacion, 0);
		const Creditos = result.data
			.filter((obj) => obj.naturaleza === 2 && obj.tipo == 2)
			.reduce((total, obj) => total + obj.apropiacion, 0);

		const cab = cabeceras.find((item) => item.id === idC);
		setCabeceraObject(cab);
		setVrDebitos(Debitos);
		setVrCreditos(Creditos);
		//deshabilita los botoes  si se encuentra en estado diferente a construccion(0)
		if (cab.estado != 0) {
			setDisabled(true);
			setBtnSolicitud(true);
		} else {
			setDisabled(false);
			setBtnSolicitud(false);
		}
		//deshabilita el boton soliciatr aprobacion si creditos y debitos son desiguales
		if (Debitos == Creditos && Debitos > 0 && cab.estado == 0) {
			setBtnSolicitud(false);
		} else {
			setBtnSolicitud(true);
		}
		setBanCarga(0);
	};
	function functionPagination(num) {
		setBanCarga(1);
		setNumpages(num);
		setNumpagesO({ value: num, label: `${num}` });
		getProyecciones(cabeceraSelected);
	}
	function procesarJson(jsonData) {
		const procesado = jsonData.map((item) => {
			let labelNat = "";
			let labelTipo = "";

			// Determinar el valor de labelNat según el valor de naturaleza
			if (item.naturaleza === 1) {
				labelNat = "Debito";
			} else if (item.naturaleza === 2) {
				labelNat = "Credito";
			}

			// Determinar el valor de labelTipo según el valor de tipo
			if (item.tipo === 1) {
				labelTipo = "Titulo";
			} else if (item.tipo === 2) {
				labelTipo = "Detalle";
			}

			// Devolver el objeto con los campos labelNat y labelTipo agregados
			return {
				...item,
				labelNat,
				labelTipo,
			};
		});
		var organizados = reorganizarCuentas(procesado);
		return procesado;
	}
	function reorganizarCuentas(json) {
		const cuentasPorId = {};

		// Construir un objeto donde las cuentas se almacenan por su ID
		json.forEach((cuenta) => {
			cuentasPorId[cuenta.id] = { ...cuenta, hijas: [] };
		});

		// Recorrer nuevamente el JSON para construir la relación entre cuentas y sus hijas
		json.forEach((cuenta) => {
			if (cuenta.madre !== null) {
				// Si la cuenta tiene una "madre", agregarla como hija de esa "madre"
				cuentasPorId[cuenta.madre].hijas.push(cuentasPorId[cuenta.id]);
			}
		});

		// Filtrar las cuentas principales que no tienen madre
		const cuentasPrincipales = json.filter((cuenta) => cuenta.madre === null);

		return cuentasPrincipales.map((cuenta) => cuentasPorId[cuenta.id]);
	}
	const handleConfirmarSolicitud = (estado) => {
		Swal.fire({
			title: "Esta solicitud no es reversible?",
			icon: "warning",
			text: "Verifique los valores",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				solicitarAprobacion(estado);
			}
		});
	};

	const solicitarAprobacion = async (estado) => {
		setBanSol(1);
		let values = {
			id: cabeceraSelected,
			estado: estado,
			user: localStorage.getItem("idUser"),
		};
		let dataJson = JSON.stringify(values);
		try {
			const response = await axios.post(
				route_confiPresupuesto_base + "/estadoPlan",
				dataJson,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			Swal.fire({
				icon: "success",
				title: "Solicitud enviada",
				showConfirmButton: false,
				timer: 1500,
			});
			setBanSol(0);
			getCabeceras();
			setBtnSolicitud(true);
			setDisabled(true);
			if (estado == 0) {
				setSelectedOption(null);
				setProyecciones([]);
				setEstructura([]);
				setCabeceraObject({});
				setCabeceraSelected(null);
			}
		} catch (error) {
			throw error;
		}
	};
	const getCabeceras = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_confiPresupuesto_base}/getcabeceras/${establishment.id}`,
			config
		);

		setCabeceras(result.data);
	};
	const getInfoCab = (idE) => {
		const cab = cabeceras.find((item) => item.id === idE);
		getProyecciones(idE);
		if (cab.estado == 0) {
			setLabel("En proceso");
		} else if (cab.estado == 1) {
			setLabel("Aprobado");
		} else if (cab.estado == 2) {
			setLabel("Rechazado");
		} else if (cab.estado == 3) {
			setLabel("Esperando aprobación");
		}
	};
	const getEstructuras = async (naturaleza) => {
		var idE = cabeceraSelected;
		var est = "";
		{
			naturaleza == 1 ? (est = "estructura") : (est = "estructura2");
		}
		const cab = cabeceras.find((item) => item.id === idE);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		if (cab) {
			const result = await axios.get(
				`${route_confiPresupuesto_base}/getconf/${cab[est]}`,
				config
			);
			let estructuraOrder = result.data
				.slice()
				.sort((a, b) => a.nivel - b.nivel);
			setEstructura(estructuraOrder);
		}
	};
	const handleDelete = (servicioId) => {
		let esMadre = null;
		esMadre = proyecciones.find((obj) => obj.madre == servicioId);
		if (esMadre == null) {
			Swal.fire({
				title: "Eliminar Cuenta?",
				text: "La cuenta a eliminar no debe tener cuentas hijas!",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Aceptar",
				cancelButtonText: "Cancelar",
			}).then((result) => {
				if (result.isConfirmed) {
					Swal.fire({
						title: "Eliminando...",
						allowOutsideClick: false,
						allowEscapeKey: false,
						showConfirmButton: false,
						didOpen: () => {
							deleteServicio(servicioId, false)
								.then((response) => {
									if (response) {
										var icon = "success";
										var msg = "Cuenta eliminada";
									} else {
										var icon = "warning";
										var msg =
											"Error: Verifique que no exista una configuración activa";
									}
									Swal.fire({
										icon: icon,
										title: msg,
										showConfirmButton: false,
										timer: 1500,
									}).then(() => {
										getProyecciones(cabeceraSelected);
									});
								})
								.catch((error) => {
									Swal.fire({
										icon: "error",
										title: "Hubo un error al eliminar la cuenta",
										text: error.message,
										showConfirmButton: true,
									});
								});
						},
					});
				}
			});
		} else {
			Swal.fire({
				title: "Error",
				text:
					"La cuenta " +
					esMadre.codigo +
					" - " +
					esMadre.nombre +
					" tiene cuentas hijas",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Aceptar",
				cancelButtonText: "Cancelar",
			});
		}
	};
	const deleteServicio = async (servicioId, status) => {
		try {
			const response = await axios.delete(
				route_confiPresupuesto_base +
					"/deleteproyeccion/" +
					servicioId +
					"," +
					localStorage.getItem("idUser"),

				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			throw error;
		}
	};
	const handleInputChange = (event) => {
		const { id, value, max } = event.target;
		if (value.length <= max) {
			setInputValues((prevValues) => ({
				...prevValues,
				[id]: value,
			}));
		} else {
			const nextInput = document.getElementById(parseInt(id) + 1);
			if (nextInput) {
				nextInput.focus();
			}
		}
	};
	const handleInputFocus = (event) => {
		const { id, value, max } = event.target;
		if (value.length > 0 && value.length < max) {
			Swal.fire({
				icon: "error",
				title: "Verifique el número de caracteres",
				text: "La cuenta " + value + " Debe tener " + max + " caracteres",
				showConfirmButton: true,
			});
			document.getElementById(id).value = "";
		}
	};

	const modalSiaSifse = (cab) => {
		// Realiza la lógica deseada en A con el ID recibido
		console.log(cab);
		getSia(cab.naturaleza);
		getSifse(cab.naturaleza);
		setCtaUpdate(cab);
		setSiaS(
			cab.sia !== null
				? {
						label: `${cab.siaO.codigo} - ${cab.siaO.nombre}`,
						value: cab.sia,
				  }
				: null
		);
		setSifseS(
			cab.sifse !== null
				? {
						label: `${cab.sifseO.codigo} - ${cab.sifseO.nombre}`,
						value: cab.sifse,
				  }
				: null
		);
		setSifseDetS(
			cab.sifsedet !== null
				? {
						label: `${cab.sifseDetO.codigo} - ${cab.sifseDetO.nombre}`,
						value: cab.sifsedet,
				  }
				: null
		);
		setNomEdit(cab.nombre)
	};
	const handleEdit = (mov) => {
	
		setSiaS({ label: "SIA", value: null });
		setSifseS({ label: "Sifse Ingreso", value: null });
		setSifseDetS({ label: "Sifse Detalle", value: null });
		setEdicion(1);
		setVrOriginal(mov.apropiacion);
		if (formikRef.current) {
			formikRef.current.setValues(mov);
		}
		//------ mostrar valores ----------------------
		//---- Naturaleza --------------------------------
		if (mov.naturaleza == 1) {
			setSelectedOptionN({ label: "Débito", value: 1 });
			getSia(1);
			getSifse(1);
		} else if (mov.naturaleza == 2) {
			setSelectedOptionN({ label: "Crédito", value: 2 });
			getSia(2);
			getSifse(2);
		}
		//---- Tipo --------------------------------------
		if (mov.tipo == 1) {
			setSelectedOptionT({ label: "Titulo", value: 1 });
		} else if (mov.tipo == 2) {
			setSelectedOptionT({ label: "Detalle", value: 2 });
		}
		//---- Apropiacion---------------------------------
		setInputValor(mov.apropiacion);
		//------- SIA ------------------------------------
		if (mov.siaO) {
			setSiaS({
				label: mov.siaO.codigo + " - " + mov.siaO.nombre,
				value: mov.siaO.id,
			});
		}
		//------- SIFSE ------------------------------------
		if (mov.sifseO) {
			setSifseS({
				label: mov.sifseO.codigo + " - " + mov.sifseO.nombre,
				value: mov.sifseO.id,
			});
		}
		if (mov.sifseDetO) {
			setSifseDetS({
				label: mov.sifseDetO.codigo + " - " + mov.sifseDetO.nombre,
				value: mov.sifseDetO.id,
			});
		}
		// almacenar codigos en array
		getEstructuras(mov.naturaleza);
		const arrayFromInput = mov.codigo.split(".");
		const resultObject = {};
		arrayFromInput.forEach((value, index) => {
			resultObject[`${index + 1}`] = value;
		});
		setInputValues(resultObject);
	};

	useEffect(() => {
		var columns = [
			{ Header: "Código", accessor: "codigo" },
			{ Header: "Nombre", accessor: "nombre" },
			{ Header: "Naturaleza", accessor: "labelNat" },
			{ Header: "Tipo", accessor: "labelTipo" },
			{ Header: "Apropiacion", accessor: "apropiacion" },
		];
		setColumnas(columns);
		getCabeceras();
	}, []);
	const validationSchema = Yup.object().shape({
		plan: Yup.number().required("Seleccione el plan presupuestal"),
		nombre: Yup.string().required("Seleccione el nombre de la cuenta"),
		naturaleza: Yup.number().required("Seleccione la naturaleza de la cuenta"),
		tipo: Yup.number().required("Seleccione el tipo de movimiento"),
		//codigo: Yup.string().required("Seleccione el tipo de movimiento"),
		apropiacion: Yup.number()
			.required("Ingrese la apropiación")
			.min(0, "La apropiación debe ser mayor o igual a cero"),
	});
	const initialValues = {
		id: "",
		idEstablishment: establishment.id,
		user: localStorage.getItem("idUser"),
		plan: "",
		nombre: "",
		naturaleza: "",
		codigo: "",
		tipo: "",
		madre: "",
		apropiacion: "",
		sia: null,
		sifse: null,
		sifsedet: null,
	};
	const getSia = async (tipo) => {
		setListSia([]);
		setSiaS({ label: "SIA", value: null });
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_procesosPresupuesto}/FindSia/${tipo}`,
			config
		);
		const formattedOptions = result.data.map((item) => ({
			value: item.id,
			label: item.codigo + " - " + item.nombre,
			tipo: item.tipo,
			clase: item.clase,
			isDisabled: item.clase == 1 ? true : false,
		}));
		// Ordenar alfabéticamente por código
		formattedOptions.sort((a, b) => a.label.localeCompare(b.label));

		// Si los códigos son numéricos, puedes ordenar también numéricamente
		// En caso contrario, si son alfanuméricos, puedes omitir esta parte
		formattedOptions.sort((a, b) => {
			const codeA = parseInt(a.codigo, 10);
			const codeB = parseInt(b.codigo, 10);
			return codeA - codeB;
		});

		setListSia(formattedOptions);
	};
	const getSifse = async (tipo) => {
		setListSifse([]);
		setListSifseDet([]);
		setSifseS({ label: "SIFSE", value: null });
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_procesosPresupuesto}/FindSifse/${tipo}`,
			config
		);
		const formattedOptions = result.data.map((item) => ({
			value: item.id,
			label: item.codigo + " - " + item.nombre,
			tipo: item.tipo,
			clase: item.clase,
			isDisabled: item.clase == 1 ? true : false,
		}));
		formattedOptions.sort((a, b) => a.value - b.value);
    	let filteredOptionsIngreso = formattedOptions.filter(option => option.value <= 6);
		setListSifse(filteredOptionsIngreso);

		let filteredOptionsDet = formattedOptions.filter(option => option.value > 6);
		setListSifseDet(filteredOptionsDet);
	};

	const updateCta = async () => {
		try {
			const values = {
				id: ctaUpdate.id,
				sia: siaUp!=null? siaUp:null,
				sifse: sifseUp,
				sifsedet:sifseDetup,
				nombre:nomEdit,
				user: parseInt(localStorage.getItem("idUser")),
			};

			const response = await axios.post(
				`${route_procesosPresupuesto}/updatecods`,
				values,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);

			if (response.status === 200) {
				Swal.fire({
					icon: "success",
					title: "Códigos actualizados",
					showConfirmButton: false,
					timer: 1500,
				}).then(() => {
					getProyecciones(cabeceraSelected);
					btnClose.current.click();
					closemod.current.click();
				});
			} else {
				Swal.fire({
					icon: "error",
					title: "Error en la actualización",
					text: "Hubo un problema al actualizar los códigos.",
					showConfirmButton: true,
				});
			}
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error en la actualización",
				text: error.response ? error.response.data.message : error.message,
				showConfirmButton: true,
			});
		}
	};

	return (
		<div className="containerFormUser">
			{cabeceraObject && cabeceraObject.estado == 2 ? (
				<div class="alert alert-danger" role="alert">
					El plan presupuestal se ha rechazado por el siguiente motivo:&nbsp;
					{cabeceraObject.motivo}
					<br></br>
					<button
						className="btn btn-primary"
						onClick={() => handleConfirmarSolicitud(0)}
					>
						Corregir
					</button>
				</div>
			) : null}
			<h1>
				Proyección de plan presupuestal{" "}
				<label style={{ fontSize: "11px", color: "blue" }}>({label})</label>{" "}
			</h1>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
				innerRef={formikRef}
			>
				{(formik, props) => (
					<Form>
						<div className="row">
							{/* BLOQUE1 */}
							<div className="col-md-3  ">
								<div className="form-floating mb-2  ">
									<Select
										isClearable={true}
										styles={customStyles}
										value={selectedOption}
										placeholder="Plan presupuestal"
										onChange={(e) => {
											if (e) {
												handleSelectChange();
												getInfoCab(e.value);
												formik.setFieldValue("plan", e.value);
											} else {
												setEstructura([]);
												setProyecciones([]);
											}
										}}
										options={cabeceras.map((rol) => ({
											label: rol.nombre,
											value: rol.id,
										}))}
									/>

									<ErrorMessage name="plan" component="div" className="error" />
								</div>
							</div>
							<div className="col-3  ">
								<div className="form-floating mb-2  ">
									<Select
										isClearable={true}
										styles={customStyles}
										placeholder="Naturaleza"
										value={selectedOptionN}
										onChange={(e) => {
											setSelectedOptionN(e ? e : null);
											formik.setFieldValue("naturaleza", e ? e.value : "");
											if (e && e.value) {
												getSia(e.value);
												getSifse(e.value);
												getEstructuras(e.value);
											} else {
												setEstructura([]);
											}
										}}
										options={[
											{ label: "Debito", value: 1 },
											{ label: "Credito", value: 2 },
										]}
										classNamePrefix="select"
									/>

									<ErrorMessage
										name="naturaleza"
										component="div"
										className="error"
									/>
								</div>
							</div>
							<div className="col-3  ">
								<div className="form-floating mb-2  ">
									<Select
										styles={customStyles}
										placeholder="Tipo"
										value={selectedOptionT}
										onChange={(e) => {
											validaApropiacion(e.value, formik);
											handleSelectChangeT();
											formik.setFieldValue("tipo", e.value ? e.value : "");
										}}
										options={[
											{ label: "Titulo", value: 1 },
											{ label: "Detalle", value: 2 },
										]}
										classNamePrefix="select"
									/>

									<ErrorMessage name="tipo" component="div" className="error" />
								</div>
							</div>
							<div className="col-3  ">
								<div className="form-floating mb-2  ">
									<NumericFormat
										style={{ ...inputStyles, textAlign: "right" }}
										value={inputValor}
										className="form-control"
										thousandSeparator="."
										decimalSeparator=","
										readOnly={readO}
										onChange={(e) => {
											handleValorChange(
												parseFloat(
													e.currentTarget.value
														.replace(/\./g, "")
														.replace(/,/g, ".")
												)
											);
											formik.setFieldValue(
												"apropiacion",
												e
													? parseFloat(
															e.currentTarget.value
																.replace(/\./g, "")
																.replace(/,/g, ".")
													  )
													: ""
											);
										}}
									/>
									<label htmlFor="apropiacion" className="form-label">
										Valor
									</label>
									<ErrorMessage
										name="apropiacion"
										component="div"
										className="error"
									/>
								</div>
							</div>
							{/* BLOQUE2 */}
							<div className="col-3  ">
								<div className="form-floating mb-2  ">
									<Field
										style={inputStyles}
										type="text"
										name="nombre"
										id="nombre"
										className="form-control"
										placeholder="Nombre"
									/>
									<label
										style={{ zIndex: "0" }}
										htmlFor="forma"
										className="form-label"
									>
										Nombre de cuenta
									</label>
									<ErrorMessage
										name="nombre"
										component="div"
										className="error"
									/>
								</div>
							</div>
							<div className="col-8" style={{ display: "flex" }}>
								<label className="text text-info">Código: &nbsp;&nbsp;</label>
								{estructura.length > 0 ? (
									estructura.map((nivel) => (
										<>
											<input
												//onKeyUp={validarCodigo()}
												id={nivel.nivel}
												key={nivel.nivel}
												type="text"
												title={nivel.nombre}
												max={nivel.caracteres}
												value={inputValues[nivel.nivel] || ""}
												onChange={handleInputChange}
												onBlur={handleInputFocus}
												style={{
													backgroundColor: nivel.color,
													width: "35px",
													height: "45px",
													marginRight: "5px",
												}}
											/>
										</>
									))
								) : (
									<>
										{" "}
										<label className="text-danger" style={{ fontSize: "20px" }}>
											{" "}
											Seleccione un plan presupuestal{" "}
										</label>{" "}
									</>
								)}
							</div>

							<div className="col-3  ">
								<div className="form-floating mb-2  ">
									<Select
										styles={customStyles}
										placeholder="SIA"
										isClearable={true}
										value={siaS}
										isDisabled={readO}
										onChange={(e) => {
											setSiaS(e ? e : null);
											formik.setFieldValue("sia", e ? e.value : null);
										}}
										options={listSia}
										classNamePrefix="select"
									/>
								</div>
							</div>
							<div className="col-3  ">
								<div className="form-floating mb-2  ">
									<Select
										styles={customStyles}
										placeholder="Sifse ingreso"
										isClearable={true}
										isDisabled={readO}
										value={sifseS}
										onChange={(e) => {
											setSifseS(e ? e : null);
											formik.setFieldValue("sifse", e ? e.value : null);
										}}
										options={listSifse}
										classNamePrefix="select"
									/>
								</div>
							</div>
							<div className="col-3  ">
								<div className="form-floating mb-2  ">
									<Select
										styles={customStyles}
										placeholder="Sifse detalle"
										isClearable={true}
										isDisabled={readO}
										value={sifseDetS}
										onChange={(e) => {
											setSifseDetS(e ? e : null);
											formik.setFieldValue("sifsedet", e ? e.value : null);
										}}
										options={listSifseDet}
										classNamePrefix="select"
									/>
								</div>
							</div>
						</div>
						{btnCarga === 0 ? (
							<button
								type="submit"
								disabled={disabled}
								className="btn btn-primary"
							>
								Guardar
							</button>
						) : (
							/* spinner carga */
							<button className="btn btn-primary" type="button" disabled>
								<span
									className="spinner-border spinner-border-sm"
									aria-hidden="true"
								></span>
								<span role="status"> Guardando...</span>
							</button>
							/*--------------- */
						)}
						&nbsp;
						<button
							type="reset"
							ref={btnCancel}
							onClick={handleClear}
							className="btn btn-danger"
						>
							Cancelar
						</button>
					</Form>
				)}
			</Formik>
			<br></br>
			<h1>Plan presupuestal</h1>

			<div class="row">
				<div className="col-4  ">
					<div className="form-floating mb-2  ">
						<NumericFormat
							value={vrDebitos}
							style={{ ...inputStyles, textAlign: "right" }}
							className="form-control"
							thousandSeparator="."
							decimalSeparator=","
							readOnly={true}
						/>
						<label htmlFor="apropiacion" className="form-label">
							Débitos
						</label>
					</div>
				</div>
				<div className="col-4  ">
					<div className="form-floating mb-2  ">
						<NumericFormat
							value={vrCreditos}
							style={{ ...inputStyles, textAlign: "right" }}
							className="form-control"
							thousandSeparator="."
							decimalSeparator=","
							readOnly={true}
						/>
						<label htmlFor="apropiacion" className="form-label">
							Créditos
						</label>
					</div>
				</div>
				<div className="col-1">
					{/* <button
						style={{ width: "95%", height: "80%" }}
						className="btnInfo"
						type="button"
						data-bs-toggle="modal"
						data-bs-target="#modalPdf"
					>
						<i class="fas fa-file-pdf"></i>
					</button> */}
				</div>
				<div className="col-3">
					{banSol == 0 ? (
						<button
							style={{ width: "95%", height: "80%" }}
							className="btn btn-success"
							disabled={btnSolicitud}
							onClick={() => handleConfirmarSolicitud(3)}
						>
							<FaCheckDouble /> Solicitar Aprobación
						</button>
					) : (
						/* spinner carga */
						<button className="btn btn-primary" type="button" disabled>
							<span
								className="spinner-border spinner-border-sm"
								aria-hidden="true"
							></span>
							<span role="status"> Cargando...</span>
						</button>
						/*--------------- */
					)}
				</div>
			</div>
			{banCarga == 0 ? (
				<TableIngresos
					data={proyecciones}
					columns={columnas}
					encabezado={{
						empresa: establishment.name,
						nit: establishment.identification,
						titulo: "",
						imagen: establishment.image,
						fechas: "",
						generado: "",
					}}
					onClickButton={modalSiaSifse}
					onClickButtonNombre={modalSiaSifse}
					onClickDelete={handleDelete}
					onClickPagination={functionPagination}
					numPages={numPages}
					numPagesO={numPagesO}
					disabled={disabled}
					onClickEdit={handleEdit}
				/>
			) : (
				<div className="spinner-overlay">
					<button className="btn btn-primary" type="button" disabled>
						<span
							className="spinner-border spinner-border-sm"
							aria-hidden="true"
						></span>
						<span role="status"> Cargando...</span>
					</button>
				</div>
				/*--------------- */
			)}
			{/* modal con proyeccion en pdf */}
			<div
				className="modal fade"
				id="modalPdf"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabindex="-1"
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-dialog-scrollable">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="staticBackdropLabel">
								Proyección plan presupuestal
							</h1>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>

						<div className="modal-body">
							<ProyeccionPDF
								style={{ width: "100%" }}
								establishment={establishment}
								plan={proyecciones}
								cabecera={cabeceraObject}
								estructura={estructura}
							/>
						</div>
					</div>
				</div>
			</div>
			{/* --------------------------------- */}

			{/* modal actualizar SIA SIFSE */}
			<div
				className="modal fade"
				id="modalSIA"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabindex="-1"
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-dialog-scrollable">
					<div
						className="modal-content"
						style={{ height: "400px", width: "600px" }}
					>
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="staticBackdropLabel">
								Actualizar codigos SIA - SIFSE
							</h1>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>

						<div className="modal-body">
							<h6>
								{ctaUpdate != null ? ctaUpdate.codigo : null} -{" "}
								{ctaUpdate != null ? ctaUpdate.nombre : null}
							</h6>
							{/* card sia */}
							<div class="card">
								<div class="card-header">SIA</div>
								<div class="card-body">
									<Select
										styles={customStyles}
										placeholder="SIA"
										isClearable={true}
										value={siaS}
										isDisabled={readO}
										onChange={(e) => {
											setSiaS(e ? e : null);
											setSiaUp(e ? e.value : null);
										}}
										options={listSia}
										classNamePrefix="select"
									/>
								</div>
							</div>
							&nbsp;
							{/* CARD SIFSE */}
							<div class="card">
								<div class="card-header">SIFSE</div>
								<div class="card-body">
									<div className="row">
										<div className="col-6">
											<Select
												styles={customStyles}
												placeholder="Fuente ingreso"
												isClearable={true}
												isDisabled={readO}
												value={sifseS}
												onChange={(e) => {
													setSifseS(e ? e : null);
													setSifseUp(e ? e.value : null);
												}}
												options={listSifse}
												classNamePrefix="select"
											/>
										</div>
										<div className="col-6">
											<Select
												styles={customStyles}
												placeholder="Item detalle"
												isClearable={true}
												isDisabled={readO}
												value={sifseDetS}
												onChange={(e) => {
													setSifseDetS(e ? e : null);
													setSifseDetUp(e ? e.value : null);
												}}
												options={listSifseDet}
												classNamePrefix="select"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button
								type="button"
								class="btn btn-secondary"
								data-bs-dismiss="modal"
								ref={btnClose}
							>
								Cerrar
							</button>
							<button
								type="button"
								class="btn btn-primary"
								onClick={() => updateCta()}
							>
								Actualizar
							</button>
						</div>
					</div>
				</div>
			</div>
			{/* --------------------------------- */}
			{/* modal edicion nombre de cuenta */}
			<div
				class="modal fade"
				id="editarNombreCta"
				tabindex="-1"
				aria-labelledby="editarNombreCtaLabel"
				aria-hidden="true"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<h1 class="modal-title fs-5" id="editarNombreCtaLabel">
								Editar Nombre
							</h1>
							<button
								type="button"
								class="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								ref={closemod}
							></button>
						</div>
						<div
							className="modal-body"
							style={{ display: "flex", alignItems: "center" }}
						>
							<input
								className="form-control"
								style={{ marginRight: "10px" }}
								onChange={(e) => {
									setNomEdit(e.target.value);
								}}
								value={nomEdit}
							></input>
							<button
								className="btn btn-primary"
								onClick={() => updateCta()}
							>
								<i className="fas fa-save"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Proyeccion;
