import React, { useState, useEffect, useContext, useRef } from "react";
import { parseISO, getMonth, getYear } from "date-fns";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AuthContext } from "../../context/LogContext";
import { NumericFormat } from "react-number-format";
import axios from "axios";
import Select from "react-select";
import * as Yup from "yup";
import { FaPlusCircle } from "react-icons/fa";
import {
	route_contabconf_base,
	route_contamovs_base,
	route_person_searchPerson,
	route_configuration_base,
} from "../../../api-routes/RoutesLogin";
import Swal from "sweetalert2";
import RegTerceros from "../../persons/register/RegisterPerson";

export default function NuevoMovimiento(props) {
	/* ------- CONTABILIDAD */
	const [comprobantes, setComprobantes] = useState([]);
	const [centrosCosto, setCentrosCosto] = useState([]);
	const [Puc, setPuc] = useState([]);
	const [arrayAsientos, setArrayAsientos] = useState([]);
	const [contador, setContador] = useState(0);
	const [totalDB, setTotalDB] = useState(0);
	const [totalCR, setTotalCR] = useState(0);
	const [centroS, setCentroS] = useState(null);
	const [movimiento, setMovimiento] = useState(null);
	const formikRef = useRef(null);
	const selectRefCtas = useRef(null);
	const [comprobanteSel, setComprobanteSel] = useState(null);
	const [ctasxcobrar, setCtasxcobrar] = useState([]);
	const [ctaxCSel, setCtaxCSel] = useState(null);
	const [arrayCtasxC, setArrayCtasxC] = useState([]);
	const [cuenta, setCuenta] = useState(null);
	const [cuentaO, setCuentaO] = useState(null);
	const [docAfectado, setDocAfectado] = useState(null);
	const [ctaSaldo, setCtaSaldo] = useState(false);
	const [saldo, setSaldo] = useState(0);
	const [base, setBase] = useState(0);
	const [porcentaje, setPorcentaje] = useState(0);
	const [valor, setValor] = useState(0);
	const [dctos, setDctos] = useState([]);
	const [dctoSelected, setDctoSelected] = useState(null);
	const [arrayDctos, setArrayDctos] = useState([]);
	const closeDctoRef = useRef(null);
	const buttonCloseDcto = useRef(null);
	const [difDeb, setDifDeb] = useState(0);
	const [difCre, setDifCre] = useState(0);
	/* -------------------- */
	const closeModal = useRef(null);
	const selectRef = useRef(null);
	const [isLoading, setIsLoading] = useState(false);

	const {
		establishment,
		getToken,
		FechaYMD,
		FechaYMDHMS,
		customStyles,
		dateStyles,
		inputStyles,
	} = useContext(AuthContext);
	const { tipo, categoria, idM } = useParams();
	const [titulo, setTitulo] = useState("");
	const [optionsPersons, setOptionsPersons] = useState([]);
	const [debito, setDebito] = useState(0);
	const [credito, setCredito] = useState(0);
	const [verCredito, setVerCredito] = useState(false);
	const [verDebito, setVerDebito] = useState(false);
	const [isEnableDebito, setIsEnableDebito] = useState(true);
	const [isEnableCredito, setIsEnableCredito] = useState(true);
	const [contadorId, setContadorId] = useState(1); // Inicializa el contador en 1
	const [isDisabled, setIsDisabled] = useState(true);
	const [personSelected, setPersonSelected] = useState(null);

	const [mActivos, setMActivos] = useState([]);
	const navigate = useNavigate();
	const [btnCarga, setBtnCarga] = useState(0);

	/* ----- funcines contabilidad */
	const getComprobantes = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_contabconf_base}/getComprobantesActivos/${tipo},${establishment.id},${categoria}`,
			config
		);
		const formattedOptions = result.data.map((item) => ({
			value: item.id,
			label: item.nombre,
		}));
		setComprobantes(formattedOptions);
	};
	const getCentrosCosto = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_contabconf_base}/getCentrosC/${establishment.id}`,
			config
		);

		const sortedData = result.data.sort((a, b) => {
			if (a.codigo < b.codigo) return -1;
			if (a.codigo > b.codigo) return 1;
			return 0;
		});
		const formattedOptions = sortedData.map((item) => ({
			value: item.id,
			label: item.codigo + " - " + item.nombre,
		}));
		// Asignar el array ordenado al estado
		setCentrosCosto(formattedOptions);
	};
	const getPuc = async () => {
		//setBanCarga(1)
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_contabconf_base}/getpuc/${establishment.id}`,
			config
		);
		//let newPuc = reemplazarMadreConCodigo(result.data.pucDTOList);
		console.log(result.data);
		let order = result.data.pucDTOList.sort(customSort);
		const formattedOptions = order.map((item) => ({
			value: item.id,
			label: item.codigo + " - " + item.nombre,
			tipo: item.tipo,
			naturaleza: item.naturaleza,
			isDisabled: item.tipo == 1 ? true : false,
			codCategoria: item.codCategoria,
		}));

		setPuc(formattedOptions);
	};
	function agregarMovimiento() {
		console.log(ctaSaldo);
		if (
			cuenta != null &&
			debito != null &&
			credito != null &&
			centroS != null
		) {
			// valida si es cta x cobrar que no se exceda el valor del saldo

			if ((ctaSaldo == true && credito <= saldo) || ctaSaldo == false) {
				let id = contador + 1;
				if (arrayAsientos.length > 0) {
					id = arrayAsientos.length + 1;
				}
				var cta = Puc.find((p) => p.value == cuenta);
				let asiento = {
					numero: id,
					cuenta: cuenta,
					debito: debito,
					credito: credito,
					centro: centroS.value,
					docafectado: ctaSaldo == false ? docAfectado : null,
					ctaSaldo: ctaSaldo,
					saldomov: saldo,
					codCategoria: cta.codCategoria,
				};
				setContador(id);
				if (selectRef.current) {
					selectRef.current.clearValue(); // Llama al método clearValue para limpiar el Select
				}
				setCuenta(null);
				setDebito(0);
				setCredito(0);
				let arrayTotal = [...arrayAsientos, asiento];
				setArrayAsientos(arrayTotal);
				Totales(arrayTotal);
				setCtaSaldo(false);
				setSaldo(0);
			} else {
				Swal.fire({
					icon: "warning",
					title: "El valor excede el saldo de la cuenta",
					showConfirmButton: false,
					timer: 1500,
				});
			}
		} else {
			Swal.fire({
				icon: "warning",
				title: "Datos incompletos",
				showConfirmButton: false,
				timer: 1500,
			});
		}
	}
	const eliminarMov = (id) => {
		var ctaEliminada = arrayAsientos.find((c) => c.numero == id);
		// si se elimina una cuenta con saldo se reestablece la lista de ctas
		if (ctaEliminada.ctaSaldo == true) {
			const formattedOptions = arrayCtasxC.map((item) => ({
				value: item.id,
				label: (
					<span>
						<b>Documento: </b>
						{item.numero}&nbsp;<b>Fecha: </b>
						{item.fecha}&nbsp;<b>Detalle: </b>
						{item.detalle}
						&nbsp;<b>Cuenta: </b>
						{item.cuenta}&nbsp;<b>Saldo: </b>
						<label style={{ color: "red" }}>
							{item.saldo.toLocaleString()}
						</label>
					</span>
				),
			}));
			setCtasxcobrar(formattedOptions);
			const nuevasCuentas = arrayAsientos.filter(
				(cuenta) => cuenta.numero !== id && cuenta.docafectado !== docAfectado
			);
			setDocAfectado(null);
			setArrayAsientos(nuevasCuentas);
			Totales(nuevasCuentas);
		} else {
			const nuevasCuentas = arrayAsientos.filter(
				(cuenta) => cuenta.numero !== id
			);
			setArrayAsientos(nuevasCuentas);
			Totales(nuevasCuentas);
		}
		// si la cuenta essta enlazada a un descuento lo elimina
		var newDctos = arrayDctos.filter((d) => d.movimiento != id);
		setArrayDctos(newDctos);
	};
	function Totales(arrayTotal) {
		const totalDebito = arrayTotal.reduce(
			(acc, transaccion) => acc + transaccion.debito,
			0
		);
		const totalCredito = arrayTotal.reduce(
			(acc, transaccion) => acc + transaccion.credito,
			0
		);
		setTotalDB(totalDebito);
		setTotalCR(totalCredito);
		if (totalDebito > 0 && totalCredito > 0 && totalCredito === totalDebito) {
			setIsDisabled(false);
		} else {
			setIsDisabled(true);
		}
		//label con diferencia en el lado respectivo
		if (totalDebito != totalCredito) {
			var diferencia = totalDebito - totalCredito;
			if (diferencia > 0) {
				setDifCre(diferencia);
			} else {
				setDifDeb(diferencia);
			}

			console.log(diferencia);
		} else {
			setDifCre(0);
			setDifDeb(0);
		}
	}
	const handleSubmit = (values) => {
		setBtnCarga(1);
		values.creditos = totalCR;
		values.debitos = totalDB;
		let jsonFinal = {
			cabecera: values,
			detalleList: arrayAsientos,
		};
		console.log(jsonFinal);
		//cuenta cuantas ctas por cobrar hay en la lista de detalles
		const ctasxcobrar = jsonFinal.detalleList.filter(
			(detalle) => detalle.codCategoria === 2
		).length;
		const ctasxpagar = jsonFinal.detalleList.filter(
			(detalle) => detalle.codCategoria === 7
		).length;

		if (
			((tipo == 1 && ctasxcobrar === 0) || (tipo == 2 && ctasxpagar === 0)) &&
			categoria == 1
		) {
			var titulo = "";
			var msj = "";
			if (tipo == 1) {
				titulo = "Este movimiento no generará un cuenta por cobrar";
				msj = "No existe un cuenta configurada como cuenta por cobrar";
			}
			if (tipo == 2) {
				titulo = "Este movimiento no generará una cuenta por pagar";
				msj = "No existe un cuenta configurada como cuenta por pagar";
			}
			Swal.fire({
				title: titulo,
				text: msj,
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Aceptar",
				cancelButtonText: "Cancelar",
			}).then((result) => {
				if (result.isConfirmed) {
					registrarMovimiento(jsonFinal);
				}
			});
		} else {
			registrarMovimiento(jsonFinal);
		}
		setBtnCarga(0);
	};
	function checkPresupuesto(json){
		console.log(json)
	}

	const registrarMovimiento = async (json) => {
		/* confirmacion de movimiento presupuestal */
		var checkP=false;
		Swal.fire({
			title: "Afectación presupuestal",
			text: "Desea afectar presupuestalmente?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				//funcion que retorna true si los saldo presupuestales son correctos o false
				//checkP=checkPresupuesto(json)
				//alert("en proceso");
			}
		});
		/* --------------------------------------- */
		 const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		return axios
			.post(route_contamovs_base + "/saveMovimiento", json, config)
			.then((response) => {
				if (response.data == true) {
					Swal.fire({
						icon: "success",
						title: "Movimiento registrado ",
						showConfirmButton: false,
						timer: 1500,
					}).then(() => {
						setBtnCarga(0);
						navigate(
							"/menu/hefestoContabilidad/lista-movimientos/" +
								tipo +
								"/" +
								categoria
						);
					});
				} else {
					Swal.fire({
						icon: "warning",
						title: "Error",
						text: "Error al guardar movimiento",
						showConfirmButton: false,
						timer: 1500,
					}).then(() => {});
				}
			})
			.catch((error) => {
				setBtnCarga(0);
				console.error(error);
				throw error;
			}); 
	};
	const obtenerMovimiento = async (id) => {
		let data = [];

		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			let url = `${route_contamovs_base}/getMovimiento/${id}`;
			const response = await axios.get(url, config);
			data = response.data;
			const movEdit = response.data.cabecera;
			if (formikRef.current) {
				setPersonSelected({
					value: movEdit.terceroO.id,
					label:
						movEdit.terceroO.identificacion +
						" - " +
						movEdit.terceroO.nombreCompleto,
				});
				if (movEdit.comprobante) {
					console.log(movEdit.comprobanteO);
					let comp = {
						value: movEdit.comprobanteO.id,
						label: movEdit.comprobanteO.nombre,
					};
					setComprobanteSel(comp);
				}
				formikRef.current.setValues(movEdit);
				//cargar detalles
				const arrayTotal = response.data.detalleList.map((cta) => ({
					id: cta.id,
					numero: cta.id,
					cuenta: cta.cuenta,
					debito: cta.debito,
					credito: cta.credito,
					centro: cta.centro,
					descuento: cta.descuento,
				}));
				setArrayAsientos(arrayTotal);
				Totales(arrayTotal);
			}

			// cargue de array de descuentos
			var arrayD = [];
			response.data.detalleList.map((det) => {
				if (det.descuento != null) {
					console.log(det);
					var d = det.descuento;
					d.id = null;
					d.numero = d.id;
					arrayD = [...arrayD, d];
				}
			});
			console.log(arrayD);
			setArrayDctos(arrayD);
			console.log(data);
			//setMovimiento(data);
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}

		//return data;
	};
	const getCtasxCobrar = async (idT) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			let url = `${route_contamovs_base}/getCtasxCobrar/${establishment.id},${idT},${tipo}`;
			const response = await axios.get(url, config);
			setArrayCtasxC(response.data);
			const formattedOptions = response.data.map((item) => ({
				value: item.id,
				label: (
					<span>
						<b>Documento: </b>
						{item.numero}&nbsp;<b>Fecha: </b>
						{item.fecha}&nbsp;<b>Detalle: </b>
						{item.detalle}
						&nbsp;<b>Cuenta: </b>
						{item.cuenta}&nbsp;<b>Saldo: </b>
						<label style={{ color: "red" }}>
							{item.saldo.toLocaleString()}
						</label>
					</span>
				),
			}));
			setCtasxcobrar(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}

		//return data;
	};
	function agregarCtaxC() {
		var cta = arrayCtasxC.find((c) => c.id == ctaxCSel);
		setDocAfectado(cta.id);
		setCuentaO({ value: cta.idcta, label: cta.cuenta });
		setCuenta(cta.idcta);
		var centro = centrosCosto.find((c) => c.value == cta.centro);
		setCentroS(centro);
		setCredito(cta.saldo);
		setDebito(0);
		setCtasxcobrar([]);
		if (selectRefCtas.current) {
			selectRefCtas.current.clearValue();
		}
		setCtaSaldo(true);
		setSaldo(cta.saldo);
	}
	const stylePlan = {
		control: (base) => ({
			...base,
			height: 80,
			minHeight: 35,
			fontSize: "14px",
			height: "45px",
		}),
		option: (base, { data, isDisabled }) => ({
			...base,
			textAlign: `left`,
			backgroundColor: data.tipo === 1 ? "#5fb7eb" : "#3879db",
			color: "white",
		}),
	};

	/* --------------------------- */

	useEffect(() => {
		getDctos();
		getComprobantes();
		getCentrosCosto();
		getPuc();
		getMesesActivos();
		if (tipo == 1 && categoria == 1) {
			setTitulo("Cuentas por cobrar");
		} else if (tipo == 1 && categoria == 2) {
			setTitulo("Recibos de caja");
		} else if (tipo == 2 && categoria == 1) {
			setTitulo("Cuentas por pagar");
		} else if (tipo == 2 && categoria == 2) {
			setTitulo("Comprobantes de egreso");
		} else if (tipo == 3 && categoria == 1) {
			setTitulo("Comprobantes Contables");
		}

		if (idM) {
			obtenerMovimiento(idM);
		} else {
			//getCabecerasP(0);
		}
	}, [tipo, categoria]);

	function customSort(a, b) {
		// Ordenar por 'codigo'
		if (a.codigo < b.codigo) return -1;
		if (a.codigo > b.codigo) return 1;

		// Si 'codigo' es igual, ordenar por 'tipo'
		if (a.tipo < b.tipo) return -1;
		if (a.tipo > b.tipo) return 1;

		// Si 'codigo' y 'tipo' son iguales, ordenar por 'naturaleza'
		if (a.naturaleza < b.naturaleza) return -1;
		if (a.naturaleza > b.naturaleza) return 1;

		return 0;
	}

	const onInputChange = (inputValue) => {
		if (
			typeof inputValue === "string" &&
			inputValue != "" &&
			inputValue.length >= 3
		) {
			loadPersons(inputValue);
		}
	};

	const loadPersons = async (inputValue) => {
		setIsLoading(true);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			const response = await axios.get(
				route_person_searchPerson +
					establishment.id +
					"," +
					inputValue +
					",true",
				config
			);
			const data = response.data;
			const formattedOptions = data.map((item) => ({
				value: item.id,
				label:
					item.identification +
					" - " +
					item.name1 +
					" " +
					item.name2 +
					" " +
					item.lastName1 +
					" " +
					item.lastName2 +
					" - " +
					item.businessName,
			}));

			setOptionsPersons(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const validationSchema = () => {
		let baseSchema = {
			tercero: Yup.number().required("Este campo es requerido"),
			fecha: Yup.date().required("Este campo es requerido"),
			detalle: Yup.string().required("Este campo es requerido"),
			//centrocosto: Yup.number().required("Este campo es requerido"),
			comprobante: Yup.number().required("Este campo es requerido"),
		};

		return Yup.object().shape(baseSchema);
	};

	const initialValues = {
		id: null,
		tercero: "",
		comprobante: null,
		centrocosto: null,
		fecha: "",
		detalle: "",
		establishment: establishment.id,
		estado: 1,
		usuario: localStorage.getItem("idUser"),
		fechaCrea: FechaYMDHMS,
	};
	/* ---- formulario descuentos */
	const validationSchemaDcto = () => {
		let baseSchema = {
			descuento: Yup.number().required("Este campo es requerido"),
			//base: Yup.number().required("Este campo es requerido"),
			//porcentaje: Yup.number().required("Este campo es requerido"),
		};
		return Yup.object().shape(baseSchema);
	};
	const initialValuesDcto = {
		id: null,
		descuento: null,
		base: 0,
		porcentaje: 0,
		valor: 0,
		nombre: "",
		movimiento: null,
		usuario: localStorage.getItem("idUser"),
		fechaCrea: FechaYMDHMS,
	};
	const handleSubmitDcto = (values) => {
		if (valor > 0) {
			var numero = arrayDctos.length + 1;
			values.numero = numero;
			values.porcentaje = porcentaje;
			values.valor = valor;
			values.nombre = dctoSelected.nombre;
			setArrayDctos([...arrayDctos, values]);
			console.log(arrayDctos);
			if (closeDctoRef.current) {
				closeDctoRef.current.clearValue();
			}
			setBase(0);
			setPorcentaje(0);
			setValor(0);
		} else {
			Swal.fire({
				icon: "warning",
				title: "El valor debe ser mayor a cero (0)",
				showConfirmButton: false,
				timer: 1500,
			});
		}
	};
	const getDctos = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			let url = `${route_contabconf_base}/getDescuentos/${establishment.id}`;
			const response = await axios.get(url, config);
			console.log(response.data)
			
			/* const formattedOptions = response.data.map((item) => ({
				
				value: item.id,
				label: item.nombre + "  - (%) " + item.porcentaje,
				porcentaje: item.porcentaje,
				nombre: item.nombre,
				cuenta: item.cuenta,
			})); */
			const formattedOptions = response.data.map((item) => {
				const ctaObj = Puc.find((cta) => cta.value === item.cuenta);
				const ctaLabel = ctaObj ? ctaObj.label : "";
				return {
				  value: item.id,
				  label: item.nombre + "  - (%) " + ctaLabel ,
				  porcentaje: item.porcentaje,
				  nombre: item.nombre,
				  cuenta: item.cuenta,
				};
			  });
			setDctos(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}

		//return data;
	};
	function calcularDcto(base) {
		if (base !== 0 && dctoSelected != null) {
			console.log(dctoSelected);
			setPorcentaje(dctoSelected.porcentaje);
			var valor = parseFloat(base * parseFloat(dctoSelected.porcentaje / 100));
			setValor(valor);
		} else {
			setPorcentaje(0);
			setBase(0);
			setValor(0);
		}
	}
	function aplicarDctos() {
		let arrayTotal = arrayAsientos;
		arrayDctos.map((descuento) => {
			console.log(descuento);
			if (descuento.movimiento == null) {
				let id = contador + 1;
				if (arrayTotal.length > 0) {
					id = arrayTotal.length + 1;
				}
				descuento.movimiento = id;
				var dcto = dctos.find((d) => d.value === descuento.descuento);
				var cta = Puc.find((c) => c.value == dcto.cuenta);
				var deb = 0;
				var cre = 0;
				if (cta.naturaleza == 1) {
					deb = descuento.valor;
				} else if (cta.naturaleza == 2) {
					cre = descuento.valor;
				}
				console.log(cta);
				let asiento = {
					numero: id,
					cuenta: dcto.cuenta,
					debito: deb,
					credito: cre,
					centro: centroS.value,
					docafectado: null,
					ctaSaldo: null,
					saldomov: null,
					codCategoria: null,
					descuento: descuento,
				};
				console.log(asiento);
				arrayTotal = [...arrayTotal, asiento];
			}
			setArrayAsientos(arrayTotal);
			Totales(arrayTotal);
		});
		if (buttonCloseDcto.current) {
			buttonCloseDcto.current.click();
		}
	}

	//---------- meses activos ------
	const getMesesActivos = async () => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};

			const result = await axios.get(
				`${route_configuration_base}/getMonthsActive/${establishment.id}`,
				config
			);
			setMActivos(result.data);
		} catch (error) {
			console.error("Error while fetching options:", error);
		}
	};
	// ---------- funcion que valida si mes y año estan abiertos -----------
	const verificarFecha = (json) => {
		const fechaString = json.fecha;
		const fecha = parseISO(fechaString);
		// Obtener el mes (devuelve un número entre 0 y 11, donde 0 es enero y 11 es diciembre)
		const month = getMonth(fecha) + 1; // Sumamos 1 porque los meses en JavaScript van de 0 a 11
		// Obtener el año
		const year = getYear(fecha);
		// Verificar si el año y el mes están en el array de fechas

		const fechaEnArray = mActivos.find(
			(fecha) => fecha.year == year && fecha.numero == month
		);
		return fechaEnArray ? true : false;
	};

	return (
		<div className="containerFormUser">
			<h1>{titulo}</h1>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
				innerRef={formikRef}
			>
				{(formik, props) => (
					<Form>
						{/* ------------------- Formulario Contabilidad  ---------------------------*/}
						<div className="row">
							{/* terceros */}
							<div className="col-md-6 ">
								<div className="d-flex align-items-center mb-2">
									<div className="flex-grow-1">
										<Select
											styles={customStyles}
											isSearchable={true}
											placeholder="Búsqueda de terceros"
											isClearable={true}
											isLoading={isLoading}
											value={personSelected}
											onInputChange={onInputChange}
											options={optionsPersons}
											onChange={(e) => {
												setPersonSelected(e);
												formik.setFieldValue("tercero", e ? e.value : "");
												setOptionsPersons([]);
												if (
													((tipo == 1 && categoria == 2) ||
														(tipo == 2 && categoria == 2)) &&
													e != null
												) {
													getCtasxCobrar(e.value, tipo, categoria);
												} else {
													setCtasxcobrar([]);
												}
											}}
										/>
										<ErrorMessage
											name="tercero"
											component="div"
											className="error"
										/>
									</div>
									<button
										style={{ margin: "2px" }}
										type="button"
										className="btn btn-primary"
										data-bs-toggle="modal"
										data-bs-target="#modalTercero"
									>
										<i className="fas fa-user-plus ml-2"></i>
									</button>
								</div>
							</div>
							{/* comprobantes */}
							<div className="col-md-6">
								<div className="form-floating mb-2">
									<Select
										styles={customStyles}
										isSearchable="true"
										placeholder="Comprobantes"
										isClearable="true"
										value={comprobanteSel}
										isLoading={isLoading}
										//onInputChange={onInputChange}
										options={comprobantes}
										onChange={(e) => {
											if (e != null && e.value) {
												setComprobanteSel(e);
												formik.setFieldValue("comprobante", e.value);
											}
										}}
									></Select>
									<ErrorMessage
										name="comprobante"
										component="div"
										className="error"
									/>
								</div>
							</div>
							{/* cuentas por cobrar solo disponble para recibs de caja */}
							{(tipo == 1 || tipo == 2) && categoria == 2 ? (
								<div className="col-md-12 ">
									<div className="d-flex align-items-center mb-2">
										<div className="flex-grow-1">
											<Select
												ref={selectRefCtas}
												styles={customStyles}
												isSearchable={true}
												placeholder="Cuentas por cobrar"
												isClearable={true}
												isLoading={isLoading}
												options={ctasxcobrar}
												onChange={(e) => {
													setCtaxCSel(e ? e.value : null);
												}}
											/>
										</div>
										<button
											style={{ margin: "2px" }}
											type="button"
											className="btn btn-success"
											onClick={() => agregarCtaxC()}
										>
											<i className="fas fa-check ml-2"></i>
										</button>
									</div>
								</div>
							) : null}
							{/* Centros de costo */}
							<div className="col-md-6">
								<div className="form-floating mb-2">
									<Select
										styles={customStyles}
										isSearchable="true"
										placeholder="Centros de costo"
										isClearable="true"
										//value={institucionS}
										isLoading={isLoading}
										//onInputChange={onInputChange}
										options={centrosCosto}
										onChange={(e) => {
											if (e != null && e.value) {
												setCentroS(e);
												formik.setFieldValue("centrocosto", e.value);
											} else {
												setCentroS(null);
												formik.setFieldValue("centrocosto", null);
											}
										}}
									></Select>
									<ErrorMessage
										name="centrocosto"
										component="div"
										className="error"
									/>
								</div>
							</div>
							{/* fecha */}
							<div className="col-md-6">
								<div className="form-floating mb-2  ">
									<Field
										style={dateStyles}
										type="date"
										name="fecha"
										id="fecha"
										className="form-control"
										placeholder="Fecha "
										onChange={(e) => {
											formik.setFieldValue("fecha", e.target.value);
										}}
									/>
									<label
										style={{ zIndex: "0" }}
										htmlFor="fecha"
										className="form-label"
									>
										Fecha
									</label>
									<ErrorMessage
										name="fecha"
										component="div"
										className="error"
									/>
								</div>
							</div>
							{/* detalle */}
							<div className="col-md-12  ">
								<div className="form-floating mb-2  ">
									<Field
										style={inputStyles}
										type="textarea"
										name="detalle"
										id="detalle"
										className="form-control"
										placeholder="Detalle"
									/>
									<label
										style={{ zIndex: "0" }}
										htmlFor="observaciones"
										className="form-label"
									>
										Detalle
									</label>
									<ErrorMessage
										name="detalle"
										component="div"
										className="error"
									/>
								</div>
							</div>
						</div>
						{/* DILIGENCIAR VALORES CUENTA */}
						<div className="row" style={{ marginBottom: "-20px" }}>
							<>
								<h2>Movimientos</h2>
								<div className="row">
									<div className="col-md-3  ">
										<div className="form-floating mb-2  ">
											<Select
												ref={selectRef}
												styles={stylePlan}
												isSearchable={true}
												placeholder="Cuenta"
												isClearable={true}
												id="selectCta"
												value={cuentaO}
												options={Puc}
												filterOption={(option, inputValue) => {
													const label = option.label.toLowerCase();
													const input = inputValue.toLowerCase();
		
													// Primero, prioriza las coincidencias que comienzan con el input
													if (label.startsWith(input)) {
														return true;
													}
		
													// Como segunda opción, busca cualquier coincidencia
													return label.includes(input);
												}}
												onChange={(e) => {
													if (e) {
														setCuenta(e.value);
														setCuentaO(e);
													} else {
														setCuenta(null);
														setCuentaO(null);
													}
												}}
											></Select>
										</div>
									</div>
									<div className="col-md-3">
										<div className="form-floating mb-2">
											<Select
												styles={customStyles}
												isSearchable="true"
												placeholder="Centros de costo"
												isClearable="true"
												value={centroS}
												isLoading={isLoading}
												//onInputChange={onInputChange}
												options={centrosCosto}
												onChange={(e) => {
													if (e != null && e.value) {
														setCentroS(e);
														formik.setFieldValue("centrocosto", e.value);
													} else {
														setCentroS(null);
													}
												}}
											></Select>
											<ErrorMessage
												name="centrocosto"
												component="div"
												className="error"
											/>
										</div>
									</div>
									<div className="col-md-2  ">
										<div className="form-floating mb-2  ">
											<NumericFormat
												style={{ ...inputStyles, textAlign: "right" }}
												value={debito}
												className="form-control"
												thousandSeparator="."
												decimalSeparator=","
												//disabled={isEnableDebito}
												onChange={(e) => {
													setDebito(
														parseFloat(
															e.target.value
																.replace(/\./g, "")
																.replace(",", ".")
														)
													);
													if (e.target.value == null) {
														setDebito(0);
													}
												}}
											/>
											<label
												style={{ zIndex: "0" }}
												htmlFor="valorPlan"
												className="form-label"
											>
												Débitos
											</label>
										</div>
										{/* <label
											style={{
												zIndex: "0",
												visibility: verDebito ? "visible" : "hidden",
											}}
											className="text-info font-weight-bold"
										>
											La cuenta es de naturaleza débito
										</label> */}
									</div>
									<div className="col-md-2  ">
										<div className="form-floating mb-2  ">
											<NumericFormat
												style={{
													...inputStyles,
													textAlign: "right",
													zIndex: "0",
												}}
												value={credito}
												className="form-control"
												thousandSeparator="."
												decimalSeparator=","
												//disabled={isEnableCredito}
												onChange={(e) => {
													setCredito(
														parseFloat(
															e.target.value
																.replace(/\./g, "")
																.replace(",", ".")
														)
													);
													if (e.target.value == null) {
														setCredito(0);
													}
												}}
											/>
											<label
												style={{ zIndex: "0" }}
												htmlFor="valorPlan"
												className="form-label"
											>
												Créditos
											</label>
										</div>
										{/* <label
											style={{
												zIndex: "0",
												visibility: verCredito ? "visible" : "hidden",
											}}
											className="text-info font-weight-bold"
										>
											La cuenta es de naturaleza crédito
										</label> */}
									</div>
									<div className="col-md-2" style={{ paddingTop: "15px" }}>
										<button
											onClick={agregarMovimiento}
											type="button"
											style={{ width: "90%" }}
											className="btn btn-primary"
										>
											<FaPlusCircle />
											&nbsp;Agregar
										</button>
									</div>
								</div>
							</>
						</div>
						<>
							<table
								className="table table-striped table-hover"
								style={{ marginTop: "10px", border: 1 }}
							>
								<thead>
									<tr>
										<th style={{ textAlign: "center" }}>Cuenta</th>
										<th style={{ textAlign: "center" }}>Centro</th>
										<th
											style={{
												width: "20%",
												textAlign: "right",
												color: totalDB === totalCR ? "green" : "red",
											}}
										>
											Débitos&nbsp;{"($" + totalDB.toLocaleString(0) + ")"}{" "}
											{difDeb != 0
												? "(-$" + difDeb.toLocaleString(0) + ")"
												: ""}
										</th>
										<th
											style={{
												width: "20%",
												textAlign: "right",
												color: totalDB === totalCR ? "green" : "red",
											}}
										>
											Créditos&nbsp;{"($" + totalCR.toLocaleString(0) + ")"}{" "}
											{difCre != 0
												? "(-$" + difCre.toLocaleString(0) + ")"
												: ""}
										</th>
										<th style={{ width: "20%", textAlign: "right" }}>
											Opciones
										</th>
									</tr>
								</thead>
							</table>
							<div style={{ maxHeight: "200px", overflowY: "auto" }}>
								<table className="table table-striped table-hover">
									<tbody>
										{arrayAsientos.map((transaccion, index) => {
											const cuenta = Puc.find(
												(opcion) => opcion.value === transaccion.cuenta
											);

											const centro = centrosCosto.find(
												(opcion) => opcion.value === transaccion.centro
											);

											return (
												<tr key={index}>
													<td style={{ textAlign: "left" }}>
														{cuenta ? cuenta.label : "No encontrado"}
													</td>
													<td style={{ textAlign: "left" }}>
														{centro ? centro.label : "No encontrado"}
													</td>

													<td style={{ width: "20%", textAlign: "right" }}>
														<NumericFormat
															value={transaccion.debito} // El número que deseas formatear
															displayType={"text"} // Puedes usar 'input' si deseas que sea un campo de entrada
															thousandSeparator="."
															decimalSeparator="," // Agregar separadores de miles
															decimalScale={2} // Especificar el número de decimales
															prefix={"$"} // Agregar un prefijo, como el símbolo de la moneda
														/>
													</td>
													<td style={{ width: "20%", textAlign: "right" }}>
														<NumericFormat
															value={transaccion.credito} // El número que deseas formatear
															displayType={"text"} // Puedes usar 'input' si deseas que sea un campo de entrada
															thousandSeparator="."
															decimalSeparator="," // Agregar separadores de miles
															decimalScale={2} // Especificar el número de decimales
															prefix={"$"} // Agregar un prefijo, como el símbolo de la moneda
														/>
													</td>
													<td style={{ width: "20%", textAlign: "right" }}>
														<button
															className="btnDelete"
															type="button"
															onClick={() => eliminarMov(transaccion.numero)}
														>
															<i className="fas fa-trash-alt"></i>
														</button>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
							{/* ------------------------------------------------------------ */}
						</>
						{btnCarga === 0 ? (
							<>
								<button
									type="button"
									className="btn btn-info"
									data-bs-toggle="modal"
									data-bs-target="#modalDctos"
									disabled={centroS == null}
								>
									Descuentos
								</button>
								&nbsp;
								<button
									disabled={isDisabled} // Usamos una expresión para habilitar o deshabilitar el botón
									type="submit"
									className="btn btn-primary"
								>
									Guardar
								</button>
							</>
						) : (
							/* spinner carga */
							<button className="btn btn-primary" type="button" disabled>
								<span
									className="spinner-border spinner-border-sm"
									aria-hidden="true"
								></span>
								<span role="status"> Guardando...</span>
							</button>
							/*--------------- */
						)}
						&nbsp;
						<NavLink
							className="navInfo"
							to={{
								pathname:
									"/menu/hefestoContabilidad/lista-movimientos/" +
									tipo +
									"/" +
									categoria,
							}}
						>
							<button className="btn btn-danger">Cancelar</button>
						</NavLink>
						&nbsp;
					</Form>
				)}
			</Formik>

			{/* modal terceros */}
			<div
				className="modal fade"
				id="modalTercero"
				tabindex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="exampleModalLabel">
								Terceros
							</h1>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								ref={closeModal}
							></button>
						</div>
						<div className="modal-body">
							<RegTerceros ban={1} closeModal={closeModal} />
						</div>
					</div>
				</div>
			</div>

			{/* ----- modal descuentos ------ */}

			<div
				className="modal fade"
				id="modalDctos"
				tabindex="-1"
				aria-hidden="true"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				aria-labelledby="staticBackdropLabel"
			>
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="exampleModalLabel">
								Descuentos
							</h1>
						</div>
						<div className="modal-body">
							{/* formulario descuentos */}
							<Formik
								initialValues={initialValuesDcto}
								validationSchema={validationSchemaDcto}
								onSubmit={handleSubmitDcto}
								//innerRef={formikRef}
							>
								{(formikDcto, props) => (
									<Form>
										<div className="row">
											{/* descuento */}
											<div className="col-md-5 ">
												<div className="d-flex align-items-center mb-2">
													<div className="flex-grow-1">
														<Select
															styles={customStyles}
															ref={closeDctoRef}
															isSearchable={true}
															placeholder="Descuentos"
															isClearable={true}
															value={dctoSelected}
															options={dctos}
															onChange={(e) => {
																setDctoSelected(e);
																calcularDcto(0);
																formikDcto.setFieldValue(
																	"descuento",
																	e ? e.value : ""
																);
															}}
														/>
														<ErrorMessage
															name="descuento"
															component="div"
															className="error"
														/>
													</div>
												</div>
											</div>
											{/* base */}
											<div className="col-md-2  ">
												<div className="form-floating mb-2  ">
													<NumericFormat
														style={{ ...inputStyles, textAlign: "right" }}
														value={base}
														className="form-control"
														thousandSeparator="."
														decimalSeparator=","
														//disabled={isEnableDebito}
														onChange={(e) => {
															setBase(
																parseFloat(
																	e.target.value
																		.replace(/\./g, "")
																		.replace(",", ".")
																)
															);
															calcularDcto(
																parseFloat(
																	e.target.value
																		.replace(/\./g, "")
																		.replace(",", ".")
																)
															);
															formikDcto.setFieldValue(
																"base",
																parseFloat(
																	e.target.value
																		.replace(/\./g, "")
																		.replace(",", ".")
																)
															);
														}}
													/>
													<label
														style={{ zIndex: "0" }}
														htmlFor="base"
														className="form-label"
													>
														Base
													</label>
													<ErrorMessage
														name="base"
														component="div"
														className="error"
													/>
												</div>
											</div>
											{/* Porcentaje */}
											<div className="col-md-2  ">
												<div className="form-floating mb-2  ">
													<NumericFormat
														style={{ ...inputStyles, textAlign: "right" }}
														value={porcentaje}
														className="form-control"
														thousandSeparator="."
														decimalSeparator=","
														disabled={true}
														onChange={(e) => {
															setPorcentaje(
																parseFloat(
																	e.target.value
																		.replace(/\./g, "")
																		.replace(",", ".")
																)
															);
															formikDcto.setFieldValue(
																"porcentaje",
																parseFloat(
																	e.target.value
																		.replace(/\./g, "")
																		.replace(",", ".")
																)
															);
														}}
													/>
													<label
														style={{ zIndex: "0" }}
														htmlFor="base"
														className="form-label"
													>
														Porcentaje
													</label>
													<ErrorMessage
														name="porcentaje"
														component="div"
														className="error"
													/>
												</div>
											</div>
											{/* Valor */}
											<div className="col-md-2  ">
												<div className="form-floating mb-2  ">
													<NumericFormat
														style={{ ...inputStyles, textAlign: "right" }}
														value={valor}
														className="form-control"
														thousandSeparator="."
														decimalSeparator=","
														disabled={true}
														onChange={(e) => {
															setValor(
																parseFloat(
																	e.target.value
																		.replace(/\./g, "")
																		.replace(",", ".")
																)
															);
															formikDcto.setFieldValue(
																"valor",
																parseFloat(
																	e.target.value
																		.replace(/\./g, "")
																		.replace(",", ".")
																)
															);
														}}
													/>
													<label
														style={{ zIndex: "0" }}
														htmlFor="base"
														className="form-label"
													>
														Valor
													</label>
													<ErrorMessage
														name="valor"
														component="div"
														className="error"
													/>
												</div>
											</div>
											{/* boton */}
											<div className="col-md-1  ">
												<button
													//onClick={agregarMovimiento}
													type="submit"
													style={{ width: "100%" }}
													className="btn btn-primary"
												>
													<FaPlusCircle />
												</button>
											</div>
											{/* --------------- */}
										</div>
									</Form>
								)}
							</Formik>
							{/* -------- Listado de descuento ----------- */}
							<div class="card">
								<div class="card-body">
									<table className="table table-striped table-hover">
										<thead>
											<tr>
												<th>Concepto</th>
												<th>Base</th>
												<th>Porcentaje</th>
												<th>Valor</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{arrayDctos.map((dcto, index) => {
												// Encuentra el descuento correspondiente
												const dctoS = dctos.find(
													(d) => d.value === dcto.descuento
												);
												const dctoLabel = dctoS ? dctoS.label : ""; // Maneja el caso en que no se encuentre el dcto

												return (
													<tr key={index}>
														<td>{dctoLabel}</td>
														<td>{dcto.base}</td>
														<td>{dcto.porcentaje}</td>
														<td>{dcto.valor}</td>
														<td style={{ width: "20%", textAlign: "right" }}>
															<button
																className="btnDelete"
																type="button"
																onClick={() => {
																	const nuevoArray = arrayDctos.filter(
																		(item) => item.numero !== dcto.numero
																	);
																	setArrayDctos(nuevoArray);
																	const nuevoArray2 = arrayAsientos.filter(
																		(item) => item.numero !== dcto.movimiento
																	);
																	setArrayAsientos(nuevoArray2);
																	Totales(nuevoArray2);
																}}
															>
																<i className="fas fa-trash-alt"></i>
															</button>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button
								ref={buttonCloseDcto}
								type="button"
								className="btn btn-danger"
								data-bs-dismiss="modal"
								onClick={() => {
									const nuevoArray = arrayDctos.filter(
										(item) => item.movimiento != null
									);
									setArrayDctos(nuevoArray);
								}}
							>
								Cancelar
							</button>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => aplicarDctos()}
							>
								Aplicar
							</button>
						</div>
					</div>
				</div>
			</div>
			{/* --------------------- */}
		</div>
	);
}
