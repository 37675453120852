import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { GrOrderedList } from "react-icons/gr";
import { VscFileSubmodule } from "react-icons/vsc";
import { GiReceiveMoney } from "react-icons/gi";

export default function ConfPresupuesto() {
	const navigate = useNavigate();
	const [usuariosOptionsVisible, setUsuariosOptionsVisible] = useState(false);

	useEffect(() => {
		navigate("/menu/hefestoContabilidad");
	}, []);
	const toggleUsuariosOptions = () => {
		setUsuariosOptionsVisible(!usuariosOptionsVisible);
	};

	return (
		<>
			<div id="space">
				<div className="container1 container-fluid">
					<div className="menu">
						<div className="divMenuLog">
							<ul>
								<NavLink className="navInfo" to="crear-puc">
									<li className="navInfoAdmin">
										<GrOrderedList /> <span>Plan de cuentas</span>
									</li>
								</NavLink>
							</ul>
							<ul>
								<NavLink className="navInfo" to="crear-centroscosto">
									<li className="navInfoAdmin">
										<VscFileSubmodule /> <span>Centros de costo</span>
									</li>
								</NavLink>
							</ul>
							{/* bloque Comprobantes */}
							<ul>
								<NavLink className="navInfo" to="#">
									<li className="navInfoAdmin" onClick={toggleUsuariosOptions}>
										<i class="far fa-folder-open"></i>{" "}
										<span>
											Comprobantes&nbsp;
											{usuariosOptionsVisible === false ? (
												<i class="fas fa-chevron-down"></i>
											) : (
												<i class="fas fa-chevron-up"></i>
											)}{" "}
										</span>
									</li>
								</NavLink>
								{usuariosOptionsVisible && (
									<ul>
										<NavLink
											className="navInfo"
											to={{
												pathname:
													"/menu/hefestoContabilidad/crear-comprobantes/1",
											}}
										>
											<li className="navSubmenu">
												<i class="fas fa-file-signature"></i>{" "}
												<span> Ingresos</span>
											</li>
										</NavLink>

										<NavLink
											className="navInfo"
											to={{
												pathname:
													"/menu/hefestoContabilidad/crear-comprobantes/2",
											}}
										>
											<li className="navSubmenu">
												<i class="fas fa-file-signature"></i>{" "}
												<span> Gastos</span>
											</li>
										</NavLink>
										<NavLink
											className="navInfo"
											to={{
												pathname:
													"/menu/hefestoContabilidad/crear-comprobantes/3",
											}}
										>
											<li className="navSubmenu">
												<i class="fas fa-file-signature"></i>{" "}
												<span> Contables</span>
											</li>
										</NavLink>
									</ul>
								)}
							</ul>
							<ul>
								<NavLink className="navInfo" to="crear-descuentos">
									<li className="navInfoAdmin">
										<i class="fas fa-hand-holding-usd"></i>{" "}
										<span>Descuentos</span>
									</li>
								</NavLink>
							</ul>
						</div>
					</div>
					<div className="workspace">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}
