import React, { useState } from 'react';
import Componente1 from './PucManual';
import Componente2 from './PucPlano';

const App = () => {
  const [activeComponent, setActiveComponent] = useState('Componente1');

  const PucContabilidad = () => {
    switch (activeComponent) {
      case 'Componente1':
        return <Componente1 />;
      case 'Componente2':
        return <Componente2 />;
      default:
        return <Componente1 />;
    }
  };

  return (
    <div className="containerFormUser">
     
	 <ul class="nav nav-tabs">

        <li className="nav-item">
          <button className="nav-link" href="#" onClick={() => setActiveComponent('Componente1')}>
            PUC Manual
          </button>
        </li>
        <li className="nav-item">
          <button className="nav-link" href="#" onClick={() => setActiveComponent('Componente2')}>
            PUC Plano
          </button>
        </li>
      </ul>
      {PucContabilidad()}
    </div>
  );
};

export default App;
