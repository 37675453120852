import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import { AuthContext } from "../../context/LogContext";
import { route_contabconf_base } from "../../../api-routes/RoutesLogin";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Select from "react-select";
import Formato1 from "../pdfs/Formato1";
import { MdFindInPage } from "react-icons/md";

const Comprobantes = () => {
	const { getToken, inputStyles, customStyles, establishment } =
		useContext(AuthContext);
	const [comprobantes, setComprobantes] = useState([]);
	const [comprobantesxC, setComprobantesxC] = useState([]);
	const btnCancel = useRef(null);
	const formikRef = useRef(null);
	const [CategoriaSelected, setCategoriaSelected] = useState(null);
	const [OptionsCategoria, setOptionsCategoria] = useState([]);
	const [tipoSelected, setTipoSelected] = useState(null);
	const [btnCarga, setBtnCarga] = useState(0);
	const [banCarga, setBanCarga] = useState(0);
	const { tipo } = useParams();
	const [titulo, setTitulo] = useState("");
	const selectRef = useRef();
	const selectRef2 = useRef();
	const [optionsFormato, setOptionsFormato] = useState([]);
	const [formatoSelected, setFormatoSelected] = useState({});
	const [movimientoPDF, setMovimientoPDF] = useState({
		id: 22,
		identificaciont: "1098765432",
		tipoid: "PASAPORTE",
		nombret: "Juan Carlos Mendoza Herrera",
		pais: "MEXICO",
		depto: "JALISCO",
		mupio: "GUADALAJARA",
		direccion: "Av. Vallarta 1234",
		correo:"correo@correo.com",
		telefono:"123456",
		numero: "20240025",
		comprobante: "Comprobante",
		fecha: "2024-09-15",
		detalle: "Pago de servicios",
		debitos: 2500,
		creditos: 2500,
		nombreCrea:"Francisco Arellano",
		detalles: [
			{
				nombrecta: "Banco BBVA",
				codigocta: "112505",
				codigocc: "001",
				nombrecc: "centro costo  3",
				debito: 0,
				credito: 2500,
				naturaleza: 1,
				tipo: 1,
			},
			{
				nombrecta: "Proveedores Nacionales",
				codigocta: "220505",
				codigocc: "002",
				nombrecc: "centro costo  3",
				debito: 2500,
				credito: 0,
				naturaleza: 2,
				tipo: 1,
			},
		],
	});
	const [formatosActivos, setFormatosActivos] = useState([
		{
			key: "Formato1",
			formato: (
				<Formato1
					style={{ width: "100%" }}
					establishment={establishment}
					movimiento={movimientoPDF}
				/>
			),
		},
	]);
	const [pdfSelected, setPdfSelected] = useState(null);

	const handleSubmit = (values) => {
		setBtnCarga(1);
		saveComprobante(values);
	};

	const saveComprobante = async (json) => {
		json.user = parseInt(localStorage.getItem("idUser"));
		json.tipo = tipoSelected;
		if (tipoSelected == 3) {
			json.categoria = 1;
		}
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(
				route_contabconf_base + "/saveComprobante",
				json,
				config
			);
			const successMessage = "Comprobante contable creado";

			Swal.fire({
				icon: "success",
				title: successMessage,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				setBtnCarga(0);
				btnCancel.current.click();
				getComprobantes();
				if (selectRef.current) {
					selectRef.current.clearValue(); // Llama al método clearValue para limpiar el Select
				}
				if (selectRef2.current) {
					selectRef2.current.clearValue(); // Llama al método clearValue para limpiar el Select
				}
			});
		} catch (error) {
			if (error.response && error.response.status === 403) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Verifique los datos",
					showConfirmButton: true,
				}).then(() => {});
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error de red, contactese con su proveedor",
					showConfirmButton: true,
				}).then(() => {});
			}
		}
	};
	const getComprobantes = async () => {
		setBanCarga(1);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_contabconf_base}/getComprobantes/${tipo},${establishment.id}`,
			config
		);

		setComprobantes(result.data);
		setComprobantesxC([]);
		if (tipoSelected == 3) {
			setComprobantesxC(comprobantes.filter((c) => c.categoria == 1));
		}

		if (selectRef.current) {
			selectRef.current.clearValue(); // Llama al método clearValue para limpiar el Select
		}
		if (selectRef2.current) {
			selectRef2.current.clearValue(); // Llama al método clearValue para limpiar el Select
		}
		setBanCarga(0);
	};

	const handleServiceEdit = (conceptoId) => {
		const conceptoDet = comprobantes.find((p) => p.id === conceptoId);
		if (formikRef.current) {
			formikRef.current.setValues(conceptoDet);
			var tipoSel = OptionsCategoria.find(
				(option) => option.value === conceptoDet.categoria
			);
			setCategoriaSelected(tipoSel);
			setFormatoSelected({
				value: conceptoDet.formato,
				label: conceptoDet.formato,
			});
		}
	};
	const handleDelete = (Id) => {
		Swal.fire({
			title: "Eliminar comprobante?",
			text: "El comprobante no debe estar en uso!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				deleteServicio(Id);
			}
		});
	};
	const deleteServicio = async (Id) => {
		try {
			let user = localStorage.getItem("idUser");
			const response = await axios.delete(
				route_contabconf_base + "/deleteComprobante/" + Id + "," + user,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			if (response.data == true) {
				Swal.fire({
					icon: "success",
					title: "El comprobante se ha eliminado correctamente",
					showConfirmButton: false,
					timer: 1500,
				}).then(() => {
					getComprobantes();
				});
			} else {
				Swal.fire({
					icon: "warning",
					title: "Error, Verifique si el concepto no ha sido aplicado",
					showConfirmButton: false,
					timer: 1500,
				});
			}
		} catch (error) {
			throw error;
		}
	};
	const activarCta = async (id, checked) => {
		let comp = comprobantes.find((c) => c.id === id);
		comp.status = checked;
		comp.user = parseInt(localStorage.getItem("idUser"));
		try {
			const response = await axios.post(
				route_contabconf_base + "/saveComprobante",
				comp,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			Swal.fire({
				icon: "success",
				title: "Comprobante modificado",
				showConfirmButton: false,
				timer: 1500,
			});
			getComprobantes();
		} catch (error) {
			Swal.fire({
				icon: "warning",
				title: "Hubo un error en el proceso",
				showConfirmButton: false,
				timer: 1500,
			});
			getComprobantes();
		}
	};
	useEffect(() => {
		setTipoSelected(tipo);
		setComprobantes([]);
		setOptionsFormato([{ label: "Formato1", value: "Formato1" }]);

		if (selectRef.current) {
			selectRef.current.clearValue(); // Llama al método clearValue para limpiar el Select
		}
		/* if (selectRef2.current) {
			selectRef2.current.clearValue(); // Llama al método clearValue para limpiar el Select
		} */
		if (tipo == 1) {
			setTitulo("Comprobantes de ingreso");
			setOptionsCategoria([
				{ label: "Cuentas por cobrar", value: 1 },
				{ label: "Recibo de caja", value: 2 },
			]);
		} else if (tipo == 2) {
			setTitulo("Comprobantes de Gastos");
			setOptionsCategoria([
				{ label: "Cuentas por pagar", value: 1 },
				{ label: "Comprobante de egreso", value: 2 },
			]);
		} else if (tipo == 3) {
			setTitulo("Comprobantes Contables");
			setOptionsCategoria([{ label: "Contables", value: 1 }]);
		}
		getComprobantes();
	}, [tipo]);
	const validationSchema = Yup.object().shape({
		nombre: Yup.string().required().typeError("Campo obligatorio"),
		categoria: Yup.number().required().typeError("Campo obligatorio"),
		formato: Yup.string().required().typeError("Campo obligatorio"),
	});

	const initialValues = {
		id: "",
		nombre: "",
		categoria: null,
		tipo: null,
		status: true,
		establishment: establishment.id,
		formato: "",
	};

	return (
		<div className="containerFormUser">
			<h1>{titulo}</h1>

			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
				innerRef={formikRef}
			>
				{(formik, props) => (
					<Form>
						<div className="row">
							{/*------ BLOQUE1 ------- */}

							<div className="col-md-4  ">
								<div className="form-floating mb-2  ">
									<Select
										isClearable={true}
										ref={selectRef}
										styles={customStyles}
										value={CategoriaSelected}
										placeholder="Categoria"
										onChange={(e) => {
											if (e) {
												formik.setFieldValue("categoria", e.value);
												setCategoriaSelected(e);
											} else {
												formik.setFieldValue("categoria", null);
												setCategoriaSelected(null);
											}
										}}
										options={OptionsCategoria}
									/>
									<ErrorMessage
										name="categoria"
										component="div"
										className="error"
									/>
								</div>
							</div>

							<div className="col - 3">
								<div className="form-floating mb-2  ">
									<Field
										style={inputStyles}
										type="text"
										name="nombre"
										id="nombre"
										className="form-control"
										placeholder="Nombre"
									/>
									<label htmlFor="forma" className="form-label">
										Nombre
									</label>
									<ErrorMessage
										name="nombre"
										component="div"
										className="error"
									/>
								</div>
							</div>

							<div className="col-md-3  ">
								<div className="form-floating mb-2  ">
									<Select
										isClearable={true}
										ref={selectRef}
										styles={customStyles}
										value={formatoSelected}
										placeholder="Formato"
										onChange={(e) => {
											if (e) {
												formik.setFieldValue("formato", e.value);
												setFormatoSelected(e);
											} else {
												formik.setFieldValue("formato", null);
												setFormatoSelected(null);
											}
										}}
										options={optionsFormato}
									/>
									<ErrorMessage
										name="formato"
										component="div"
										className="error"
									/>
								</div>
							</div>
							{/* -------------------- */}

							<div className="col-md-2  ">
								{btnCarga === 0 ? (
									<button type="submit" className="btn btn-primary">
										Guardar
									</button>
								) : (
									/* spinner carga */
									<button className="btn btn-primary" type="button" disabled>
										<span
											className="spinner-border spinner-border-sm"
											aria-hidden="true"
										></span>
										<span role="status"> Guardando...</span>
									</button>
									/*--------------- */
								)}
								&nbsp;
								<button type="reset" ref={btnCancel} className="btn btn-danger">
									Cancelar
								</button>
							</div>
							{/* ------------------- */}
						</div>
					</Form>
				)}
			</Formik>
			<br></br>
			<h1>Lista de conceptos</h1>

			<div className="row">
				<div className="col-md-12  ">
					<div className="form-floating mb-2  ">
						<Select
							isClearable={true}
							styles={customStyles}
							ref={selectRef2}
							placeholder="Tipo"
							onChange={(e) => {
								if (e) {
									setComprobantesxC(
										comprobantes.filter((c) => c.categoria == e.value)
									);
								} else {
									setComprobantesxC([]);
								}
							}}
							options={OptionsCategoria}
						/>
					</div>
				</div>
			</div>
			{banCarga === 0 ? (
				<table
					style={{ width: "100%" }}
					id="tableUsers"
					className="table table-striped table-hover"
				>
					<thead>
						<tr>
							<th style={{ width: "60%", textAlign: "center" }}>Nombre</th>
							<th style={{ width: "20%", textAlign: "right" }}>Código</th>
							<th style={{ width: "20%", textAlign: "right" }}>Opciones</th>
						</tr>
					</thead>
					<tbody>
						{comprobantesxC.map((concepto) => (
							<tr key={concepto.id}>
								<td>{concepto.nombre} </td>
								<td style={{ textAlign: "right" }}>{concepto.codigo}</td>
								<td style={{ width: "20%", textAlign: "right" }}>
									<div className="row">
										<div className="col-4"></div>

										<div className="col-2">
											<>
												<div class="form-check form-switch">
													<input
														className="form-check-input"
														type="checkbox"
														role="switch"
														id="flexSwitchCheckDefault"
														checked={concepto.status}
														onChange={(e) =>
															activarCta(concepto.id, e.target.checked)
														}
													/>
												</div>
											</>
										</div>

										<div className="col-6">
											<button
												className="btnInfo"
												type="button"
												data-bs-toggle="modal"
												data-bs-target="#modalPdf"
												onClick={() => {
													let formato = formatosActivos.find(
														(f) => f.key == concepto.formato
													);
													setPdfSelected(formato.formato);
												}}
											>
												<MdFindInPage />
											</button>
											&nbsp;
											<button
												className="btnEdit"
												type="button"
												onClick={(e) => handleServiceEdit(concepto.id)}
											>
												<i className="fas fa-pen"></i>
											</button>
											&nbsp;&nbsp;
											<button
												className="btnDelete"
												type="button"
												onClick={() => handleDelete(concepto.id)}
											>
												<i className="fas fa-trash-alt"></i>
											</button>
										</div>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			) : (
				/* spinner carga */
				<button className="btn btn-primary" type="button" disabled>
					<span
						className="spinner-border spinner-border-sm"
						aria-hidden="true"
					></span>
					<span role="status"> Cargando...</span>
				</button>
				/*--------------- */
			)}
			{/* Modal recibo   */}
			<div
				className="modal fade"
				id="modalPdf"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabindex="-1"
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="staticBackdropLabel">
								Comprobante
							</h1>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>

						<div className="modal-body">
							{/* <Formato1
								style={{ width: "100%" }}
								establishment={establishment}
								movimiento={movimientoPDF}
							/> */}
							{pdfSelected}
						</div>
					</div>
				</div>
			</div>
			{/* --------------- */}
		</div>
	);
};

export default Comprobantes;
