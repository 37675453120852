import "../../../styles/administration/NavBarAdmin.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import axios from "axios";
import { NavLink } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
/**Procesos admin/ */

import Users from "../users/Users";
import Settings from "../settings/Settings";
import Register from "../../persons/register/Register";
import InformesAdministracion from "../../administration/informes/BaseInformesAdmin";
import { AuthContext } from "../../context/LogContext";
import { route_options_listByModule } from "../../../api-routes/RoutesLogin";
import ProcesosAdmin from "../process/Procesos";
/*----------DASHBOARDS -------------------------*/
import DashBoardAdmin from '../home/DashboardAdmin'
import DashBoardPersons from '../../persons/DashboardPersons'
import DashBoardPresupuesto from '../../presupuesto/DashboardPresupuesto'
import DashBoardGym from '../../facturacionGym/Dashboardgym'

/* compponents gym */
import ConfiguracionGym from "../../facturacionGym/configuracion/configuracionGym";
import SuscripcionesGym from "../../facturacionGym/suscripcion/SuscripcionesGym";
import PagosGym from "../../facturacionGym/pagos/BasePagos";
import InformesGym from "../../facturacionGym/informes/BaseInformesGym";
/* Componentes presupuesto */
import ConfiguracionPresupuesto from "../../presupuesto/configuracion/configuracionPresupuesto";
import ProcesosPresupuesto from "../../presupuesto/procesos/procesosPresupuesto";
import MovimientosPresupuesto from "../../presupuesto/movimientos/movimientosPresupuesto";
import InformesPresupuesto from "../../presupuesto/Informes/BaseInformesPre";
/*Componentes Contabilidad*/
import DashBoardContabilidad from '../../contabilidad/DashboardContabilidad'
import ConfiguracionContabilidad from '../../contabilidad/configuracion/configuracionContabilidad.jsx'
import ProcesosContabilidad from '../../contabilidad/procesos/procesosContabilidad.jsx'
import MovimientosContabilidad from '../../contabilidad/movimientos/movimiientosContabilidad.jsx'
import InformesContabilidad from '../../contabilidad/informes/BaseInformesCont.jsx'

export function NavBarAdmin(props) {
	const { getToken,setBanVisualizar } = useContext(AuthContext);
	const [showArea, setShowArea] = useState(null);
	const [loading, setLoading] = useState(true);
	const [options, setOptions] = useState([]);
	const [arrayOptions, setArrayOptions] = useState({});
	const [permission, setPermission] = useState({});

	function mostrarComponente(Component) {
		setShowArea(arrayOptions[Component]);
	}

	if (showArea !== null) {
		var resp = null;
		const modalRoot = document.getElementById("workArea");
		console.log(showArea);
		resp = ReactDOM.createPortal(<div>{showArea}</div>, modalRoot);
	} else {
		resp = null;
	}
	useEffect(() => {
		
		axios
			.get(route_options_listByModule + "/" + props.module, {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			})
			.then((response) => {
				console.log(response.data);
				setOptions(response.data);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
			});

		setArrayOptions({
			Users: <Users />,
			Settings: <Settings />,
			RegisterPerson: <Register />,
			ConfiguracionGym: <ConfiguracionGym />,
			AdminGym: <SuscripcionesGym />,
			PagosGym: <PagosGym />,
			InformesGym: <InformesGym />,
			configuracionPresupuesto: <ConfiguracionPresupuesto />,
			ProcesosAdmin: <ProcesosAdmin />,
			ProcesosPresupuesto: <ProcesosPresupuesto />,
			movimientosPresupuesto: <MovimientosPresupuesto />,
			InformesPresupuesto: <InformesPresupuesto />,
			InformesAdministracion: <InformesAdministracion />,
			ConfiguracionContabilidad:<ConfiguracionContabilidad />,
			ProcesosContabilidad:<ProcesosContabilidad/>,
			MovimientosContabilidad:<MovimientosContabilidad/>,
			InformesContabilidad:<InformesContabilidad/>
		});
		setPermission(JSON.parse(localStorage.getItem("permissions")));
		if(props.module==1){
			setShowArea(<DashBoardAdmin/>)
			setBanVisualizar(0)
		}else if(props.module==3){
			setShowArea(<DashBoardPersons/>)
			setBanVisualizar(0)
		}else if(props.module==4){
			setShowArea(<DashBoardPresupuesto/>)
			setBanVisualizar(0)
		}else if(props.module==2){
			setShowArea(<DashBoardGym/>)
			setBanVisualizar(0)
		}else if(props.module==5){
			setShowArea(<DashBoardContabilidad/>)
			setBanVisualizar(0)
		}
	}, []);
	return (
		<>
			<div>{resp}</div>
			<div className="container-fluid  ">
				<nav className="navbar1">
					<ul>
						{!loading ? (
							options.map((option) => (
								<>
									{(permission.options.includes(option.id) ||
										permission.admin == true) && (
										<NavLink
											className="navInfo"
											key={"L" + option.id}
											onClick={() => {
												mostrarComponente(option.component);
											}}
										>
											<li key={option.id} className="">
												{option.option}
											</li>
										</NavLink>
									)}
								</>
							))
						) : (
							<center>
								<div className="spinner-border text-primary" role="status">
									<span className="visually-hidden">Loading...</span>
								</div>
							</center>
						)}
					</ul>
				</nav>
				<button
					className="btnLogs"
					type="button"
					data-bs-toggle="offcanvas"
					data-bs-target="#offcanvasRight"
					aria-controls="offcanvasRight"
				>
					<i className="fas fa-user-check"></i>
				</button>
			</div>
			
		</>
	);
}
