import React, { useEffect, useState } from "react";
import {
	PDFViewer,
	Document,
	Page,
	Text,
	View,
	StyleSheet,
	Font,
	Image,
} from "@react-pdf/renderer";

function ComprobantePagoPDF({ movimiento, establishment }) {
	const [sumDctos, setSumDctos] = useState(0);
	const [arrayDctos, setArrayDctos] = useState([]);
	useEffect(() => {
		console.log(movimiento);
		const sumaValorTotal = movimiento.listDctos
			? movimiento.listDctos.reduce((total, row) => total + row.valorTotal, 0)
			: 0;
		setSumDctos(sumaValorTotal);
		console.log(movimiento.listDctos);
		if (movimiento.listDctos && movimiento.listDctos.length > 0) {
			const dctosAgrup = agruparDescuentos(movimiento.listDctos);
			setArrayDctos(Object.values(dctosAgrup));
		}
	}, [movimiento]);

	function agruparDescuentos(descuentos) {
		return descuentos.reduce((acc, curr) => {
			if (!acc[curr.dcto]) {
				acc[curr.dcto] = {
					...curr,
					valorAplicado: 0,
					valorTotal: 0,
				};
			}

			acc[curr.dcto].valorAplicado += curr.valorAplicado;
			acc[curr.dcto].valorTotal += curr.valorTotal;

			return acc;
		}, {});
	}

	const styles = StyleSheet.create({
		page: {
			padding: 30,
		},
		title: {
			fontSize: 18,
			marginBottom: 5,
			textAlign: "center",
			fontFamily: "Helvetica",
		},
		header: {
			textAlign: "center",
			fontSize: 8,
			//marginBottom: 5,
			fontFamily: "Helvetica",
		},
		tableNo: {
			position: "absolute",
			top: 62, // Ajusta este valor según sea necesario
			right: 26, // Ajusta este valor según sea necesario
			width: "150px",
			textAlign: "center",
			fontSize: 8,
			padding: 2,
			//backgroundColor: "#E5E5E5",
			borderWidth: 1,
			borderColor: "#000000",
			fontFamily: "Helvetica",
		},
		tableFecha: {
			position: "absolute",
			top: 85, // Ajusta este valor según sea necesario
			right: 26, // Ajusta este valor según sea necesario
			width: "150px",
			textAlign: "left",
			fontSize: 8,
			padding: 5,
			//backgroundColor: "#E5E5E5",
			borderWidth: 1,
			borderColor: "#000000",
			fontFamily: "Helvetica",
		},
		containerDatos: {
			position: "absolute",
			top: 130, // Ajusta este valor según sea necesario
			left: 40,
			width: "100%",
		},
		containerDetalle: {
			/* position: "absolute",
			top: 290, // Ajusta este valor según sea necesario
			left: 10, */
			width: "100%",
		},

		tableRow: {
			display: "flex",
			flexDirection: "row",
		},
		tableTitle: {
			flex: 1,
			padding: 5,
			fontSize: 12,
			fontWeight: "bold",
			width: "100%",
			maxWidth: "100%",
			fontSize: "10",
			borderWidth: 0.5,
			borderColor: "#000000",
			backgroundColor: "#E5E5E5",
			fontFamily: "Helvetica",
		},
		tableTitle25: {
			flex: 1,
			padding: 5,
			fontSize: 12,
			fontWeight: "bold",
			width: "25%",
			maxWidth: "25%",
			fontSize: "10",
			borderWidth: 0.5,
			borderColor: "#000000",
			backgroundColor: "#E5E5E5",
			fontFamily: "Helvetica",
		},
		tableTitleNumber: {
			flex: 1,
			padding: 5,
			fontSize: 12,
			fontWeight: "bold",
			width: "50px",
			maxWidth: "80px",
			fontSize: "10",
			borderWidth: 0.5,
			borderColor: "#000000",
			backgroundColor: "#E5E5E5",
			fontFamily: "Helvetica",
		},
		tableTitle1: {
			flex: 1,
			padding: 5,
			fontSize: 12,
			fontWeight: "bold",
			width: "25%",
			maxWidth: "25%",
			borderWidth: 1,
			borderColor: "#000000",
			backgroundColor: "#E5E5E5",
			fontFamily: "Helvetica",
		},
		tableTitle2: {
			flex: 1,
			padding: 5,
			fontSize: 12,
			fontWeight: "bold",
			width: "90%",
			maxWidth: "90%",
			borderWidth: 1,
			borderColor: "#000000",
			backgroundColor: "#E5E5E5",
			fontFamily: "Helvetica",
		},
		title2: {
			textAlign: "center",
			flex: 2,
			padding: 5,
			fontSize: 10,
			fontWeight: "bold",
			width: "100%",
			maxWidth: "100%",
			borderWidth: 1,
			borderColor: "#000000",
			backgroundColor: "#E5E5E5",
			fontFamily: "Helvetica",
		},
		tableCell: {
			flex: 1,
			fontSize: 9,
			padding: 5,
			width: "100%",
			borderWidth: 0.8,
			borderColor: "#000000",
			fontFamily: "Helvetica",
		},
		tableCellSmall: {
			flex: 1,
			fontSize: 9,
			padding: 5,
			width: "50px",
			maxWidth: "80px",
			borderWidth: 0.8,
			borderColor: "#000000",
			fontFamily: "Helvetica",
		},
		tableCellDetalle: {
			flex: 1,
			fontSize: 9,
			padding: 5,
			width: "100%",
			borderWidth: 0.8,
			borderColor: "#000000",
			fontFamily: "Helvetica",
		},
		tableCellNumber: {
			flex: 1,
			textAlign: "right",
			fontSize: 9,
			padding: 5,
			width: "50px",
			maxWidth: "80px",
			//backgroundColor: "#E5E5E5",
			borderWidth: 1,
			borderColor: "#000000",
			fontFamily: "Helvetica",
		},
		tableCellNumberTotal: {
			flex: 1,
			textAlign: "right",
			fontSize: 9,
			padding: 5,
			width: "100%",
			maxWidth: "100%",
			//backgroundColor: "#E5E5E5",
			borderWidth: 1,
			borderColor: "#000000",
			fontFamily: "Helvetica",
		},
		footer: {
			position: "absolute",
			bottom: -2,
			left: 20,
			right: 0,
			textAlign: "left",
			fontSize: 8,
			paddingTop: 10,
			paddingBottom: 10,
			fontFamily: "Helvetica",
		},
		logo: {
			position: "absolute",
			top: 20, // Ajusta este valor según sea necesario
			left: 30, // Ajusta este valor según sea necesario
			width: 100, // Ajusta el ancho de la imagen según sea necesario
			height: 100, // Ajusta la altura de la imagen según sea necesario
		},
		anulado: {
			position: "absolute",
			top: "20%",
			textAlign: "center",
			display: "inline-block",
			color: "rgba(255,0,0,0.5)",
			transform: "rotate(-45deg)",
			fontSize: "100",
		},
		txtAnula: {
			position: "absolute",
			bottom: 20,
			left: 40,
			textAlign: "center",
			display: "inline-block",
			color: "rgba(255,0,0,0.5)",

			fontSize: "20",
		},
		espacio: {
			height: "10px",
		},
		cellText: {
			flexWrap: "wrap",
			overflow: "hidden", // Asegura que el texto no se desborde
			maxWidth: "100%", // Asegura que el texto no se desborde de la celda
		},
		firmas: {
			flex: 1,
			alignItems: "center",
			marginLeft: 30,
			marginTop: 50,
			fontSize: 10,
			paddingTop: 10,
			paddingBottom: 10,
			fontFamily: "Helvetica",
		},
		cellSinBordes: {
			flex: 1,
			padding: 3,
			fontSize: 12,
			fontWeight: "bold",
			width: "20%",
			maxWidth: "20%",
			borderWidth: 0,
			borderColor: "White",
			backgroundColor: "#ffffff",
			fontFamily: "Helvetica",
			textAlign: "center",
		},
		cellSinBordes25: {
			flex: 1,
			padding: 3,
			fontSize: 12,
			fontWeight: "bold",
			width: "100%",
			maxWidth: "100%",
			borderWidth: 0.8,
			borderColor: "White",
			backgroundColor: "#ffffff",
			fontFamily: "Helvetica",
			textAlign: "center",
		},
	});

	return (
		<>
			<PDFViewer
				style={{ width: "100%", height: "100vh" }}
				className="pdf-viewer"
			>
				<Document>
					<Page size="A4" style={styles.page}>
						{/* TITULO Y TEXTO BAJO TITULO */}
						<Image
							style={styles.logo}
							//src={`data:image/png;base64,${establishment.image}`}
							src={establishment.image ? establishment.image : ""}
						/>
						<Text style={styles.title}>{establishment.name}</Text>
						<Text style={styles.header}>
							{establishment.identificationTypeEst.code} -{" "}
							{establishment.identification}
						</Text>
						<Text style={styles.header}>
							{`${establishment.address} - ${establishment.phone}`}
						</Text>
						<Text style={styles.header}>{establishment.email}</Text>
						{/* -------------------------------------------- */}

						{/* CUADRO FECHA TIPO Y NUMERO DE COMPROBANTE */}
						<View style={styles.tableNo}>
							<Text>
								{(() => {
									switch (movimiento.tipo) {
										case 1:
											return "Reconocimiento Presupuestal";
										case 2:
											return "Ingreso Presupuestal";
										case 3:
											return "Disponibilidad Presupuestal";
										case 4:
											return "Registro Presupuestal";
										case 5:
											return "Obligación Presupuestal";
										case 6:
											return "Comprobante de egreso";
										case 7:
											return "Reducción a reconocimiento presupuestal";
										case 9:
											return "Reducción a disponibilidad presupuestal";
										case 11:
											return "Reducción a registro presupuestal";
										case 13:
											return "Reducción Presupuestal";
										case 8:
											return "Adición a reconocimiento presupuestal";
										case 10:
											return "Adición a disponibilidad presupuestal";
										case 12:
											return "Adición a registro presupuestal";
										case 14:
											return "Adición Presupuestal";
										case 15:
											return "Adición Presupuestal";
										case 16:
											return "Reducción Presupuestal";
										case 17:
											return "Traslado Presupuestal";
										default:
											return null;
									}
								})()}
							</Text>
							<Text>No. {movimiento.numero}</Text>
						</View>
						<View style={styles.tableFecha}>
							<Text>Fecha. {movimiento.fecha}</Text>
							{/* <Text>Fecha Crea. {movimiento.fecha}</Text> */}
						</View>
						{/* --------------------------------------------- */}

						{/* --------- CONTENEDOR DATOS PERSONALES --------- */}
						<View style={styles.containerDatos}>
							<View style={styles.tableRow}>
								<View style={styles.tableTitle25}>
									<Text>Tipo Identificación </Text>
								</View>
								<View style={styles.tableCell}>
									<Text>
										{movimiento.terceroObject &&
										movimiento.terceroObject.identificationType
											? movimiento.terceroObject.identificationType.name
											: ""}
									</Text>
								</View>
								<View style={styles.tableTitle}>
									<Text>Numero Identificación </Text>
								</View>
								<View style={styles.tableCell}>
									<Text>
										{movimiento.terceroObject &&
										movimiento.terceroObject.identification
											? movimiento.terceroObject.identification
											: ""}
									</Text>
								</View>
							</View>

							<View style={styles.tableRow}>
								<View style={styles.tableTitle25}>
									<Text>Tercero</Text>
								</View>
								<View style={styles.tableCell}>
									<Text>
										{movimiento.terceroObject && movimiento.terceroObject.name1
											? `${movimiento.terceroObject.name1} ${movimiento.terceroObject.name2} ${movimiento.terceroObject.lastName1} ${movimiento.terceroObject.lastName2}`
											: movimiento.terceroObject &&
											  movimiento.terceroObject.businessName
											? `${movimiento.terceroObject.businessName}`
											: ""}
									</Text>
								</View>
							</View>

							<View style={styles.tableRow}>
								<View style={styles.tableTitle25}>
									<Text>País</Text>
								</View>
								<View style={styles.tableCell}>
									<Text>
										{movimiento.terceroObject &&
										movimiento.terceroObject.paisUbicacion
											? movimiento.terceroObject.paisUbicacion.descripcion
											: ""}
									</Text>
								</View>
								<View style={styles.tableTitle}>
									<Text>Departamento</Text>
								</View>
								<View style={styles.tableCell}>
									<Text>
										{movimiento.terceroObject &&
										movimiento.terceroObject.departamentoUbicacion
											? movimiento.terceroObject.departamentoUbicacion
													.descripcion
											: ""}
									</Text>
								</View>
							</View>

							<View style={styles.tableRow}>
								<View style={styles.tableTitle25}>
									<Text>Municipio</Text>
								</View>
								<View style={styles.tableCell}>
									<Text>
										{movimiento.terceroObject &&
										movimiento.terceroObject.municipioUbicacion
											? movimiento.terceroObject.municipioUbicacion.descripcion
											: ""}
									</Text>
								</View>
								<View style={styles.tableTitle}>
									<Text>Teléfono</Text>
								</View>
								<View style={styles.tableCell}>
									<Text>
										{movimiento.terceroObject
											? movimiento.terceroObject.phone1
											: ""}
									</Text>
								</View>
							</View>

							<View style={styles.tableRow}>
								<View style={styles.tableTitle25}>
									<Text>Dirección</Text>
								</View>
								<View style={styles.tableCell}>
									<Text>
										{movimiento.terceroObject
											? movimiento.terceroObject.address
											: ""}
									</Text>
								</View>
							</View>

							<View style={styles.tableRow}>
								<View style={styles.tableTitle25}>
									<Text>Detalle</Text>
								</View>
								<View style={styles.tableCellDetalle}>
									<Text style={styles.cellText}>{movimiento.detalle}</Text>
								</View>
							</View>
							<View style={styles.containerDetalle}>
								<Text style={styles.title2}>DETALLES COMPROBANTE </Text>

								<View style={styles.tableRow}>
									<View style={styles.tableTitle}>
										<Text>Código</Text>
									</View>
									{movimiento.tipo == 18 ? (
										<View style={styles.tableTitle}>
											<Text>Detalle</Text>
										</View>
									) : (
										<View style={styles.tableTitle}>
											<Text>Nombre</Text>
										</View>
									)}

									<View style={styles.tableTitleNumber}>
										<Text>Doc Afectado</Text>
									</View>
									{([1, 2, 3, 4, 5, 6].includes(movimiento.tipo) &&
										movimiento.creditos > 0) ||
									[15, 16, 17].includes(movimiento.tipo) ? (
										<View style={styles.tableTitleNumber}>
											<Text>Crédito</Text>
										</View>
									) : null}

									{/* ------------------------------- */}

									{([1, 2, 3, 4, 5, 6].includes(movimiento.tipo) &&
										movimiento.debitos > 0) ||
									[15, 16, 17].includes(movimiento.tipo) ? (
										<View style={styles.tableTitleNumber}>
											<Text>Contra crédito</Text>
										</View>
									) : null}

									
								</View>

								{movimiento.listDetalles &&
									movimiento.listDetalles.map((row, rowIndex) => (
										<View style={styles.tableRow} key={rowIndex}>
											<View style={styles.tableCell} key={rowIndex}>
												<Text>
													{row.cuentaObject ? row.cuentaObject.codigo : ""}
												</Text>
											</View>
											<View style={styles.tableCell} key={rowIndex}>
												<Text>
													{movimiento.tipo === 18
														? row.detalle
														: row.cuentaObject
														? row.cuentaObject.nombre
														: ""}
												</Text>
											</View>
											<View style={styles.tableCellSmall} key={rowIndex}>
												<Text>
													{row.docObject && row.docObject.numeroMovimiento
														? row.docObject.numeroMovimiento
														: " - "}
												</Text>
											</View>
											{([1, 2, 3, 4, 5, 6].includes(movimiento.tipo) &&
												row.credito > 0) ||
											[15, 16, 17].includes(movimiento.tipo) ? (
												<View style={styles.tableCellNumber} key={rowIndex}>
													<Text>{row.credito.toLocaleString()}</Text>
												</View>
											) : null}

											{([1, 2, 3, 4, 5, 6].includes(movimiento.tipo) &&
												row.debito > 0) ||
											[15, 16, 17].includes(movimiento.tipo) ? (
												<View style={styles.tableCellNumber} key={rowIndex}>
													<Text>{row.debito.toLocaleString()}</Text>
												</View>
											) : null}
										</View>
									))}
								<View style={styles.tableRow}>
									{/* <View style={[styles.cellSinBordes]}></View>
									<View style={[styles.cellSinBordes]}></View> */}
									<View style={[styles.cellSinBordes25]}>
										<Text>Totales</Text>
									</View>
									{/* muestra solo unalinea en caso de moviientos */}
									{([1, 2, 3, 4, 5, 6].includes(movimiento.tipo) &&
										movimiento.creditos > 0) ||
									[15, 16, 17].includes(movimiento.tipo) ? (
										<View style={styles.tableCellNumber}>
											<Text>
												{movimiento.creditos
													? movimiento.creditos.toLocaleString()
													: 0}
											</Text>
										</View>
									) : null}

									{/* ------------------------------- */}
									{([1, 2, 3, 4, 5, 6].includes(movimiento.tipo) &&
										movimiento.debitos > 0) ||
									[15, 16, 17].includes(movimiento.tipo) ? (
										<View style={styles.tableCellNumber}>
											<Text>
												{movimiento.debitos
													? movimiento.debitos.toLocaleString()
													: 0}
											</Text>
										</View>
									) : null}
								</View>
								<View style={styles.espacio}></View>

								{movimiento.listDctos && movimiento.listDctos.length > 0 && (
									<>
										<Text style={styles.title2}>DESCUENTOS</Text>
										<View style={styles.tableRow}>
											<View style={styles.tableTitle}>
												<Text>Concepto</Text>
											</View>
											<View style={styles.tableTitleNumber}>
												<Text>Porcentaje</Text>
											</View>
											<View style={styles.tableTitleNumber}>
												<Text>Base</Text>
											</View>
											<View style={styles.tableTitleNumber}>
												<Text>Total</Text>
											</View>
										</View>
										{arrayDctos &&
											arrayDctos.map((row, rowIndex) => (
												<View style={styles.tableRow} key={rowIndex}>
													<View style={styles.tableCell} key={rowIndex}>
														<Text>{row.nombre}</Text>
													</View>
													<View style={styles.tableCellNumber} key={rowIndex}>
														<Text>{row.porcentaje.toLocaleString()}</Text>
													</View>
													<View style={styles.tableCellNumber} key={rowIndex}>
														<Text>{row.valorAplicado.toLocaleString()}</Text>
													</View>
													<View style={styles.tableCellNumber} key={rowIndex}>
														<Text>{row.valorTotal.toLocaleString()}</Text>
													</View>
												</View>
											))}
										<View style={styles.tableRow}>
											<View style={styles.tableCellNumberTotal}>
												<Text style={{ textAlign: "left" }}>
													Total Descuentos
												</Text>
												<Text>{sumDctos.toLocaleString()}</Text>
											</View>
										</View>
										<View style={styles.espacio}></View>
										<View style={styles.tableCellNumberTotal}>
											<Text style={{ textAlign: "left" }}>Total a pagar</Text>
											<Text>
												{(movimiento.creditos - sumDctos).toLocaleString()}
											</Text>
										</View>
									</>
								)}

								{/* Sección de firmas  */}
								<View>
									{movimiento.tipo != 3 ? (
										<>
											<View
												style={{
													flexDirection: "row",
													justifyContent: "space-between",
												}}
											>
												<View style={styles.firmas}>
													<Text>{`________________________`}</Text>
													<Text>{`Elaborado por:`}</Text>
													<Text>{movimiento.nombreCrea}</Text>
												</View>
												<View style={styles.firmas}>
													<Text>{`________________________`}</Text>
													<Text>{`Aprobó`}</Text>
												</View>
											</View>
										</>
									) : (
										<>
											<View
												style={{
													flexDirection: "row",
													justifyContent: "space-between",
												}}
											>
												<View style={styles.firmas}>
													<Text>{`________________________`}</Text>
													<Text>{`Ordenador del gasto`}</Text>
												</View>
											</View>
										</>
									)}
								</View>

								{/* --------------- */}
							</View>
						</View>

						{/* ---------------------------------------------------- */}

						{/* detalles de descuentos si es tipo 6 y tiene conceptos pago */}

						{/* ------------------------------------------------------------ */}

						<View style={styles.footer}>
							<Text>
								{`Fecha y hora de expedición: ${new Date().toLocaleString()} - Elaborado por: ${
									movimiento.nombreCrea
								}`}
							</Text>
						</View>
						<View style={styles.anulado}>
							<Text>{movimiento.estado == 2 ? "ANULADO" : ""} </Text>
						</View>
						<View style={styles.txtAnula}>
							<Text>
								{movimiento.estado == 2 ? "Motivo: " + movimiento.motivo : ""}{" "}
							</Text>
						</View>
					</Page>
				</Document>
			</PDFViewer>
		</>
	);
}

export default ComprobantePagoPDF;
