import React from 'react';
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';
import Home from './components/administration/home/Home';
//import Home from './components/administration/home/Presentacion1.jsx';
import Menu1Container from './components/administration/home/Menu1Container';
import HomeGym from './components/facturacionGym/HomeGym';
import HomeAdmin from './components/administration/home/HomeAdmin';
import HomePersons from './components/persons/homePersons';
import SearchUser from './components/administration/users/SearchUser';
import RegisterUser from './components/administration/users/RegisterUser';
import Establishments
  from './components/administration/settings/Establishments';
import AdminEstablishments
  from './components/administration/settings/AdminEstablishment.jsx';
import RegisterPerson from './components/persons/register/RegisterPerson';
import SearchPersons from './components/persons/register/SearchPersons';
import Anios from './components/administration/process/CrearAnio';
import Meses from './components/administration/process/CrearMes';
import CrearInstituciones
  from './components/administration/settings/CrearInstituciones.jsx';
/* --------------------------------------------------- */
import LoginEstab from './components/administration/home/LoginEstab.jsx';
/* -------------------------------------------------- */
import RegisterInstructors
  from './components/facturacionGym/configuracion/registerInstructors';
import RegisterService
  from './components/facturacionGym/configuracion/registerService';
import RegisterPlan
  from './components/facturacionGym/configuracion/registerPlan';
import SuscripcionUsuario
  from './components/facturacionGym/suscripcion/SuscripcionUsuario';
import BusquedaSuscripciones
  from './components/facturacionGym/suscripcion/BusquedaSuscripciones';
import BusquedaPagos from './components/facturacionGym/pagos/BusquedaPagos';
import InfoUsuarios
  from './components/facturacionGym/informes/InformacionUsuarios.jsx';
import InforSuscripciones
  from './components/facturacionGym/informes/informacionSuscripciones';
import InfoVencimientos
  from './components/facturacionGym/informes/informacionVencimientos';
import InformeIngresos
  from './components/facturacionGym/informes/InformeIngresos';
import InformeAnulaciones
  from './components/facturacionGym/informes/InformeAnulaciones';
import InformeCartera
  from './components/facturacionGym/informes/InformeCartera';
/* -------------------------------------------------------- */
import Roles from './components/administration/settings/Roles';
import FormaPago from './components/administration/settings/FormasPago';
import Permissions from './components/administration/settings/Permissions';
import ProtectedRoutes
  from './components/administration/router/ProtectedRouter';
import {AuthProvider} from './components/context/LogContext';
import Infologs from './components/administration/informes/LogsInfo.jsx';
/* ------------------------------------------------------------------- */
import NavBar from './components/administration/home/NavBar';
import Us from './components/administration/home/Us';
import Mission from './components/administration/home/Mission';
import Vision from './components/administration/home/Vision';
import Services from './components/administration/home/Services';
import Contacts from './components/administration/home/Contacts';

/* Imports presupuesto */
import HomePresupuesto from './components/presupuesto/HomePresupuesto.jsx';
import ConfigurarEstructura
  from './components/presupuesto/configuracion/ConfigurarEstructura';
import CrearEstructura
  from './components/presupuesto/configuracion/CrearEstructura';
import CrearCabeceraPlan
  from './components/presupuesto/configuracion/CrearCabeceraPlanP';
import ProyectarPlan
  from './components/presupuesto/configuracion/ProyectarPlanP';
import AprobarPlanP from './components/presupuesto/procesos/AprobarPLanP';
import ListaMovimientos
  from './components/presupuesto/movimientos/ListaMovimientos';
import NuevoMovimiento
  from './components/presupuesto/movimientos/NuevoMovimiento';
import NuevoConcepto
  from './components/presupuesto/configuracion/NuevoConcepto.jsx';
import EjecucionIngresos
  from './components/presupuesto/Informes/EjecucionIngresos.jsx';
import EjecucionesSia
  from './components/presupuesto/Informes/EjecucionesSia.jsx';
import EjecucionesSifse
  from './components/presupuesto/Informes/EjecucionesSifse.jsx';
import MovimientosPre from './components/presupuesto/Informes/Movimientos.jsx';
import NuevoConceptoInfo
  from './components/presupuesto/configuracion/NuevoConceptoInfo.jsx';
import PlanPxPlano
  from './components/presupuesto/configuracion/PlanPxPlano.jsx';
/*Imports Contabilidad */
import HomeContabilidad from './components/contabilidad/HomeContabilidad.jsx';
import PucContabilidad
  from './components/contabilidad/configuracion/PucContabilidad.jsx';
  import CentrosCosto from './components/contabilidad/configuracion/CentrosCosto.jsx'
  import Comprobantes from './components/contabilidad/configuracion/Comprobantes.jsx'
  import ListaMovimientosContabilidad from './components/contabilidad/movimientos/ListaMovimientos.jsx'
  import NuevoMovimientoContabilidad from './components/contabilidad/movimientos/NuevoMovimiento.jsx'
  import DescuentosConta from './components/contabilidad/configuracion/Descuentos.jsx'
  import SituacinoFinanciera from './components/contabilidad/informes/SituacionFinanciera.jsx'
function App () {
  return (
    <body>
      <div className="main-content">
        <AuthProvider>
          <BrowserRouter>
            <NavBar />
            <Routes>

              <Route path="/home/*" element={<Home />} />
              <Route path="/home/us" element={<Us />} />
              <Route path="/home/mission" element={<Mission />} />
              <Route path="/home/vision" element={<Vision />} />
              <Route path="/home/services" element={<Services />} />
              <Route path="/home/contacts" element={<Contacts />} />
              <Route path="/" element={<Navigate to="/home/*" />} />
              <Route path="/register-estab/:userId?" element={<LoginEstab />} />

            </Routes>
            <Routes>
              <Route element={<ProtectedRoutes />}>

                <Route path="/menu/*" element={<Menu1Container />}>
                  {/* MENU DE INGRESO A ESTABLECIMIENTO LOG2  */}

                  {/* /* Rutas de GYM */}
                  <Route path="hefestoGym/*" element={<HomeGym />}>
                    <Route
                      path="register-instructors/:instructorId?"
                      element={<RegisterInstructors />}
                    />
                    <Route
                      path="register-service"
                      element={<RegisterService />}
                    />
                    <Route path="register-plan" element={<RegisterPlan />} />
                    <Route
                      path="register-userGym"
                      element={<SuscripcionUsuario />}
                    />
                    <Route
                      path="search-suscripciones/:suscripcionId?"
                      element={<BusquedaSuscripciones />}
                    />
                    <Route
                      path="register-pagoGym/:suscripcionId?"
                      element={<BusquedaPagos />}
                    />
                    <Route path="register-formaPago" element={<FormaPago />} />
                    <Route path="infoUsuarios-Gym" element={<InfoUsuarios />} />
                    <Route
                      path="infoSuscripciones-Gym"
                      element={<InforSuscripciones />}
                    />
                    <Route
                      path="infoVencimientos-Gym"
                      element={<InfoVencimientos />}
                    />
                    <Route
                      path="infoIngresos-Gym"
                      element={<InformeIngresos />}
                    />
                    <Route
                      path="infoAnulaciones-Gym"
                      element={<InformeAnulaciones />}
                    />
                    <Route
                      path="infoCartera-Gym"
                      element={<InformeCartera />}
                    />
                  </Route>

                  {/*  /* Rutas administracion */}
                  <Route path="hefestoAdmin/*" element={<HomeAdmin />}>
                    <Route path="search-users" element={<SearchUser />} />
                    <Route
                      path="register-users/:userId?"
                      element={<RegisterUser />}
                    />
                    <Route
                      path="register-establishment"
                      element={<Establishments />}
                    />
                    <Route
                      path="admin-establishment"
                      element={<AdminEstablishments />}
                    />

                    <Route path="register-roles" element={<Roles />} />
                    <Route
                      path="register-permissions"
                      element={<Permissions />}
                    />
                    <Route path="crear-anios" element={<Anios />} />
                    <Route path="info-logs" element={<Infologs />} />
                    <Route
                      path="crear-instituciones"
                      element={<CrearInstituciones />}
                    />

                  </Route>
                  {/*  Rutas terceros */}
                  <Route path="hefestoPersons/*" element={<HomePersons />}>
                    <Route
                      path="register-person/:personId?"
                      element={<RegisterPerson />}
                    />
                    <Route path="search-person" element={<SearchPersons />} />
                  </Route>
                  {/* Rutas Presupuesto */}
                  <Route
                    path="hefestoPresupuesto/*"
                    element={<HomePresupuesto />}
                  >
                    <Route
                      path="configurar-estructura"
                      element={<ConfigurarEstructura />}
                    />
                    <Route
                      path="crear-estructura"
                      element={<CrearEstructura />}
                    />
                    <Route path="crear-planP" element={<CrearCabeceraPlan />} />
                    <Route path="proyectar-planP" element={<ProyectarPlan />} />
                    <Route
                      path="aprobar_planp/:edit?"
                      element={<AprobarPlanP />}
                    />
                    <Route
                      path="reconocimiento-Presupuesto/:tipo"
                      element={<ListaMovimientos />}
                    />
                    <Route
                      path="nuevoMovimiento-Presupuesto/:tipo/:idM?"
                      element={<NuevoMovimiento />}
                    />
                    <Route path="conceptos-planP" element={<NuevoConcepto />} />
                    <Route
                      path="conceptosinfo-planP"
                      element={<NuevoConceptoInfo />}
                    />
                    <Route
                      path="infoejecuciones-Pre/:naturaleza"
                      element={<EjecucionIngresos />}
                    />
                    <Route
                      path="infoejecucionesSia-Pre/:naturaleza"
                      element={<EjecucionesSia />}
                    />
                    <Route
                      path="infoejecucionesSifse-Pre/:naturaleza"
                      element={<EjecucionesSifse />}
                    />
                    <Route
                      path="movimientos-Pre"
                      element={<MovimientosPre />}
                    />
                    <Route path="anios_planp/:modulo?" element={<Anios />} />
                    <Route path="meses_planp/:modulo?" element={<Meses />} />
                    <Route path="planpxPlano" element={<PlanPxPlano />} />

                  </Route>
                  {/* Rutas Contabilidad */}
                  <Route
                    path="hefestoContabilidad/*"
                    element={<HomeContabilidad />}
                  >
                    <Route path="crear-puc" element={<PucContabilidad />} />
                    <Route path="anios_planp/:modulo?" element={<Anios />} />
                    <Route path="meses_planp/:modulo?" element={<Meses />} />
                    <Route path="crear-centroscosto" element={<CentrosCosto />} />
                    <Route path="crear-comprobantes/:tipo" element={<Comprobantes />}/>
                    <Route path="lista-movimientos/:tipo/:categoria" element={<ListaMovimientosContabilidad />} />
                    <Route path="nuevo-movimiento/:tipo/:categoria/:idM?" element={<NuevoMovimientoContabilidad />} />
                    <Route path="crear-descuentos" element={<DescuentosConta />} />
                    <Route path="informe-situacion" element={<SituacinoFinanciera />} />


                  </Route>
                </Route>
              </Route>

            </Routes>
          </BrowserRouter>

        </AuthProvider>
      </div>

    </body>
  );
}

export default App;
